import { SurveyNames } from '../../translations';
import { getResultTable } from '../../utils/htmlResultSurveyTable';

export const getResultObjectHtml = (output, getTranslation, t, survResult) => {
	const objResult = {
		headerBackgroundColor: '#E4E4E7', //TODO GET FROM THEME.
		columns: [
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY),
				dataKey: 'category',
				colorProperty: 'categoryScoreColor',
				rowSpanProperty: 'categoryRowSpan',
				textColor: 'categoryTextColor',
				textAlign: 'center',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY_SCORE),
				dataKey: 'categoryScoreDescription',
				colorProperty: 'categoryScoreColor',
				rowSpanProperty: 'categoryRowSpan',
				textColor: 'categoryTextColor',
				textAlign: 'center',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_CATEGORY_DOMAIN),
				dataKey: 'domain',
				priority: 2,
				rowSpanProperty: 'domainRowSpan',
				colorProperty: 'domainScoreColor',
				textColor: 'domainTextColor',
				textAlign: 'center',
			},
			{
				title: getTranslation(
					t,
					SurveyNames.TABLE_RESULT_CATEGORY_DOMAIN_SCORE
				),
				dataKey: 'domainScoreDescription',
				colorProperty: 'domainScoreColor',
				rowSpanProperty: 'domainRowSpan',
				textColor: 'domainTextColor',
				textAlign: 'center',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_DIMENSION),
				dataKey: 'dimension',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_TOTAL),
				dataKey: 'total',
				toFix: 4,
				textAlign: 'center',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_QUESTION),
				dataKey: 'questions',
				textAlign: 'right',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_ITEMS),
				dataKey: 'items',
				textAlign: 'right',
			},
			{
				title: getTranslation(t, SurveyNames.TABLE_RESULT_FACTOR),
				dataKey: 'factor',
				colorProperty: 'factorColor',
				toFix: 4,
				textAlign: 'center',
				textColor: 'factorTextColor',
			},
		],
		rows: survResult?.summary,
		output: output,
	};

	return getResultTable(objResult);
};
