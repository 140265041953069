import React from 'react';

import logo from './imgs/ctilaptop.png';

export const ErrorComponentValidation = ({ messageSurveyValidation }) => {
	//close windows actual

	return (
		<div className='flex w-[80%] h-[80%] lg:w-3/5 xl:w-3/5 2xl:w-3/5 lg:h-3/5 xl:h-3/5 2xl:h-3/5 bg-white shadow-2xl divide-y-2 absolute'>
			<div className='flex flex-col w-full h-full'>
				<div className='flex w-full h-16 bg-secondary justify-center items-center content-center'>
					<label className='font-bold text-sm text-white' htmlFor=''>
						{'AVISO'}
					</label>
				</div>
				<div className='flex w-full h-full flex-row'>
					<div className='flex h-full w-2/3 justify-center content-center items-center  flex-col p-4'>
						<label className='font-bold text-2xl' htmlFor=''>
							{'Lo sentimos este link ya no es valido'}
						</label>
						<label className='' htmlFor=''>
							{messageSurveyValidation}
						</label>
					</div>
					<div className='flex h-full w-1/3 p-4'>
						<img className='object-fill w-full' src={logo} alt='Logo' />
					</div>
				</div>
			</div>
		</div>
	);
};
