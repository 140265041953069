import React from 'react';
import { CustomIconButton } from '../../../Components/Buttons/CustomIconButton';
import { MdOutlineEdit } from 'react-icons/md';
import {
	getTranslation,
	useCustomTranslation,
} from './../../../hooks/useTranslations';
import { GenericsNames, SurveyNames } from '../../../translations';
import moment from 'moment';
import { convertDate } from '../../../utils/utils';
import PropTypes from 'prop-types';
export const HeaderComponent = ({
	initialValue,
	company,
	survey,
	handleOnEdit,
}) => {
	const tittle = `${initialValue?.guideName}`;
	const nameCompany = `${company?.name}`;

	const valueStartDate = convertDate(survey?.startDate);
	const valueEndDate = convertDate(survey?.endDate);
	const { t } = useCustomTranslation();
	return (
		<div className='w-full h-32 font-font-family-base text-sm mt-2 flex flex-col'>
			<div className=' flex justify-center'>
				<p className='font-semibold flex uppercase'>{tittle}</p>
			</div>
			<div className=' flex justify-center'>
				<p className='font-semibold flex uppercase'>{nameCompany}</p>
			</div>
			<div className='flex justify-between pr-1'>
				<div className='flex'>
					<p className='font-semibold pl-3'>Clave de encuesta:</p>
					<p className='font-base pl-2'> {initialValue?.surveyCode} </p>
					<p className='font-semibold pl-3'>Nombre de encuesta:</p>
					<p className='font-base pl-2'> {survey?.name} </p>
					<p className='font-semibold pl-3'>Fecha Inicio:</p>
					<p className='font-base pl-2'>
						{moment(valueStartDate).format('DD/MM/YYYY')}
					</p>
					<p className='font-semibold pl-3'>Fecha fin:</p>
					<p className='font-base pl-2'>
						{moment(valueEndDate).format('DD/MM/YYYY')}
					</p>
				</div>
				<CustomIconButton
					toolTip={getTranslation(t, GenericsNames.EDIT)}
					//icon={faPenAlt}
					onClick={() => handleOnEdit(initialValue)}
					size='small'>
					<MdOutlineEdit className='text-xl' />
				</CustomIconButton>
			</div>
			<div className='w-full flex-grow relative'>
				<div className='w-full absolute top-0 bottom-0 overflow-y-auto '>
					<div className='flex'>
						<p className='font-semibold flex px-3'>
							{`${getTranslation(t, SurveyNames.CONCLUSION)}:`}
						</p>
						<p className='font-base'>
							{survey?.conclusion || (
								<span className='opacity-60'>
									{getTranslation(t, SurveyNames.WITHOUT_CONCLUSION)}
								</span>
							)}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

HeaderComponent.propTypes = {
	initialValue: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	survey: PropTypes.object.isRequired,
	handleOnEdit: PropTypes.func.isRequired,
};
