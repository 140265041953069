import {
	GET_STATES,
	GET_STATES_ERROR,
	GET_STATES_SUCCESS,
} from '../../business/constants';
import { useContext, useEffect, useState } from 'react';

import { IsNullOrEmpty } from '../../utils';
import { StoreContext } from '../../business/Provider';
import UseCities from './UseCities';
import { Toast } from '../../Components/toast';
import { sortBy } from 'lodash';
import useApi from '../api';
import useSuburbs from './useSuburbs';

const useStates = () => {
	const { genericApiCall } = useApi();
	const context = useContext(StoreContext);
	const [statesOptions, setStatesOptions] = useState(undefined);
	const [loadingState, setLoadingState] = useState(false);
	const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/cp/states`;
	const { getCities } = UseCities();
	const { getSuburbs } = useSuburbs();
	const getStateOptions = (states) => {
		const activeEvaluators = states.filter((object) => {
			return IsNullOrEmpty(object.deleteBy);
		});
		return sortBy(activeEvaluators, [
			function (o) {
				return o.name;
			},
		]).map((item) => ({ label: item.name, value: item.name, id: item.id }));
	};

	const getStates = async () => {
		try {
			context.dispatchStates({ type: GET_STATES });
			const response = await genericApiCall(baseUrl, 'get');

			if (response.status === 200) {
				context.dispatchStates({
					type: GET_STATES_SUCCESS,
					payload: getStateOptions(response.data.data.estados),
				});
			} else {
				context.dispatchStates({
					type: GET_STATES_ERROR,
					payload: response.data.message,
				});
			}
		} catch (error) {
			Toast('warning', error.message, '', error);
			context.dispatchStates({ type: GET_STATES_ERROR });
		}
	};

	const getStatesCities = async (state, formik) => {
		const id = state?.find((x) => x.value === formik.values.state).id;
		const cities = await getCities(id);
		const cityId = cities.data.data.ciudades?.find(
			(x) => x.name.trim() === formik.values.municipality.trim()
		).id;
		await getSuburbs(cityId, id);
	};
	useEffect(() => {
		let abortController = new AbortController();

		if (
			!context.statesState?.states &&
			context.statesState?.loading === false &&
			context.statesState?.error === null
		) {
			getStates();
		}
		return () => {
			abortController.abort();
		};
	}, [context.statesState]);

	return {
		statesOptions,
		setStatesOptions,
		loadingState,
		getStatesCities,
		getStates,
	};
};

export default useStates;
