import { CollaboratorsNames, SurveyNames, DepartmentNames, EvaluatorNames, GenericsNames, PositionNames, WorkCenterNames, ZoneNames } from "../../translations";
import { regexDescription, regexName } from "../../utils/utils";
import * as yup from 'yup'
// not unique field value validation return { [index]: field } where index is the position of the field in the array

/**
 * Toma una matriz de objetos y un nombre de campo, y devuelve un objeto con el índice del objeto que
 * tiene un valor duplicado para el campo.
 * @param array - La matriz de objetos que se va a validar.
 * @param field - El campo a validar
 */
const validateUnique = (array, field, context = null, reImport = false) => {
  let uniqueValues = new Set();
  let result = {};

  array.forEach((item, index) => {
    if (item[field] !== undefined) {
      const value = item[field];
      
      if (uniqueValues.has(value)) {
        result[index] = {
          field: [field],
          message: { [field]: [GenericsNames.REPEAT_ERROR] },
          position: index
        };
      } else {
        uniqueValues.add(value);
      }
    }
    if(context !== null && !reImport){
      const equal = context.filter(actualObj => actualObj.taxId == item['taxId'])

      if (equal.length > 0) {
        result[index] = {
          field: ['taxId'],
          message: { ['taxId']: [GenericsNames.ERROR_RFC_UNIQ] },
          position: index
        };
      }
    }
  });

  return result;
};
const CollaboratorsSchema = yup.array(yup.object().shape({
  name:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_NAME_REQUIRED)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  lastName:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_LASTNAME_REQUIRED)
      .min(1, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  surName:
    yup
      .string('')
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
      .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS)
      .nullable(),
  email:
    yup
      .string('')
      .email(CollaboratorsNames.VALIDATOR_EMAIL)
      .required(CollaboratorsNames.VALIDATOR_EMAIL_REQUIRED)
      .max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE),
  departmentId:
    yup
      .string('')
      .required(CollaboratorsNames.VALIDATOR_DEPARTMENT_REQUIRED)
      .nullable(),
  positionId:
    yup
      .string('')
      .nullable(),
  workCenterId:
    yup
      .string('')
      .nullable(),
  gender:
    yup
      .string(''),
  areaId:
    yup
      .string('')
      .nullable(),
  taxId:
    yup //taxID: DataTypes.STRING, //RFC
      .string()
      .required(CollaboratorsNames.VALIDATOR_TAX_ID_REQUIRED)
      .max(13, CollaboratorsNames.VALIDATOR_TAX_ID_SIZE)
      .matches(
        /^([A-zÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-z]|[0-9]){2}([A]|[0-9]){1})?$/,
        'RFC inválido'
      )
}))

const DepartmentsSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, DepartmentNames.DepartmentNameValidation)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))
const AreaSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ).nullable(),
  name: yup.string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    .max(70, ZoneNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  //.matches(/^[0-9a-zñáéíóúü ]+$/i, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))
const PositionSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(PositionNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, PositionNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, PositionNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required('POSITION_VALIDATOR_NAME_REQUIRED')
    .required(PositionNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(150, PositionNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
}))

const EvaluatorSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(ZoneNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, ZoneNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(ZoneNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, EvaluatorNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  profesionalId: yup
    .string('')
    .matches(/^[0-9]+$/, ZoneNames.VALIDATOR_DIGIT_ALLOW)
    .max(15, EvaluatorNames.VALIDATOR_DIGIT_PROFESIONAL_ID)
}))

const SurveySchema = yup.array(yup.object().shape({
  name: yup.string('').required(SurveyNames.VALIDATOR_NAME_REQUIRED).max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE),
  taxId:  yup //taxID: DataTypes.STRING, //RFC
  .string()
  .required(CollaboratorsNames.VALIDATOR_TAX_ID_REQUIRED)
  .max(13, CollaboratorsNames.VALIDATOR_TAX_ID_SIZE)
  .nullable()
  .matches(
    /^([A-zÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-z]|[0-9]){2}([A]|[0-9]){1})?$/,
    'RFC inválido'
  ),
  lastName: yup.string('').required(SurveyNames.VALIDATOR_NAME_REQUIRED).max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE),
  email: yup.string('').required(SurveyNames.COLLABORATOR_INVALID).email(SurveyNames.EMAIL_INVALID).nullable(),
  departmentId: yup.string('').required(SurveyNames.DEPARTMENT_NOT_EXIST).nullable(),
  evaluationPeriod: yup.string('').required(SurveyNames.EVALUATION_PERIOD_REQUIRED).nullable(),
  period: yup.string('').required(SurveyNames.PERIOD_REQUIRED).nullable(),
}))

const WorkCenterSchema = yup.array(yup.object().shape({
  scode: yup // licenseId: DataTypes.UUID,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ID_REQUIRED)
    .matches(/^[0-9]+$/, WorkCenterNames.VALIDATOR_DIGIT_ALLOW)
    .min(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .max(4, WorkCenterNames.VALIDATOR_ID_REQUIRED_SIZE)
    .test(
      'greaterThanZero',
      PositionNames.VALIDATOR_ID_GREATER_THAN_ZERO,
      (value) => parseInt(value, 10) > 0
    ),
  name: yup
    .string('')
    .required(WorkCenterNames.VALIDATOR_NAME_REQUIRED)
    // .min(4, 'El código postal debe tener al menos 5 caracteres')
    .max(100, WorkCenterNames.VALIDATOR_NAME_REQUIRED_SIZE)
    .matches(regexDescription, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
  zipCode: yup // zipCode: DataTypes.INTEGER,
    .string('')
    .required(WorkCenterNames.VALIDATOR_ZIPCODE_REQUIRED)
    .nullable()
    .min(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN)
    .max(5, WorkCenterNames.VALIDATOR_ZIPCODE_MIN),
  state: yup // state: DataTypes.STRING,
    .string()
    .required(WorkCenterNames.VALIDATOR_STATE_REQUIRED),
  municipality: yup //  municipality: DataTypes.STRING,
    .string('')
    .required(WorkCenterNames.VALIDATOR_MUNICIPALITY_REQUIRED)
    .max(50, WorkCenterNames.VALIDATOR_MUNICIPALITY_MAX),
  street: yup // street: DataTypes.STRING,
    .string('').required(WorkCenterNames.VALIDATOR_STREE_REQUIRED)
    .max(100, WorkCenterNames.VALIDATOR_STREET_MAX),
  exteriorNumber: yup.string().max(30, WorkCenterNames.VALIDATOR_EXTERIOR_MAX).required(WorkCenterNames.VALIDATOR_EXTERIOR_REQUIRED),
  interiorNumber: yup.string().max(30, WorkCenterNames.VALIDATOR_INTERIOR_MAX),
  suburb: yup.string().max(50, WorkCenterNames.VALIDATOR_SUBURB_MAX).required(WorkCenterNames.VALIDATOR_SUBURB_REQUIRED),
  principalActivity: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  objective: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  activities: yup.string().max(255, WorkCenterNames.VALIDATOR_PRINCIPAL_ACTIVITY).nullable(),
  //evaluatorId: yup.string('').required(WorkCenterNames.VALIDATOR_EVALUATOR_ID),
  // responsableId:string.uuid('WORK_CENTER_VALIDATOR_RESPONSABLE_ID')
}))
// get error fields
/**
 * Toma un objeto de valores a validar y un esquema de validación, y devuelve un objeto de errores
 * @param valuesToValidate - Los valores a validar.
 * @param validationSchema - Este es un objeto de esquema Yup.
 * @returns Un objeto con la siguiente estructura:
 * {
 *   [posición]: {
 *     [campo]: [
 *       [mensaje],
 *       [mensaje],
 *       ...
 *     ]
 *   }
 * }
 */
const validateAndGetErrors = async (values, schema) => {
  try {
    await schema.validate(values, { abortEarly: false });
    return {};
  } catch (err) {
    const errors = {};

    err.inner.forEach((error) => {
      const [position, field] = error.path.split('.');
      const index = position.split('[')[1].split(']')[0];

      if (!errors[index]) {
        errors[index] = { field: [], message: {} };
      }

      errors[index].field.push(field);
      
      
      if (!errors[index].message[field]) {
        errors[index].message[field] = [];
      }
      errors[index].message[field].push(error.message);
    });

    return errors;
  }
};
// validate object
export const validate = async (values, type, context = null, reImport = false) => {
  // values is array of objects
  switch (type) {
    case 1:
      return validateUniq(values, CollaboratorsSchema, 'taxId', context, reImport)
    case 2:
      return validateUniq(values, AreaSchema, 'scode')
    case 3:
      return validateUniq(values, PositionSchema, 'scode')
    case 4:
      return validateUniq(values, WorkCenterSchema, 'scode')
    case 5:
      return validateUniq(values, EvaluatorSchema, 'scode')
    case 6:
      return validateUniq(values, DepartmentsSchema, 'scode')
    case 7:
      return validateUniq(values, SurveySchema, null)
    case 8:
      return validateUniq(values, SurveySchema, null)
    case 9:
      return validateUniq(values, SurveySchema, null)
    case 10:
      return validateUniq(values, SurveySchema, null)
    case 11:
      return validateUniq(values, SurveySchema, null)
    case 12:
      return validateUniq(values, SurveySchema, null)
    default:
      return {};
  }
}

async function validateUniq(valuesToValidate, validationSchema, uniqueFields, context = null, reImport = false) {
  const validationErrors = await validateAndGetErrors(valuesToValidate, validationSchema)
  const uniqueErrors = uniqueFields ? validateUnique(valuesToValidate, uniqueFields, context, reImport) : {}

  const combinedErrors = Object.assign({}, validationErrors)

  // Combine unique errors
  Object.keys(uniqueErrors).forEach((position) => {
    if (!combinedErrors[position]) {
      combinedErrors[position] = uniqueErrors[position]
    } else {
      combinedErrors[position].field = [...combinedErrors[position].field, ...uniqueErrors[position].field]
      Object.entries(uniqueErrors[position].message).forEach(([field, messages]) => {
        combinedErrors[position].message[field] = combinedErrors[position].message[field] ? [...combinedErrors[position].message[field], ...messages] : messages
      })
    }
  })
  return combinedErrors
}