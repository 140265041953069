import React, { useContext, useState, useEffect } from 'react';
import { SearchInput } from '../SearchInput';
import { SelectComponent } from '../Select';
import { Input } from '../Input/Input';
import { firstOrDefault } from '../../Auth/arrayService';
import { getZipCodes } from '../../Auth/contpaqService';
import { StoreContext } from '../../business/Provider';
import useStates from '../../hooks/states/useStates';
import UseCities from '../../hooks/states/UseCities';
import useSuburbs from '../../hooks/states/useSuburbs';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { WorkCenterNames } from '../../translations';
export const AddressInformationComponent = ({
	formik,
	open,
	zipCodeFound,
	setZipCodeFound,
	isEdit = false,
}) => {
	const { t } = useCustomTranslation();

	const context = useContext(StoreContext);
	const { suburbsState, citiesState, statesState } = context;
	const { getStatesCities, getStates } = useStates();
	const [step, setStep] = useState(1);

	const { getCities } = UseCities();
	const { getSuburbs } = useSuburbs();
	const [suburbs, setSuburbs] = useState([]);
	const [disable, setDisable] = useState(true);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);

	useEffect(() => {
		// if important values are empty, disable the button
		if (
			formik.values.zipCode === '' ||
			formik.values.municipality === '' ||
			formik.values.suburb === '' ||
			formik.values.street === '' ||
			formik.values.state === '' ||
			formik.values.exteriorNumber === '' ||
			formik.values.scode === '' ||
			formik.values.name === ''
		) {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [formik.values]);

	useEffect(() => {
		if (suburbsState.loading === false) {
			console.log('suburbsState.suburbs', suburbsState.suburbs);
			setSuburbs(suburbsState.suburbs);
		}
	}, [suburbsState]);

	useEffect(() => {
		if (citiesState.loading === false) {
			setCities(citiesState.cities);
		}
	}, [context.citiesState]);

	useEffect(() => {
		if (statesState.loading === false) {
			setStates(statesState.states);
		}
	}, [statesState]);

	const validateSuburbs = (suburbData) => {
		if (suburbData.length === 0) {
			const stateId = states.find((x) => x.value === formik?.values?.state).id;
			const municipalityId = cities.find(
				(x) => x.value === formik?.values?.municipality
			).value;

			return getSuburbs(municipalityId, stateId);
		}
	};

	const handleZipSearch = async (zipCodeValueSearch) => {
		const response = await getZipCodes(zipCodeValueSearch);

		if (response === undefined) {
			///console.log('response handle zip', zipCodeValueSearch);
			setZipCodeFound(false);
			formik.setFieldValue('municipality', '');
			formik.setFieldValue('state', '');
			formik.setFieldValue('suburb', '');
			formik.setFieldValue('zipCode', formik?.values?.zipCode);
		} else {
			response.municipality
				? formik.setFieldValue('municipality', response.municipality)
				: formik.setFieldValue('municipality', '');
			response.state
				? formik.setFieldValue('state', response.state)
				: formik.setFieldValue('state', '');
			response?.suburbs.length > 0
				? setSuburbs(response?.suburbs)
				: validateSuburbs(response?.suburbs);
			formik.setFieldValue('zipCode', formik?.values?.zipCode);
			setZipCodeFound(true);
		}
	};

	useEffect(() => {
		if (formik.values.zipCode !== '' && isEdit === true && step === 1) {
			handleZipSearch(formik.values.zipCode);
			setStep(2);
		}
	}, [isEdit, formik.values.zipCode]);
	function capitalizeString(sentence) {
		// Lista de conjunciones y preposiciones a excluir
		const excludedWords = ['y', 'o', 'pero', 'por', 'para', 'en', 'a', 'con', 'de'];
	  
		// Dividir la oración en palabras
		const words = sentence.toLowerCase().split(' ');
	  
		// Capitalizar la primera letra de cada palabra, excluyendo las palabras excluidas
		const capitalizedWords = words.map((word, index) => {
		  if (index === 0 || !excludedWords.includes(word)) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		  } else {
			return word;
		  }
		});
	  
		// Unir las palabras capitalizadas en una sola cadena
		const capitalizedSentence = capitalizedWords.join(' ');
	  
		return capitalizedSentence;
	  }
	const renderInput = (
		id,
		name,
		placeholder,
		onChange,
		value,
		onBlur,
		error,
		errorText,
		type,
		important,
		rows,
		cols
	) => {
		return (
			<div className='flex w-1/2 p-2'>
				<Input
					id={id}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					onBlur={onBlur}
					error={error}
					errorText={errorText}
					type={type}
					important={important}
					rows={rows}
					cols={cols}
				/>
			</div>
		);
	};
	const renderStateSelected = () => {
		//console.log('states', states);
		let valueSelect =
			formik?.values?.state !== ''
				? capitalizeString(formik?.values?.state) 
				: 'Seleccione una entidad federativa';

		return (
			<SelectComponent
				className={'flex flex-col p-2 w-1/2'}
				valueSelected={valueSelect}
				labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_STATE)}
				ValuesOptions={states}
				handleChange={(e) => {
					formik.setFieldValue('state', e.target.value);
					// search state id in states
					const id = states.find((x) => {
						return x?.value === e.target?.value;
					}).id;
					getCities(id);
					//formik.setFieldValue('state', '');
					formik.setFieldValue('suburb', '');
				}}
				placeholderText={'...'}
				name={'state'}
				optionLabel={'label'}
				error={Boolean(formik?.touched?.state && formik.errors.state)}
				errorText={
					formik?.touched?.state && getTranslation(t, formik.errors.state)
				}
				important
			/>
		);
	};
	const renderInformationRelatedToZipCode = () => {
		return (
			<>
				<div className='flex w-full flex-row'>
					<SelectComponent
						className={'flex flex-col p-2 w-1/2'}
						valueSelected={
							formik?.values?.municipality !== ''
								? capitalizeString(formik?.values?.municipality)
								: 'Seleccione una ciudad'
						}
						labelText={getTranslation(
							t,
							WorkCenterNames.GRID_COLUMN_MUNICIPALITY
						)}
						ValuesOptions={formik?.values?.state !== '' ? cities : []}
						handleChange={(e) => {
							formik.setFieldValue('municipality', e.target.value);
							const stateId = states.find(
								(x) => x.value === formik?.values?.state
							).id;
							const municipalityId = cities.find(
								(x) => x.value === e.target.value
							).value;
							getSuburbs(municipalityId, stateId);
						}}
						placeholderText={'...'}
						name={'municipality'}
						optionLabel={'label'}
						error={Boolean(
							formik?.touched?.municipality && formik.errors.municipality
						)}
						errorText={
							formik?.touched?.municipality &&
							getTranslation(t, formik.errors.municipality)
						}
						important
						disabled={formik?.values?.state === ''}
					/>

					{suburbs !== undefined && suburbs.length > 0 ? (
						<SelectComponent
							className={'flex flex-col p-2 w-1/2'}
							valueSelected={
								formik?.values?.municipality !== ''
									? formik?.values?.suburb
									: []
							}
							labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_SUBURB)}
							ValuesOptions={suburbs}
							handleChange={(e) => {
								const option = firstOrDefault('value', e.target.value, suburbs);
								formik.setFieldValue('suburb', option.label);
							}}
							placeholderText={'...'}
							name={'suburb'}
							optionLabel={'label'}
							error={Boolean(formik?.touched?.suburb && formik.errors.suburb)}
							errorText={
								formik?.touched?.suburb &&
								getTranslation(t, formik.errors.suburb)
							}
							important
							disabled={formik?.values?.municipality === ''}
						/>
					) : (
						<div className='flex w-1/2 p-2'>
							<Input
								id='suburb'
								name='suburb'
								placeholder={getTranslation(
									t,
									WorkCenterNames.GRID_COLUMN_SUBURB
								)}
								onChange={formik?.handleChange}
								value={formik?.values?.suburb || ''}
								onBlur={(event) => {
									formik?.handleBlur(event);
									formik.setFieldValue('suburb', event.target.value.trim());
								}}
								error={formik?.touched?.suburb && Boolean(formik.errors.suburb)}
								errorText={
									formik?.touched?.suburb &&
									getTranslation(t, formik.errors.suburb)
								}
								type='text'
								important={true}
								//	disabled={disabled}
							/>
						</div>
					)}
				</div>
			</>
		);
	};

	const renderInputState = () => {
		return (
			<div className='flex w-1/2 p-2'>
				<Input
					id='state'
					name='state'
					placeholder={getTranslation(t, WorkCenterNames.GRID_COLUMN_STATE)}
					onChange={formik?.handleChange}
					value={formik?.values?.state || ''}
					onBlur={(event) => {
						formik?.handleBlur(event);
						formik.setFieldValue('state', event.target.value.trim());
					}}
					error={formik?.touched?.state && Boolean(formik.errors.state)}
					errorText={
						formik?.touched?.state && getTranslation(t, formik.errors.state)
					}
					type='text'
					important={true}
					disabled={true}
				/>
			</div>
		);
	};
	const renderStates = () => {
		// disabled if formik?.values?.municipality is string empty or null
		let disabled = false;
		// if formik?.values?.municipality is in municipality, then disabled is false
		if (formik?.values?.municipality !== '') {
			disabled = true;
		}
		return (
			<>
				<div className='flex w-full flex-row'>
					<div className='flex w-1/2 p-2'>
						<Input
							id='municipality'
							name='municipality'
							placeholder={getTranslation(
								t,
								WorkCenterNames.GRID_COLUMN_MUNICIPALITY
							)}
							onChange={formik?.handleChange}
							value={formik?.values?.municipality? capitalizeString(formik?.values?.municipality): ''}
							onBlur={(event) => {
								formik?.handleBlur(event);
								formik.setFieldValue('municipality', event.target.value.trim());
							}}
							error={
								formik?.touched?.municipality &&
								Boolean(formik.errors.municipality)
							}
							errorText={
								formik?.touched?.municipality &&
								getTranslation(t, formik.errors.municipality)
							}
							type='text'
							important={true}
							disabled={disabled}
						/>
					</div>
					<SelectComponent
						className={'flex flex-col p-2 w-1/2'}
						valueSelected={formik?.values?.suburb ? formik?.values?.suburb : null}
						labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_SUBURB)}
						ValuesOptions={suburbs}
						handleChange={(e) => {
							const option = firstOrDefault('value', e.target.value, suburbs);
							formik.setFieldValue('suburb', option.label);
						}}
						placeholderText={'...'}
						name={'suburb'}
						optionLabel={'label'}
						error={Boolean(formik?.touched?.suburb && formik.errors.suburb)}
						errorText={
							formik?.touched?.suburb && getTranslation(t, formik.errors.suburb)
						}
						important
					/>
				</div>
			</>
		);
	};

	const validateCeroInZipCode = (value) => {
		if (/^0\d*$/.test(value)) {
			formik.setFieldValue('zipCode', value);
			console.log('validateCeroInZipCode', newValue);
		}
	};

	return (
		<div className='flex w-full flex-col h-full'>
			<div className='flex w-full flex-row'>
				<div className='flex w-1/2 pl-2'>
					<SearchInput
						title={getTranslation(t, WorkCenterNames.GRID_COLUMN_ZIPCODE)}
						name='zipCode'
						key='zipCode'
						maxLength={5}
						onClickSearch={(e) => {
							// only numbers and 5 digits
							formik?.handleBlur(e);
							handleZipSearch(formik?.values?.zipCode);
						}}
						value={formik?.values?.zipCode || ''}
						onChange={(e) => {
							//pars to number
							//console.log('e.target.value', e.target.value);
							const value = e.target.value; //.replace(/[^0-9]/g, '');
							validateCeroInZipCode(value);
							// value is a number
							// if is empty string
							if (
								(Number(value) && value.length <= 5 && value !== '') ||
								value.length === 0
							) {
								formik?.handleChange(e);
							}
						}}
						onBlur={(e) => {
							formik?.handleBlur(e);
						}}
						error={formik?.touched?.zipCode && Boolean(formik.errors.zipCode)}
						errorText={
							formik?.touched?.zipCode &&
							getTranslation(t, formik.errors.zipCode)
						}
						type='string'
						important
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === 'Tab') {
								formik?.handleBlur(e);
								handleZipSearch(formik?.values?.zipCode);
							}
						}}
					/>
				</div>
				{zipCodeFound ? renderInputState() : renderStateSelected()}
			</div>
			{!zipCodeFound ? renderInformationRelatedToZipCode() : renderStates()}
			<div className='flex w-full flex-row'>
				{renderInput(
					'street',
					'street',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_STREET),
					formik?.handleChange,
					formik?.values?.street || '',
					(event) => {
						formik?.handleBlur(event);
						formik.setFieldValue('street', event.target.value.trim());
					},
					formik?.touched?.street && Boolean(formik.errors.street),
					formik?.touched?.street && getTranslation(t, formik.errors.street),
					'text',
					true
				)}
				{renderInput(
					'exteriorNumber',
					'exteriorNumber',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_EXTERIOR),
					formik?.handleChange,
					formik?.values?.exteriorNumber || '',
					(event) => {
						formik?.handleBlur(event);
						formik.setFieldValue('exteriorNumber', event.target.value.trim());
					},
					formik?.touched?.exteriorNumber &&
						Boolean(formik.errors.exteriorNumber),
					formik?.touched?.exteriorNumber &&
						getTranslation(t, formik.errors.exteriorNumber),
					'text',
					true
				)}
			</div>

			<div className='flex w-full flex-row'>
				{renderInput(
					'interiorNumber',
					'interiorNumber',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_INTERIOR),
					formik?.handleChange,
					formik?.values?.interiorNumber || '',
					(event) => {
						formik?.handleBlur(event);
						formik.setFieldValue('interiorNumber', event.target.value.trim());
					},
					formik?.touched?.interiorNumber &&
						Boolean(formik.errors.interiorNumber),
					formik?.touched?.interiorNumber &&
						getTranslation(t, formik.errors.interiorNumber),
					'text',
					false
				)}
			</div>
		</div>
	);
};
