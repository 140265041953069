import { getTable } from './htmlDynamicTable';
import collaboratorTemplate from './templates/ColaboradoresTemplate.xlsx';
import departmentTemplate from './templates/DepartamentosTemplate.xlsx';
import evaluatorTemplate from './templates/EvaluadoresTemplate.xlsx';
import positionTemplate from './templates/PuestosTemplate.xlsx';
import workCenterTemplate from './templates/CentrosDeTrabajoTemplates.xlsx';
import zoneTemplate from './templates/AreasTemplate.xlsx';
import guide1 from './templates/EncuestaTemplateGuiaI.xlsx';
import guide2 from './templates/EncuestaTemplateGuiaII.xlsx';
import guide3 from './templates/EncuestaTemplateGuiaIII.xlsx';
import guide5 from './templates/EncuestaTemplateGuiaV.xlsx';
import guide6 from './templates/EncuestaTemplateListaDeVerificaciónParaCandidatosATeletrabajo.xlsx'
import guide7 from './templates/EncuestaTemplateListaDeVerificaciónParaTeletrabajadores.xlsx'
import colaboratorsGuide from './templates/ImportColaboratorGuidesTemplate.xlsx'
import excelToJson from 'convert-excel-to-json';
const getVisibleProperties = (visibleColumns, rows) => {
	let exportRows = [];
	rows.forEach(function (row) {
		let item = {};
		visibleColumns.forEach(function (column) {
			item[column.title] = row[column.dataKey];
		});
		exportRows.push(item);
	});
	return exportRows;
};
export function GetText(html) {
	let doc = new DOMParser().parseFromString(html, 'text/html');
	return doc.body.textContent || '';
}
export const excelToJsonPortal = (buffer) => {
    return excelToJson({
        source: buffer,
        header: {
            rows: 1
        },
        columnToKey: {
            '*': '{{columnHeader}}'
        },
        sheetStubs: true
    });
}
export const exportExcel = (visibleColumns, rows, fileName) => {
	let exportRows = getVisibleProperties([...visibleColumns], rows);
	import('xlsx').then((xlsx) => {
		const worksheet = xlsx.utils.json_to_sheet(exportRows);
		const workbook = {
			Sheets: { [fileName]: worksheet },
			SheetNames: [fileName],
		};
		const excelBuffer = xlsx.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
		});
		saveAsExcelFile(excelBuffer, fileName);
	});
};

const saveAsExcelFile = (buffer, fileName) => {
	const now = new Date().toISOString().split('T')[0];
	fileName = `${fileName}-${now}.xlsx`;
	import('file-saver').then((module) => {
		if (module && module.default) {
			let EXCEL_TYPE =
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			let EXCEL_EXTENSION = '.xlsx';
			const data = new Blob([buffer], {
				type: EXCEL_TYPE,
			});

			module.default.saveAs(
				data,
				fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
			);
		}
	});
};

export const exportPdf = (rows, fileName, exportColumns) => {
	const now = new Date().toISOString().split('T')[0];
	fileName = `${fileName}-${now}`;
	import('jspdf').then((jsPDF) => {
		import('jspdf-autotable').then(() => {
			const doc = new jsPDF.default(0, 0);
			doc.autoTable(exportColumns, rows);
			doc.save(fileName);
		});
	});
};

export const printGrid = (printObj) => {
	let newWin = window.open('');
	newWin.document.body.innerHTML = getTable(printObj);

	newWin.print();
};

export const exportToExcelV2 = (printObj) => {
	import('xlsx').then((xlsx) => {
		let htmlstr = getTable(printObj);
		let workbook = xlsx.read(htmlstr, { type: 'string' });

		const excelBuffer = xlsx.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
		});
		saveAsExcelFile(excelBuffer, printObj.title);
	});
};

export const exportToExcelV3 = (printObj) => {
	let htmls = '';
	let uri = 'data:application/vnd.ms-excel;base64,';
	let template =
		'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body style="background:#FFFFFF;">{table}</body></html>';
	let base64 = function (s) {
		return window.btoa(unescape(escape(s)));
	};

	let format = function (s, c) {
		return s.replace(/{(\w+)}/g, function (m, p) {
			return c[p];
		});
	};

	htmls = getTable(printObj);
	let ctx = {
		worksheet: printObj.fileName,
		table: htmls,
	};

	const now = new Date().toISOString().split('T')[0];
	const fileName = `${printObj.fileName}-${now}.xls`;
	let link = document.createElement('a');
	link.download = fileName;
	link.href = uri + base64(format(template, ctx));
	link.click();
};
export const toISOString = (string) => {
	return new Date(string).toISOString().split('T')[0];
};
const removeAccents = (str) => {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const cambiarNombre = (nombre) => {
	let regex =
		/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
	const result = regex.exec(nombre);
		return result === null ? nombre : result[0];
	};
	

export const customValidationInput = (
	value,
	setValidation,
	setMessage,
	nameInput,
	compareValue
) => {
	const dataInput = nameInput === 'Departamento' ? value : cambiarNombre(value);

	const newDataImput = dataInput.trim();

	if (newDataImput === '') {
		setValidation(true);
		setMessage('El campo ' + nameInput + ' no puede estar vacio');
	} else {
		setValidation(false);
		setMessage('');
	}
	if (
		compareValue !== '' &&
		newDataImput.toLowerCase() === compareValue?.toLowerCase()
	) {
		setValidation(false);
		setMessage('');
	} else {
		setValidation(true);
		setMessage(
			'El campo ' + nameInput + ' no coincide con la información del usuario'
		);
	}
};

export const exportToExcelFromTemplate = (fileName, htmls) => {
	let uri = 'data:application/vnd.ms-excel;base64,';
	let template =
		'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body style="background:#FFFFFF;">{table}</body></html>';
	let base64 = function (s) {
		return window.btoa(unescape(escape(s)));
	};

	let format = function (s, c) {
		return s.replace(/{(\w+)}/g, function (m, p) {
			return c[p];
		});
	};

	let ctx = {
		worksheet: fileName,
		table: htmls,
	};

	const now = new Date().toISOString().split('T')[0];
	fileName = `${fileName}-${now}.xls`;
	let link = document.createElement('a');
	link.download = fileName;
	link.href = uri + base64(format(template, ctx));
	link.click();
};
export const findNestedObj = (entireObj, keyToFind, valToFind) => {
	let foundObj;
	JSON.stringify(entireObj, (_, nestedValue) => {
		if (nestedValue && nestedValue[keyToFind] === valToFind) {
			foundObj = nestedValue;
		}
		return nestedValue;
	});
	return foundObj;
};
export const capitalizeWords = (sentence) => {
	const words = sentence.split(' ');
	for (let i = 0; i < words.length; i++) {
		const value =
			words[i][0] !== undefined ? words[i][0].toUpperCase() : words[i][0];
		words[i] = value + words[i].substr(1);
	}
	return words.join(' ');
};

export const exportToExcelV4 = (fileName, htmls) => {
	try {
		let newWin = window.open('');
		//newWin.document.head.appendChild(getStylesTable())
		newWin.document.body.innerHTML = htmls;
		const now = new Date().toISOString().split('T')[0];
		import('xlsx').then((xlsx) => {
			let wb = xlsx.utils.table_to_book(
				newWin.document.getElementById('tblTest'),
				{ sheet: 'sheet1' }
			);

			fileName = `${fileName}-${now}.xlsx`;
			xlsx.writeFile(wb, fileName);
		});
	} catch (error) {
		console.log(error);
	}
};
export const regexName = new RegExp(
	`^[ A-Za-zñáéíóúü&#@0-9-()°!#$&=+*_.,\\/]*$`,
	'i'
);
export const regexDescription = new RegExp(
	`^[ A-Za-zñáéíóúü&#@0-9-()+*°!#$&=+_.,\\/]*$`,
	'i'
);

export const regexProfessionalId = new RegExp(`^[ A-Za-zñ&#@0-9]*$`, 'i');
export const getImportOptions = (data) => {
	const result = data.map((item) => ({
		label: item.scode,
		value: item.id,
		name: item.name,
	}));
	// sort by label
	result.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		}
		if (a.label > b.label) {
			return 1;
		}
		return 0;
	});
	return result;
};

export const getNameFile = (type) => {
	// 'COLLABORATOR': 1,
	//   'AREA': 2,
	//   'POSITION': 3,
	//   'WORK_CENTER': 4,
	//   'EVALUATOR': 5,
	//   'DEPARTMENT': 6
	switch (type) {
		case 1:
			return {
				name: 'ColaboradoresTemplate.xlsx',
				template: collaboratorTemplate,
			};

		case 2:
			return {
				name: 'AreasTemplate.xlsx',
				template: zoneTemplate,
			};

		case 3:
			return {
				name: 'PuestosTemplates.xlsx',
				template: positionTemplate,
			};

		case 4:
			return {
				name: 'CentrosDeTrabajoTemplates.xlsx',
				template: workCenterTemplate,
			};

		case 5:
			return {
				name: 'EvaluadoresTemplate.xlsx',
				template: evaluatorTemplate,
			};

		case 6:
			return {
				name: 'DepartamentosTemplate.xlsx',
				template: departmentTemplate,
			};
		case 7:
			return {
				name: 'EncuestaTemplateGuiaI.xlsx',
				template: guide1,
			};
		case 8:
			return {
				name: 'EncuestaTemplateGuiaII.xlsx',
				template: guide2,
			};
		case 9:
			return {
				name: 'EncuestaTemplateGuiaIII.xlsx',
				template: guide3,
			};
		case 10:
			return {
				name: 'EncuestaTemplateGuiaV.xlsx',
				template: guide5,
			};
		case 11:
			return {
				name: 'EncuestaTemplateListaDeVerificaciónParaCandidatosATeletrabajo.xlsx',
				template: guide6,
			};
		case 12:
			return {
				name: 'EncuestaTemplateListaDeVerificaciónParaTeletrabajadores.xlsx',
				template: guide7,
			};
		case 99:
			return {
				name :'ImportarColaboradoresGuiasTemplate.xlsx',
				template: colaboratorsGuide
			};
	}
};
export const downloadFile = async (typeData) => {
	const { name, template } = getNameFile(typeData);
	// template is a path to the file and name is the name of the file
	const blob = await fetch(template).then((r) => r.blob());
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', name);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const gateDateFormated = (value) => {
	const date = new Date(value);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const newDay = day < 10 ? '0' + day : day;
	const newMonth = month < 10 ? '0' + month : month;
	return `${newDay}/${newMonth}/${year}`;
};

export const convertDate = (date) => {
	if (date !== undefined) {
		const newDate = date.toString();
		const dateArray = newDate.split('/');
		const dateObject = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
		return dateArray.length > 1 ? dateObject : date;
	}
};
