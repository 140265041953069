//constant map of types of files
export const fileTypes = {
	EVALUATOR: 5,
	WORK_CENTER: 4,
	AREA: 2,
	DEPARTMENT: 6,
	POSITION: 3,
	COLLABORATOR: 1,
	GUIDE_1: 7,
	GUIDE_2: 8,
	GUIDE_3: 9,
	GUIDE_5: 10,
	GUIDE_6: 11,
	GUIDE_7: 12
};
