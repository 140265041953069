import React from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import { ResultsNames } from '../../../translations';
export const HeaderComponent = ({ title }) => {
	const { t } = useCustomTranslation();
	return (
		<header className=' border-b border-border p-2  text-center'>
			<h5 className='text-h5-font-size '>
				{title ? getTranslation(t, title.title) : ' '}
			</h5>
			<p className='text-small-font-base '>
				{getTranslation(t, ResultsNames.RESULT)}
			</p>
		</header>
	);
};
