import React from "react";

export const RenderInputFile = ({fileToBase64, label}) => {
    return (
        <div className='font-font-family-base'>
            <label
                className='p-button p-component !my-1'
                htmlFor='file'>
                {label}
            </label>
            <input
                id='file'
                type='file'
                className='hidden'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={(e) => fileToBase64(e)}
            />
        </div>
    );
};