import React from 'react';
import { MultiSelectComponent } from '../../../Components/Input/multiSelect';
import { CustomIconButton } from '../../../Components/Buttons/CustomIconButton';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import {
	GenericsNames,
	SurveyNames,
	ToolBarNames,
} from '../../../translations';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faFilterSlash } from '@fortawesome/pro-light-svg-icons';
import { FILTER } from '../../../business/constants';
import { BsFiletypeXls, BsFiletypePdf } from 'react-icons/bs';
import { MdOutlineFilterAlt, MdOutlineFilterAltOff } from 'react-icons/md';
export const FiltersComponents = ({
	formik,
	workCenters,
	areas,
	departments,
	positions,
	collaboratorsArray,
	getFilter,
	guideId,
	dispatchSurveysResult,
	getSurveys,
	selectSurvey,
	handleExcelExport,
	handlePdfExport,
	resetForm,
}) => {
	const { t } = useCustomTranslation();
	return (
		<>
			<MultiSelectComponent
				placeholder='TODOS'
				options={workCenters}
				optionLabel='name'
				optionValue='id'
				value={formik.values.workCenters}
				name='workCenters'
				onChange={formik.handleChange}
				title={getTranslation(t, SurveyNames.WORK_CENTER)}
				showClear={false}
			/>
			<MultiSelectComponent
				placeholder='TODOS'
				options={areas}
				value={formik.values.areas}
				name='areas'
				optionLabel='name'
				optionValue='id'
				onChange={formik.handleChange}
				title={getTranslation(t, SurveyNames.ZONE)}
				showClear={false}
			/>
			<MultiSelectComponent
				placeholder='TODOS'
				options={departments}
				value={formik.values.departments}
				name='departments'
				optionLabel='name'
				optionValue='id'
				onChange={formik.handleChange}
				title={getTranslation(t, SurveyNames.DEPARTMENT)}
				showClear={false}
			/>
			<MultiSelectComponent
				placeholder='TODOS'
				options={positions}
				optionLabel='name'
				optionValue='id'
				value={formik.values.positions}
				name='positions'
				onChange={formik.handleChange}
				title={getTranslation(t, SurveyNames.POSITION)}
				showClear={false}
			/>
			<MultiSelectComponent
				placeholder='TODOS'
				options={collaboratorsArray}
				optionLabel='fullName'
				optionValue='collaboratorId'
				value={formik.values.collaborators}
				name='collaborators'
				onChange={formik.handleChange}
				title={getTranslation(t, GenericsNames.COLLABORATOR)}
				showClear={false}
			/>
			<div className='flex justify-center'>
				<CustomIconButton
					toolTip={getTranslation(t, GenericsNames.FILTER)}
					//icon={faFilter}
					className='!m-1'
					onClick={() => {
						const data = getFilter();
						const payload = {
							id: guideId,
							data: formik.values,
						};
						dispatchSurveysResult({ type: FILTER, payload: payload });
						getSurveys(data);
					}}>
					<MdOutlineFilterAlt className='text-xl' />
				</CustomIconButton>
				<CustomIconButton
					//icon={faFilterSlash}
					className='!m-1'
					toolTip='Limpiar Filtros'
					onClick={() => {
						resetForm();
						getSurveys();
					}}>
					<MdOutlineFilterAltOff className='text-xl' />
				</CustomIconButton>
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.EXCEL)}
					onClick={() => {
						handleExcelExport();
					}}
					disabled={
						!selectSurvey.detail?.general?.TotalResponses ||
						selectSurvey.detail?.general?.TotalResponses === 0
					}
					//icon={faFileExcel}
					className='!m-1'>
					<BsFiletypeXls className='text-xl' />
				</CustomIconButton>
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.PDF)}
					onClick={() => {
						handlePdfExport();
					}}
					disabled={
						!selectSurvey.detail?.general?.TotalResponses ||
						selectSurvey.detail?.general?.TotalResponses === 0
					}
					//icon={faFilePdf}
					className='!m-1'>
					<BsFiletypePdf className='text-xl' />
				</CustomIconButton>
			</div>
		</>
	);
};
