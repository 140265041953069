import React from 'react';
import { GenericsNames } from '../../../../translations';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../../hooks/useTranslations';

import { DialogWithTab } from '../../../../Components/Dialogs/DialogsWithTab';
import { renderWorkCenterInputs } from './WorkCenterForms';
import { AddressInformationComponent } from '../../../../Components/AddressInformationComponent';

export const WorkCenterDialog = ({
	open,
	onClose,
	formik,
	onSave,
	title,
	evaluators,
	zipCodeFound,

	setZipCodeFound,
	activeTab,
	setActiveTab,
}) => {
	const { t } = useCustomTranslation();

	const renderAddressInput = () => {
		return (
			<AddressInformationComponent
				formik={formik}
				open={open}
				zipCodeFound={zipCodeFound}
				setZipCodeFound={setZipCodeFound}
				isEdit={true}
			/>
		);
	};

	const items = [
		{
			textKey: 'Centro de trabajo',
			component: renderWorkCenterInputs(t, formik, evaluators),
			label: 'Centro de trabajo',
			icon: 'pi pi-fw pi-user',
			validationFields: [
				'scode',
				'name',
				'evaluatorId',
				'activities',
				'objective',
				'principalActivity',
				'isActive',
			],
		},
		{
			textKey: 'Domicilio',
			component: renderAddressInput(),
			label: 'Domicilio',
			icon: 'pi pi-fw pi-file',
			validationFields: [
				'zipCode',
				'state',
				'municipality',
				'idSuburb',
				'street',
				'exteriorNumber',
				'interiorNumber',
			],
		},
	];

	return (
		<DialogWithTab
			textSave={getTranslation(t, GenericsNames.SAVE)}
			textCancel={getTranslation(t, GenericsNames.CANCEL)}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
			open={open}
			title={title}
			onClose={onClose}
			formik={formik}
			items={items}
			onSave={onSave}
			//disabled={disable}
		/>
	);
};
