import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FilterMatchMode } from 'primereact/api';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import {
	CollaboratorsNames,
	GenericsNames,
	NavigationNames,
	QuestionaryCollaboratorsNames,
	SurveyCollaboratorsNames,
	SurveyNames,
	SurveysQuestionsNames,
} from '../../translations';

import { EntityToExport, ToExcel, ToPdf } from '../../utils/exportService';

import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { ConfirmDialog } from 'primereact/confirmdialog';

import CustomDropdown from '../../Components/Input/Dropdown';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { EvaluaAlertDialog } from '../../Components/Dialogs/EvaluaAlertDialog';

import { NavigationContext } from '../../context/navigationContext';

import { StoreContext } from '../../business/Provider';
import { SurveyDialog } from '../../Components/Wizard/surveyDialog';
import { SurveyObject } from '../Survey/constants/SurveyObject';
import { SurveySchema } from '../Survey/constants/SurveySchema';

import { Toast } from '../../Components/toast';
import ToolBarThreeColumns from '../../Components/Table/ToolBarThreeColumns';
import useDepartments from '../../hooks/Departaments/useDepartaments';
import useGuideSurveys from '../../hooks/surveys/useGuideSurveys';
import useQuestionnaireGuide from '../../hooks/surveys/useQuestionnaireGuide';
import useReload from '../../hooks/useReload';
import useWorkCenters from '../../hooks/WorkCenters/useWorkCenters';

import { MdOutlineEmail } from 'react-icons/md';
import { TableComponent } from './components/TableComponent';
import { getFiltersData } from './utils';
import { HeaderComponent } from './components/HeaderComponent';

export const SurveyCollaboratorComponent = () => {
	const location = useLocation();
	const company = JSON.parse(localStorage.getItem('companyInfo'));
	const initialValue = location.state;
	const {
		departmentState,
		workCenterState,
		surveyCollaboratorState,
		surveysState,
		dispatchLoading,
		putShareState,
	} = useContext(StoreContext);

	const { guideId } = useParams();
	const navigation = useContext(NavigationContext);
	const { t } = useCustomTranslation();

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [previewValue, setPreviewValue] = useState(null);
	const [collaboratorsList, setCollaboratorsList] = useState([]);
	const [update, setUpdate] = useState(false);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	const [titlePopUp, setTitlePopUp] = useState('');
	const [activeStep, setActiveStep] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedCollaborators, setSelectedCollaborators] = useState(null);
	const [collaboratorsSearh, setColloboratorSearch] = useState([]);
	const [openDialogView, setOpenDialogView] = useState(false);
	const [uri, setUri] = useState('');
	const [toSend, setToSend] = useState([]);

	const [filterList, setFilterList] = useState([]);

	const [selectFilter, setSelectFilter] = useState({
		status: 0,
		department: getTranslation(t, QuestionaryCollaboratorsNames.ALL),
		WorkArea: getTranslation(t, QuestionaryCollaboratorsNames.ALL),
	});
	const [rowDataValue, setRowDataValue] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [survey, setSurvey] = useState(null);
	const filtersData = getFiltersData(t, getTranslation);
	const [filterOpc, setFilterOpc] = useState(filtersData);
	const [onCopyDialog, setOnCopyDialog] = useState(false);

	const toggleModal = () => setOpenDialog(!openDialog);

	const {
		getCollaborators,
		putCancelStatus,
		postSendEmail,
		deleteSurveyCollaborator,
		putShare,
	} = useQuestionnaireGuide(guideId, location?.state?.id);
	const { getSurveyDetails, putSurveys } = useGuideSurveys(guideId);
	const { getDepartment } = useDepartments();
	const { getWorkCenters } = useWorkCenters();
	const [departments, setDepartments] = useState([]);
	const [workCenters, setWorkCenters] = useState([]);
	useEffect(() => {
		if (previewValue === null) {
			setPreviewValue(location.state);
			return getCollaborators(location.state.id);
		}
	}, []);

	useEffect(() => {
		if (departmentState?.departments) {
			setDepartments(departmentState?.departments);
		}
	}, [departmentState]);

	useEffect(() => {
		if (workCenterState?.workCenter) {
			setWorkCenters(workCenterState?.workCenter);
		}
	}, [workCenterState]);

	useEffect(() => {
		if (surveyCollaboratorState?.surveyCollaborator) {
			setCollaboratorsList(surveyCollaboratorState?.surveyCollaborator);
		}
	}, [surveyCollaboratorState]);

	useEffect(() => {
		if (departments) {
			const departmentsList = [...departments, ...filterOpc.department];
			setFilterOpc({ ...filterOpc, department: departmentsList });
		}
	}, [departments]);

	useEffect(() => {
		if (workCenters) {
			const workCentersList = [...workCenters, ...filterOpc.workArea];
			setFilterOpc({ ...filterOpc, workArea: workCentersList });
		}
	}, [workCenters]);

	useEffect(() => {
		if (
			putShareState.putShare !== null &&
			putShareState.loading === false &&
			onCopyDialog
		) {
			setUri(putShareState.putShare.Link || ' No se pudo generar el link');
			setOpenDialogView(true);
		}
	}, [putShareState]);

	useEffect(() => {
		if (previewValue !== null && initialValue.id !== previewValue.id) {
			setPreviewValue(location.state);
			return getCollaborators(initialValue.id);
		}
	}, [initialValue]);
	useEffect(() => {
		if (
			surveysState?.loading === false &&
			initialValue &&
			surveysState?.surveys[guideId]
		) {
			const id = initialValue?.id;
			const index = surveysState?.surveys[guideId].findIndex(
				(surveyValue) => surveyValue.id === id
			);
			setSurvey(surveysState.surveys[guideId][index]);
		}
	}, [surveysState, initialValue]);

	useEffect(() => {
		if (collaboratorsList) {
			filterListFunc(collaboratorsList);
		}
	}, [selectFilter, collaboratorsList]);

	useReload([getCollaborators, getWorkCenters, getDepartment]);

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setSelectFilter({
			...selectFilter,
			[name]: value,
		});
	};
	const filterListFunc = (list) => {
		let filterListTemp = list;
		if (selectFilter.status !== '') {
			switch (selectFilter.status) {
				case 1:
					filterListTemp = filterListTemp.filter(
						(e) => e.answerDate === null && e.sentDate !== null
					);
					break;
				case 2:
					filterListTemp = filterListTemp.filter((e) => e.cancelDate !== null);
					break;
				case 3:
					filterListTemp = filterListTemp.filter(
						(e) => e.sentDate === null && e.answerDate === null
					);
					break;
				case 4:
					filterListTemp = filterListTemp.filter(
						(e) =>
							e.sentDate !== null &&
							e.answerDate !== null &&
							e.cancelDate === null
					);
			}
		}
		if (selectFilter.department !== '') {
			if (
				selectFilter.department !==
				getTranslation(t, QuestionaryCollaboratorsNames.ALL)
			) {
				filterListTemp = filterListTemp.filter(
					(e) => e.departmentName === selectFilter.department
				);
			}
		}
		if (selectFilter.WorkArea !== '') {
			if (
				selectFilter.WorkArea !==
				getTranslation(t, QuestionaryCollaboratorsNames.ALL)
			) {
				filterListTemp = filterListTemp.filter(
					(e) => e.workcenterName === selectFilter.WorkArea
				);
			}
		}
		if (list.length === filterListTemp.length) {
			setFilterList(null);
		} else {
			setFilterList(filterListTemp);
		}
	};
	//Este toast no era necesario ya que se hace desde useGuideSurveys.js por eso se duplicaba
	const updateSurvey = async (Survey) => {
		const response = await putSurveys(Survey);
		await getCollaborators(Survey.id);

		return response;
	};

	const handlerSendEmail = async (collaboratorData) => {
		if (collaboratorsList.length > 0) {
			try {
				if (collaboratorData === null) {
					return await postSendEmail(initialValue.id, toSend);
				} else {
					const name = collaboratorData.name + ' ' + collaboratorData.lastName;

					if (
						collaboratorData.email === null ||
						collaboratorData.email === ''
					) {
						const message =
							'El colaborador ' +
							name +
							'  no tiene email, debe capturarlo antes de enviar la encuesta';
						Toast('warning', message);
					} else if (
						collaboratorData.departmentId === null ||
						collaboratorData.departmentId === ''
					) {
						const message =
							'El colaborador ' +
							name +
							'  no tiene departamento, debe capturarlo antes de enviar la encuesta';
						Toast('warning', message);
					} else {
						return await postSendEmail(initialValue.id, [collaboratorData]);
					}
				}
			} catch (error) {}
		} else {
			Toast('warning', getTranslation(t, GenericsNames.DONT_SEND_EMAIL));
		}
	};
	const setHeaderValues = (values) => {
		let surveyCopy = survey;
		surveyCopy.surveyCode = values.scode;
		surveyCopy.name = values.name;
		surveyCopy.startDate = values.startDate;
		surveyCopy.endDate = values.endDate;
		surveyCopy.conclusion = values.conclusion;
		setSurvey(surveyCopy);
	};
	const addOrUpdateSurvey = async (
		values,
		{ resetForm, _setErrors, setStatus, setSubmitting }
	) => {
		try {
			let response;
			response = await updateSurvey(values);
			if (response !== undefined) {
				resetForm();
				setStatus({ success: true });
				setSubmitting(false);
				setOpenDialog(false);
				setHeaderValues(values);
			}
		} catch (err) {
			setStatus({ success: false });
			setSubmitting(false);
		}
	};

	const formik = useFormik({
		initialValues: { ...SurveyObject },
		validationSchema: SurveySchema,
		onSubmit: addOrUpdateSurvey,
	});

	const handleOnEdit = async (row) => {
		setTitlePopUp(getTranslation(t, SurveyNames.EDIT_SURVEY));
		setActiveStep(0);
		setSelectedCollaborators(null);
		setColloboratorSearch([]);
		const response = await getSurveyDetails(row.id);
		const surveyDetails = response;
		surveyDetails.searchStartDate = null;
		surveyDetails.searchEndDate = null;
		await formik.setValues(surveyDetails);
		toggleModal();
	};

	const handleClose = () => {
		formik.setErrors({});
		formik.resetForm();
		setOpenDialog(false);
	};
	const handlerDeleteOrCancel = async (row) => {
		//Este metodo funciona para borrar colaborador
		const ids = [row.collaboratorId];
		if (row.answerDate === null) {
			await deleteSurveyCollaborator(row.surveyId, ids);
			setUpdate(true);
		} else {
			await putCancelStatus(
				row.surveyId,
				{ collaboratorIds: ids },
				row.surveyCollaboratorId
			);
			setUpdate(true);
		}
	};
	const handlerCopy = async (rowData) => {
		// copy to clipboard
		setOnCopyDialog(true);
		return await putShare(
			rowData.surveyId,
			rowData.surveyCollaboratorId,
			rowData.surveycollaboratorkey,
			rowData.collaboratorId
		);
	};
	const onCopy = async () => {
		navigator.clipboard.writeText(uri);
		setOnCopyDialog(false);
		Toast('success', getTranslation(t, GenericsNames.COPIED));
	};
	const goToCollaboratorQuestionary = (rowData) => {
		console.log(rowData, 'rd');
		const title = `${getTranslation(t, NavigationNames.RESULTS)} ${
			rowData.SurveyCollaboratorCode
		}`;
		navigation.selectTap(
			`/guide-${guideId}/collaboratorQuestionary/${rowData.surveyId}/${rowData.surveyCollaboratorId}`,
			{},
			title
		);
	};

	const tittle = `${initialValue?.guideName}`;
	const size = {
		middle: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
		left: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
		right: `col-span-2  md:col-span-2 lg:col-span-2 2xl:col-span-2`,
	};
	const handleExcelExport = async () => {
		const printObj = {
			...filters,
			sort: sort[0],
			guideId: guideId,
			tittle: tittle,
		};
		printObj.surveyId = initialValue?.id;
		collaboratorsList.length > 0
			? await ToExcel(
					printObj,
					EntityToExport.GUIDE_SURVEYS_COLLABORATORS,
					dispatchLoading
			  )
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	};
	async function handlePDFExport() {
		const printObj = {
			...filters,
			sort: sort[0],
			guideId: guideId,
			tittle: tittle,
		};
		printObj.surveyId = initialValue?.id;
		collaboratorsList.length > 0
			? await ToPdf(
					printObj,
					EntityToExport.GUIDE_SURVEYS_COLLABORATORS,
					dispatchLoading
			  )
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	}

	const mainHeader = () => {
		return (
			<HeaderComponent
				company={company}
				handleOnEdit={handleOnEdit}
				initialValue={initialValue}
				survey={survey}
			/>
		);
	};
	const renderFilter = () => {
		return (
			<div className='flex w-full'>
				<CustomDropdown
					name='status'
					value={selectFilter.status}
					handler={handleFilterChange}
					options={filterOpc.status}
					optionLabel='label'
					optionValue='value'
					className='border-r border-border flex-grow'
					label={getTranslation(t, SurveyCollaboratorsNames.STATUS)}
				/>
				<CustomDropdown
					name='department'
					value={selectFilter.department}
					handler={handleFilterChange}
					options={filterOpc.department}
					optionLabel='name'
					optionValue='name'
					className='border-r border-border flex-grow'
					label={getTranslation(t, GenericsNames.DEPARTMENT)}
				/>
				<CustomDropdown
					name='WorkArea'
					value={selectFilter.WorkArea}
					handler={handleFilterChange}
					options={filterOpc.workArea}
					optionLabel='name'
					optionValue='name'
					className=' flex-grow'
					label={getTranslation(t, GenericsNames.WORK_CENTER)}
				/>
			</div>
		);
	};

	const renderHeader = () => (
		<ToolBarThreeColumns
			size={size}
			value={globalFilterValue}
			setValue={setGlobalFilterValue}
			setFilters={setFilters}
			onChange={onGlobalFilterChange}
			placeholder={getTranslation(t, GenericsNames.SEARCH)}
			onExcelClick={() => {
				handleExcelExport();
			}}
			onPDFClick={() => {
				handlePDFExport();
			}}
			rightChildren={
				<CustomIconButton
					disabled={toSend?.length === 0 || !toSend}
					toolTip={getTranslation(t, GenericsNames.SEND_EMAIL)}
					onClick={() => handlerSendEmail(null)}
					//	icon={faEnvelope}
					className='!m-1'>
					<MdOutlineEmail className='text-xl' />
				</CustomIconButton>
			}
			leftChildren={renderFilter()}></ToolBarThreeColumns>
	);

	const renderDataTable = () => {
		return (
			<TableComponent
				dataTable={filterList === null ? collaboratorsList : filterList}
				renderHeader={renderHeader()}
				workCenterState={workCenterState}
				loadingTableState={
					workCenterState.loading ||
					departmentState.loading ||
					surveyCollaboratorState.loading ||
					surveysState.loading
				}
				filters={filters}
				getTranslation={getTranslation}
				t={t}
				toSend={toSend}
				goToCollaboratorQuestionary={goToCollaboratorQuestionary}
				sort={sort}
				setSort={setSort}
				guideId={guideId}
				handlerSendEmail={handlerSendEmail}
				handlerCopy={handlerCopy}
				setToSend={setToSend}
				setRowDataValue={setRowDataValue}
				setShowAlert={setShowAlert}
			/>
		);
	};

	const renderAlert = () => {
		return (
			<ConfirmDialog
				visible={showAlert}
				onHide={() => setShowAlert(false)}
				message={
					rowDataValue?.answerDate !== null
						? '¿Desea cancelar la encuesta del colaborador?'
						: '¿Estás seguro que quieres eliminar el colaborador?'
				}
				header='Alerta'
				icon='pi pi-exclamation-triangle'
				accept={() => handlerDeleteOrCancel(rowDataValue)}
				reject={() => setShowAlert(false)}
				acceptLabel='Si'
			/>
		);
	};

	return (
		<div className='w-full h-full relative'>
			{showAlert ? renderAlert() : null}
			{mainHeader()}
			{renderDataTable()}
			{openDialog ? (
				<SurveyDialog
					title={titlePopUp}
					formik={formik}
					open={openDialog}
					onClose={handleClose}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					selectedCollaborators={selectedCollaborators}
					setSelectedCollaborators={setSelectedCollaborators}
					collaboratorsSearh={collaboratorsSearh}
					setColloboratorSearch={setColloboratorSearch}
				/>
			) : null}

			<EvaluaAlertDialog
				title={getTranslation(t, GenericsNames.COPY)}
				setOpen={setOpenDialogView}
				open={openDialogView}
				message={<input disabled value={uri} className='w-full' />}
				cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
				agreeLabel={getTranslation(t, GenericsNames.COPY)}
				agreeIcon='pi pi-copy'
				onConfirmFunction={onCopy}
				style={{ width: '70vw' }}
				onCancelFunction={() => setOnCopyDialog(false)}
			/>
		</div>
	);
};
