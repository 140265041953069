import { PUT_SHARE, PUT_SHARE_ERROR, PUT_SHARE_SUCCESS } from '../../constants';

export const putShareReducer = (state, actions) => {
	switch (actions.type) {
		case PUT_SHARE:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case PUT_SHARE_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case PUT_SHARE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				putShare: actions.payload,
			};
		default:
			return state;
	}
};
