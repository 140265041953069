import { QuestionaryCollaboratorsNames } from '../../translations';

export function getFiltersData(t, getTranslation) {
	let filtersData = {
		status: [
			{ label: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
			{
				label: getTranslation(t, QuestionaryCollaboratorsNames.RESPONDED),
				value: 1,
			},
			{
				label: getTranslation(t, QuestionaryCollaboratorsNames.CANCEL),
				value: 2,
			},
			{
				label: getTranslation(t, QuestionaryCollaboratorsNames.SEND),
				value: 3,
			},
			{
				label: getTranslation(t, QuestionaryCollaboratorsNames.COMPLETED),
				value: 4,
			},
		],
		department: [
			{ name: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
		],
		workArea: [
			{ name: getTranslation(t, QuestionaryCollaboratorsNames.ALL), value: 0 },
		],
	};
	return filtersData;
}
