import { useContext, useEffect, useState } from 'react';
import { Toast } from '../../Components/toast';
import useApi from '../api';
import { StoreContext } from '../../business/Provider';
import {
	GET_SCORE_RANGE,
	GET_SCORE_RANGE_ERROR,
	GET_SCORE_RANGE_SUCCESS,
} from '../../business/constants';

const useScoreRange = (guideId) => {
	const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide`;
	const [scoreRange, setScoreRange] = useState(undefined);
	const [loadingscoreRange, setloadingscoreRange] = useState(false);
	const { scoreRangesState, dispatchScoreRanges } = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const getScoreRanges = async () => {
		try {
			setloadingscoreRange(true);
			const response = await getScoreRange(guideId);
			//array swap position 2 and 3
			const ranges = response.data.data.ranges;

			setScoreRange(ranges);
			setloadingscoreRange(false);
		} catch (error) {
			setScoreRange([]);
			setloadingscoreRange(false);
		}
	};

	const getScoreRange = async () => {
		try {
			dispatchScoreRanges({ type: GET_SCORE_RANGE });
			const result = await genericApiCall(
				`${baseUrl}/${guideId}/ranges`,
				'get'
			);
			if (result.status === 200) {
				const payload = {
					id: guideId,
					data: result.data.data.ranges,
				};
				dispatchScoreRanges({
					type: GET_SCORE_RANGE_SUCCESS,
					payload: payload,
				});
			} else {
				let message = '';
				if (result.data) message = result.data.message;
				else message = result.message;
				dispatchScoreRanges({ type: GET_SCORE_RANGE_ERROR, payload: message });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchScoreRanges({ type: GET_SCORE_RANGE_ERROR });
		}
	};

	useEffect(() => {
		// check if questionsState contains the key { 1 : [], 2 : [] }
		const contain = Object.keys(scoreRangesState.scoreRanges).includes(guideId);
		if (
			!contain &&
			scoreRangesState.loading === false &&
			scoreRangesState.error === null
		) {
			getScoreRange();
		}
	}, [guideId]);

	return {
		setScoreRange,
		scoreRange,
		loadingscoreRange,
		getScoreRange,
	};
};
export default useScoreRange;
