import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
export const ChartBarComponent = ({
	labels = [],
	dataChart = [],
	name = 'Grafica prueba',
	label = 'valor',
	id = '',
}) => {
	const max = Math.max.apply(null, dataChart);
	const newMax = max < 10 ? 10 : max + 10;
	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);
	const options = {
		id: id,
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: name,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				suggestedMin: 0, // Establece el valor mínimo
				suggestedMax: newMax, // Establece el valor máximo (ajústalo según tus necesidades)
				ticks: {
					stepSize: 5, // Configura el incremento de 5 en 5 en el eje Y
				},
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				label: label,
				data: dataChart,
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
		],
	};
	return (
		<Bar
			id={'bar_' + id}
			options={options}
			data={data}
			className={
				' !flex  !h-full !relative !justify-center !items-center !content-center !w-full'
			}
			style={{
				position: 'absolute',
				top: '0',
				left: '0',
				bottom: '0',
				height: '0',
			}}
		/>
	);
};
