import React from 'react';
import { Dialog } from 'primereact/dialog';
import { WizardDialog } from '../../../../Components/Wizard/WizardDialog';

import { useCustomTranslation } from '../../../../hooks/useTranslations';
import { renderWorkCenterInputs } from './WorkCenterForms';
import { AddressInformationComponent } from '../../../../Components/AddressInformationComponent';

export const WorkCenterWizard = ({
	title,
	open,
	onClose,
	formik,
	activeStep,
	setActiveStep,
	evaluators,
	zipCodeFound,
	onZipSearch,
	suburbs,
	setZipCode,
	setZipCodeFound,
}) => {
	const { t } = useCustomTranslation();

	const steps = [
		{
			label: 'Centro de trabajo',
			title: 'Centro de trabajo',
			validationFields: [
				'scode',
				'name',
				'evaluatorId',
				'activities',
				'objective',
				'principalActivity',
				'isActive',
			],
		},
		{
			label: 'Domicilio',
			title: 'Domicilio',
			validationFields: [
				'zipCode',
				'state',
				'municipality',
				'idSuburb',
				'street',
				'exteriorNumber',
				'interiorNumber',
			],
		},
	];
	const handleNext = async (e) => {
		e.preventDefault();
		//Get All Errors
		const validationErrors = await formik.validateForm();
		//delete key from object if not exist in validationFields
		const errors = Object.keys(validationErrors).reduce((acc, key) => {
			if (steps[activeStep].validationFields.includes(key)) {
				acc[key] = validationErrors[key];
			}
			return acc;
		}, {});
		if (Object.keys(errors).length > 0) {
			//Filter the error that belongs to the step.
			if (steps[activeStep].validationFields) {
				//Execute Touch for validation fields.
				let validationFields = steps[activeStep].validationFields;
				validationFields.forEach((element) =>
					formik.setFieldTouched(element, true)
				);
				//formik.setTouched(setNestedObjectValues(validationErrors, true));
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const renderAddressInputs = () => {
		return (
			<AddressInformationComponent
				formik={formik}
				open={open}
				zipCodeFound={zipCodeFound}
				setZipCodeFound={setZipCodeFound}
			/>
		);
	};
	const renderStep = (step) => {
		switch (step) {
			case 0:
				return renderWorkCenterInputs(t, formik, evaluators);
			case 1:
				return renderAddressInputs();
			default:
				return <></>;
		}
	};
	return (
		<>
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>
						{title.toUpperCase()}
					</p>
				}
				visible={open}
				modal
				style={{ width: '70vw' }}
				onHide={() => onClose()}>
				<WizardDialog
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					steps={steps}
					formik={formik}
					onNext={handleNext}
					onBack={handleBack}
					onClose={() => onClose()}
					handleBack={handleBack}
					handleNext={handleNext}
					open={open}
					title={title}>
					<div className='border-b border-border p-1 flex-grow'>
						{renderStep(activeStep)}
					</div>
				</WizardDialog>
			</Dialog>
		</>
	);
};
