import React from 'react';
import { CustomIconButton } from './Buttons/CustomIconButton';
import { BsPlusCircle } from 'react-icons/bs';
export const AddButtonComponentIcon = ({
	onClick,
	toolTip,
	disabled = false,
}) => {
	return (
		<CustomIconButton
			className='!m-1'
			toolTip={toolTip}
			disabled={disabled}
			onClick={onClick}
			//icon={faPlusCircle}
		>
			<BsPlusCircle className='text-xl' />
		</CustomIconButton>
	);
};
