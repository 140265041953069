import React, { useEffect, useState } from 'react';
import { faEdit, faLock } from '@fortawesome/free-solid-svg-icons';
import {
	getTranslation,
	useCustomTranslation,
} from './../../hooks/useTranslations';

import { AlertComponentModal } from '../Modal';
import { ButtonTextWithIcon } from '../Buttons/ButtonTextWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../Input/Input';
import { SelectComponent } from '../Select';
import { SurveysQuestionsNames } from '../../translations';
import { customValidationInput } from '../../utils/utils';
import { orderAlphabetically } from '../../utils/arrayService';

export const ValidationComponent = ({
	setStep,
	collaboratorData,
	departaments,
}) => {
	const { t } = useCustomTranslation();
	const [data, setData] = useState({
		lastName: '',
		departament: '',
	});

	const [acceptTerms, setAcceptTerms] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [dataInputNameIsCorrect, setDataInputNameIsCorrect] = useState(false);
	const [messageName, setMessageName] = useState('');
	const [dataInputDepartmentIsCorrect, setDataInputDepartmentIsCorrect] =
		useState(false);
	const [messageDepartment, setMessageDepartment] = useState('');
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		if (
			data.lastName !== '' &&
			data.departament !== '' &&
			acceptTerms === true
		) {
			setEnableButton(true);
		} else {
			setEnableButton(false);
		}
	}, [data, acceptTerms]);

	const handleChangeGeneral = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const validationInputNameForm = (e) => {
		return customValidationInput(
			e,
			setDataInputNameIsCorrect,
			setMessageName,
			'Apellido',
			collaboratorData?.lastName
		);
	};

	const validationInputDepartmentForm = (value) => {
		return customValidationInput(
			value,
			setDataInputDepartmentIsCorrect,
			setMessageDepartment,
			'Departamento',
			collaboratorData?.departmentName
		);
	};

	const validationButton = () => {
		if (
			messageDepartment === '' &&
			messageName === '' &&
			acceptTerms === true
		) {
			setStep(2);
		} else {
			setShowAlert(true);
		}
	};
	const renderSurveyName = () => {
		let surveyName;
		if (collaboratorData !== null) {
		  const { questionaryId, questionaryShortName, questionaryName } = collaboratorData;
		surveyName = questionaryId <= 5 ? "NOM035" : "NOM037";
		  const concatenatedString = `${surveyName} ${questionaryShortName} ${questionaryName}`;
		  return <p style={{ color: "#002D7B", fontWeight: "bold" }}>{concatenatedString}</p>;
		} else {
		  return null; // No renderizar nada si questionaryId es null
		}
	  };
	const newDepartments = orderAlphabetically(departaments, 'name');

	const renderForm = () => {
		return (
      <div className="flex w-full h-[60%] lg:h-1/2 xl:h-1/2 2xl:h-1/2 flex-col items-center content-center justify-center">
        <div className="flex w-full h-2/3  items-center content-center justify-center flex-col">
          <div className="flex w-2/3 h-full   items-center content-center  justify-center ">
            <div className="flex w-full   lg:w-1/2 xl:w-1/2 2xl:w-1/2 justify-center ">
              <Input
                // title="Nombre"
                type="text"
                name="lastName"
                onChange={handleChangeGeneral}
                value={data.lastName}
                placeholder="Apellido Paterno"
                //  className={classItem}
                onBlur={(e) => validationInputNameForm(e.target.value)}
                error={dataInputNameIsCorrect}
                important
              />
            </div>
            <SelectComponent
              className={
                "flex flex-col h-1/2 lg:h-full xl:h-full 2xl:h-full p-2 w-full  lg:w-1/2 xl:w-1/2 2xl:w-1/2 justify-center border-red "
              }
              valueSelected={
                newDepartments?.filter(
                  (item) => item.name === data.departament
                )[0]
              }
              labelText={"Departamento"}
              ValuesOptions={newDepartments}
              handleChange={(e) => {
                validationInputDepartmentForm(e.value.name);
                setData({
                  ...data,
                  departament: e.value.name,
                });
              }}
              placeholderText={"..."}
              name={"departament"}
              optionLabel={"name"}
              error={dataInputDepartmentIsCorrect}
              important
            />
          </div>
          <div className="flex w-2/3 h-full   items-center content-center  justify-center ">
            <div className="flex w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 justify-start items-start">
              <small style={{textAlign: "left",  fontStyle: "italic", color: "#808080" }}>
                Escribirlo tal cual se muestra en el correo
              </small>
            </div>

            <div className="flex w-full   lg:w-1/2 xl:w-1/2 2xl:w-1/2  p-2 justify-center "></div>
          </div>
        </div>
        <div className="flex w-full h-[20%] lg:h-1/3 xl:h-1/3 2xl:h-1/3   flex-row items-start content-center justify-center    ">
          <div className="flex w-40 lg:w-1/2 xl:w-1/2 2xl:w-1/2 h-full p-2 justify-center content-center items-center">
            <ButtonTextWithIcon
              onClick={() => (enableButton ? validationButton() : null)}
              title={"Comenzar"}
              type="primary"
              disabled={!enableButton}
              Icon={<FontAwesomeIcon icon={faEdit} title={"edit"} />}
              position={"left"}
            />
          </div>
        </div>
      </div>
    );
	};

	const rendeHeader = () => {
		return (
			<div className="h-16  w-full flex flex-row bg-secondary rounded-t-lg ">
				<div className="flex w-full content-center items-center justify-center">
				<h1 className="text-white font-bold uppercase text-center text-xs sm:text-lg md:text-sm lg:text-lg xl:text-lg 2xl:text-xl">
					¡BIENVENIDO!
				</h1>
				</div>
			</div>
    );
	};

	return (
		<div className='flex w-[95%] h-[100%] lg:h-4/5 xl:w-4/5 xl:h-4/5 bg-white shadow-2xl divide-y-2 relative rounded-lg'>
  {showAlert === true ? (
    <AlertComponentModal
      showAlert={showAlert}
      actionButton={() => setShowAlert(false)}
      validations={[messageDepartment, messageName]}
      buttonActionText='Aceptar'
      type={1}
    />
  ) : null}

  <div className='w-full h-full flex flex-col divide-y-2 rounded-lg'>
    {rendeHeader()}

    <div className='h-[90%] lg:h-5/6 xl:h-5/6 2xl:h-5/6 w-full flex flex-col p-2 lg:p-6 xl:p-6 2xl:p-6 divide-y-1'>
      <div className='flex w-full h-[40%] lg:h-1/2 xl:h-1/2 2xl:h-1/2 flex-col'>
        {/* Primer Div */}
        <div className='flex w-full h-[45%] lg:h-3/5 xl:h-3/5 2xl:h-3/5 content-center flex-col justify-center items-center'>
          <p className='text-lg lg:text-xl xl:text-xl 2xl:text-xl mb-4 mt-8'>
            {renderSurveyName()}
          </p>
          <div className='flex flex-row content-center items-center justify-center w-28 h-full bg-[#D9D9D9] rounded-full'>
            <FontAwesomeIcon
              className='text-[#002D7B] w-24 h-1/2'
              icon={faLock}
              title={'lock'}
            />
          </div>
        </div>

        {/* Segundo Div */}
        <div className='flex w-full h-[100%] lg:h-5/5 xl:h-5/5 2xl:h-5/5 bg-gray-100 rounded-md p-2 mt-10 mb-10 content-center flex-col justify-center items-center'>
          <p className='text-xs sm:text-lg md:text-sm lg:text-lg xl:text-lg 2xl:text-xl font-bold ml-6 mt-2'>
            {getTranslation(t, SurveysQuestionsNames.SurveyPrivacy)}
          </p>
          <p className='text-xs sm:text-lg md:text-sm lg:text-sm xl:text-sm 2xl:text-sm justify-center mb-2 mt-2'>
            {getTranslation(t, SurveysQuestionsNames.SurveyTherms)}
          </p>
          <div className='flex w-full h-[30%] lg:h-1/5 xl:h-1/5 2xl:h-1/5 content-end items-end justify-center align-middle mb-2 mt-2'>
            <div className='flex content-center items-center justify-center flex-row'>
              <input
                type='checkbox'
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <p className='ml-6 lg:text-lg xl:text-lg 2xl:text-lg'>Acepto</p>
            </div>
          </div>
        </div>

        {/* Tercer Div */}
        <div className='flex w-full h-[25%] lg:h-1/5 xl:h-1/5 2xl:h-1/5 content-center justify-center items-center text-xs lg:text-base xl:text-base 2xl:text-base  '>
          {getTranslation(t, SurveysQuestionsNames.SurveySegurityQuestions)}
        </div>
		{renderForm()}
      </div>      
    </div>
  </div>
</div>

	);
};
