import moment from 'moment';
import { FILTER } from '../../business/constants';
import { IsNullOrEmpty } from '../../utils';

/**
 * It filters an array of objects based on a start date and end date
 * @param startDate - the start date of the filter
 * @param endDate - the end date of the filter
 * @param copy - the array of objects that I'm filtering
 */
const filterDate = (startDate, endDate, copy) => {
	if (startDate !== undefined && endDate === null) {
		return copy.filter((item) =>
			moment(startDate, 'DD/MM/YYYY').isSameOrBefore(
				moment(item.startDate, 'DD/MM/YYYY')
			)
		);
	} else if (startDate === null && endDate !== undefined) {
		return copy.filter((item) =>
			moment(endDate, 'DD/MM/YYYY').isSameOrAfter(
				moment(item.startDate, 'DD/MM/YYYY')
			)
		);
	} else if (!IsNullOrEmpty(startDate) && !IsNullOrEmpty(endDate)) {
		const newCopy = copy.filter((item) =>
			moment(startDate, 'DD/MM/YYYY').isSameOrBefore(
				moment(item.startDate, 'DD/MM/YYYY')
			)
		);
		return newCopy.filter((item) =>
			moment(endDate, 'DD/MM/YYYY').isSameOrAfter(
				moment(item.startDate, 'DD/MM/YYYY')
			)
		);
	} else {
		return copy;
	}
};

/**
 * It takes a list of scodes and a list of objects, and returns a list of objects that have scodes that
 * are in the list of scodes
 * @returns const filterScode = (scode, copy) => {
 *     if (IsNullOrEmpty(scode)) {
 *       return (copy);
 *     } else {
 *       return copy.filter(item => {
 *         return (scode.includes(item.scode));
 *       });
 *     }
 *   }
 */
const filterScode = (scode, copy) => {
	if (IsNullOrEmpty(scode)) {
		return copy;
	} else {
		return copy.filter((item) => {
			return scode.includes(item.scode);
		});
	}
};

/**
 * It filters the copy of the original array of objects based on the result value
 * @returns a filtered array of objects.
 */
const filterResult = (resultValue, copy) => {
	if (IsNullOrEmpty(resultValue) || resultValue === 'Todos') {
		return copy;
	} else {
		const newCopy = [];
		copy.filter((item) => {
			// lowercase both strings if is not null
			const itemResult = item.currentResult
				? item.currentResult.toLowerCase()
				: null;
			console.log(itemResult);
			console.log(resultValue.toLowerCase());
			if (resultValue.toLowerCase() === itemResult) {
				newCopy.push(item);
			}
		});
		return newCopy;
	}
};

/**
 * It takes the formik values, filters the surveys by date, scode, and result, and then sets the
 * surveys to the filtered list
 */
export const generalFilter = (
	formik,
	guideId,
	setSurveys,
	dispatchSurveys,
	surveysState
) => {
	let { result, scodeSelect, searchStartDate, searchEndDate } = formik.values;
	let startDate = searchStartDate ? searchStartDate : null;
	let endDate = searchEndDate ? searchEndDate : null;
	let copy = [...surveysState.surveys[guideId]];
	if (guideId !== 1) {
		copy = filterResult(result, copy);
	}

	if (startDate !== null || endDate !== null) {
		copy = filterDate(startDate, endDate, copy);
	}
	if (scodeSelect !== null) {
		copy = filterScode(scodeSelect, copy);
	}

	setSurveys(copy);

	const payload = {
		guideId: guideId,
		filters: formik.values,
		filterList: copy,
	};
	dispatchSurveys({ type: FILTER, payload: payload });
};
