import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';

// hooks
import useCollaborators from '../../hooks/Collaborator/useCollaborators';
import useDepartments from '../../hooks/Departaments/useDepartaments';
import useGuideSurveys from '../../hooks/surveys/useGuideSurveys';
import usePosition from '../../hooks/position/usePosition';
import useSurveysResult from '../../hooks/surveys/useSurveyResult';
import useZone from '../../hooks/zone/useZone';
import useWorkCenters from './../../hooks/WorkCenters/useWorkCenters';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

//utils
import { ResultsNames } from '../../translations';
import { capitalizeWords, findNestedObj } from '../../utils/utils';
import { ToExcel, ToPdf, EntityToExport } from '../../utils/exportService';
import routes from '../../routes';
import { FILTER } from '../../business/constants';

//context and providers
import { NavigationContext } from '../../context/navigationContext';
import { LoadingContext } from '../../context/LoadingContext';
import { StoreContext } from '../../business/Provider';

//components
import { ContentComponent } from './components/ContentComponent';
import { FiltersComponents } from './components/FiltersComponents';
import { FullLoading } from '../../Components/FullLoading';
import { HeaderComponent } from './components/HeaderComponent';
import { SideContainer } from '../../Components/sideContainer';
import { Toast } from '../../Components/toast';
import useReload from '../../hooks/useReload';

export const Results = () => {
	const { guideId } = useParams();
	// get body from url using react-router-dom
	const location = useLocation();
	const context = useContext(StoreContext);
	const {
		collaboratorState,
		workCenterState,
		departmentState,
		areaState,
		positionState,
		surveysResultState,
		surveysState,
		dispatchSurveysResult,
	} = context;
	// navigation context
	const navigation = useContext(NavigationContext);
	const { t } = useCustomTranslation();
	const title = findNestedObj(
		routes,
		'tapTitle',
		navigation.currentTap?.father
	);

	// getSurveys
	const { getSurveyResults } = useSurveysResult(guideId);
	useGuideSurveys(guideId);
	useCollaborators();
	useDepartments();
	useWorkCenters();
	useZone();
	usePosition();

	const [surveys, setSurvey] = useState([]);
	const [surveyIdState, setSurveyIdState] = useState(null);
	const [collaboratorsArray, setCollaboratorsArray] = useState([]);
	const [collaborators, setCollaborators] = useState([]);
	const [selectSurvey, setSelectSurvey] = useState({
		base: null,
		detail: null,
	});
	const [departments, setDepartments] = useState([]);
	const [workCenters, setWorkCenters] = useState([]);
	const [areas, setAreas] = useState([]);
	const [positions, setPositions] = useState([]);
	const loadingObj = useContext(LoadingContext);

	useEffect(() => {
		const contain = Object.keys(surveysResultState.surveysResults).includes(
			guideId
		);

		if (contain) {
			const TempSurveyIdState =
				surveysResultState.surveysResults[guideId].surveyId;

			setSelectSurvey({
				base: surveysState.surveys[guideId]?.find(
					(survey) => survey.id === TempSurveyIdState
				),
				detail: surveysResultState.surveysResults[guideId],
			});
			setSurveyIdState(TempSurveyIdState);
			const filter = surveysResultState.filters[guideId];
			filter
				? formik.setValues(filter)
				: formik.setValues({
						workCenters: [],
						areas: [],
						departments: [],
						positions: [],
						collaborators: [],
				  });
		} else {
			setSelectSurvey({
				base: null,
				detail: null,
			});
			formik.setValues({
				workCenters: [],
				areas: [],
				departments: [],
				positions: [],
				collaborators: [],
			});
		}
	}, [guideId, location]);

	useEffect(() => {
		if (
			collaboratorState.loading === false &&
			collaboratorState.collaborators?.length > 0
		) {
			setCollaborators(collaboratorState.collaborators);
		}
	}, [collaboratorState]);

	useEffect(() => {
		if (
			workCenterState.loading === false &&
			workCenterState.workCenter?.length > 0
		) {
			setWorkCenters(workCenterState.workCenter);
		}
	}, [workCenterState]);

	useEffect(() => {
		if (
			departmentState.loading === false &&
			departmentState.departments?.length > 0
		) {
			setDepartments(departmentState.departments);
		}
	}, [departmentState]);

	useEffect(() => {
		if (areaState.loading === false && areaState.areas?.length > 0) {
			setAreas(areaState.areas);
		}
	}, [areaState]);

	useEffect(() => {
		if (
			positionState.loading === false &&
			positionState.positions?.length > 0
		) {
			setPositions(positionState.positions);
		}
	}, [positionState]);

	useEffect(() => {
		const contain = Object.keys(surveysState.surveys).includes(guideId);
		if (surveysState.loading === false && contain) {
			setSurvey(surveysState.surveys[guideId]);
		}
	}, [surveysState.loading, guideId]);

	useEffect(() => {
		const temp = location.state?.surveyId;
		if (temp !== surveyIdState && temp !== undefined) {
			setSurveyIdState(temp);
		}
	}, [location]);

	useEffect(() => {
		if (collaborators) {
			//add full name to the collaborator
			const collaboratorsWithFullName = collaborators.map((collaborator) => {
				return {
					...collaborator,
					fullName: capitalizeWords(
						`${collaborator.name} ${collaborator.lastName}`
					),
				};
			});
			setCollaboratorsArray(collaboratorsWithFullName);
		}
	}, [collaborators]);

	useEffect(() => {
		if (surveyIdState && location.state?.surveyId) {
			getSurveys();
			resetForm();
		}
	}, [surveyIdState, surveys, location]);

	const getSurveys = async (filterResult = {}, surveyId = null) => {
		loadingObj.setLoading(true);
		const id = surveyId || surveyIdState;
		filterResult.surveyId = id;
		const result = await getSurveyResults(id, filterResult);
		// validate if result.status === 200
		if (result.status === 200) {
			setSelectSurvey({
				base: surveys?.find((survey) => survey.id === id),
				detail: result.data.data.results,
			});
		} else {
			Toast('warning', 'Error', result.message);
		}
		loadingObj.setLoading(false);
	};

	useReload([getSurveys]);

	const formik = useFormik({
		initialValues: {
			workCenters: [],
			areas: [],
			departments: [],
			positions: [],
			collaborators: [],
		},
		onSubmit: () => {
			formik.resetForm();
		},
	});

	const getFilter = () => {
		return {
			surveyId: surveyIdState,
			areaList: formik.values.areas,
			departmentList: formik.values.departments,
			workcenterList: formik.values.workCenters,
			positionList: formik.values.positions,
			collaboratorList: formik.values.collaborators,
			guideId: guideId
		};
	};

	const handleExcelExport = async () => {
		if (surveyIdState !== null) {
			try {
				loadingObj.setLoading(true);
				let type = EntityToExport.RESULT_BY_SURVEY
				if(guideId == 6 || guideId == 7){
					type = EntityToExport.SURVEYS_RESULT_LV
				}
				await ToExcel(getFilter(), type);
				loadingObj.setLoading(false);
			} catch (err) {
				loadingObj.setLoading(false);
			}
			//Aqui debe traer los resultados generales de la encuesta
		} else {
			Toast(
				'warning',
				'Error',
				'No se puede exportar a EXCEL, no hay encuesta seleccionada'
			);
		}
	};

	const handlePdfExport = async () => {
		if (surveyIdState !== null) {
			try {
				loadingObj.setLoading(true);
				const filter = getFilter();
				let type = EntityToExport.RESULT_BY_SURVEY
				if(guideId == 6 || guideId == 7){
					type = EntityToExport.SURVEYS_RESULT_LV
				}
				filter.pdf = true;
				await ToPdf(filter, type);
				loadingObj.setLoading(false);
			} catch (err) {
				loadingObj.setLoading(false);
			}
		} else {
			Toast(
				'warning',
				'Error',
				'No se puede exportar a PDF, no hay encuesta seleccionada'
			);
		}
	};

	const resetForm = () => {
		formik.resetForm();
		const payload = {
			id: guideId,
			data: {},
		};
		dispatchSurveysResult({ type: FILTER, payload: payload });
	};

	return (
		<div className='w-full h-full flex-col flex !font-font-family-base relative'>
			<HeaderComponent title={title} />
			<div className='flex flex-grow'>
				{
					<FullLoading
						show={
							collaboratorState.loading ||
							workCenterState.loading ||
							departmentState.loading ||
							areaState.loading ||
							positionState.loading
						}
					/>
				}
				<SideContainer
					className='w-[240px]'
					title={getTranslation(t, ResultsNames.REPORT)}>
					<FiltersComponents
						formik={formik}
						workCenters={workCenters}
						areas={areas}
						departments={departments}
						positions={positions}
						collaboratorsArray={collaboratorsArray}
						getFilter={getFilter}
						guideId={guideId}
						dispatchSurveysResult={dispatchSurveysResult}
						getSurveys={getSurveys}
						selectSurvey={selectSurvey}
						handleExcelExport={handleExcelExport}
						handlePdfExport={handlePdfExport}
						resetForm={resetForm}
					/>
				</SideContainer>
				<div className='w-full'>
					<ContentComponent
						selectSurvey={selectSurvey}
						surveyIdState={surveyIdState}
						surveys={surveys}
						setSurveyIdState={setSurveyIdState}
						resetForm={resetForm}
						getSurveys={getSurveys}
						guideId={guideId}
					/>
				</div>
			</div>
		</div>
	);
};
