import React, { useContext, useEffect, useState } from 'react';
import { SampleNames } from '../../translations';
import { findNestedObj } from '../../utils/utils';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { Card } from 'primereact/card';
import ecuacion from '../../assets/img/ecuacion.png';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { NavigationContext } from '../../context/navigationContext';
import { WorkArea } from '../../Components/WorkArea';
import routes from '../../routes';
import { useFormik } from 'formik';
import useCollaborators from '../../hooks/Collaborator/useCollaborators';
import { StoreContext } from '../../business/Provider';
import { InputText } from 'primereact/inputtext';

export const SurveySample = () => {
	const [collaborators, setCollaborators] = useState([]);
	const [countColl, setCountColl] = useState();
	const { collaboratorState } = useContext(StoreContext);
	const {} = useCollaborators();
	useEffect(() => {
		//console.log('collaborators',collaboratorState)
		if (
			collaboratorState.loading === false &&
			collaboratorState.collaborators?.length > 0
		) {
			const collaboratorsFilter = collaboratorState.collaborators;
			collaboratorsFilter.filter((valor) => {
				valor.isActive === true;
			});

			setCollaborators(collaboratorsFilter);
			setCountColl(collaboratorsFilter.length);
			if (collaboratorsFilter > 50) {
				setResult(
					Math.round(
						(0.9604 * collaboratorsFilter.length) /
							(0.0025 * (collaboratorsFilter.length - 1) + 0.9604)
					)
				);
			} else {
				setResult(getTranslation(t, SampleNames.LESS_50));
			}
		}
	}, [collaboratorState]);
	const formik = useFormik({
		initialValues: {
			collaboratorsInput: null,
		},
		validate: (data) => {
			let errors = {};
			if (data.collaboratorsInput > 100000) {
				errors.collaboratorsInput = 'La cantidad máxima es 100,000';
			}
			return errors;
		},
		onSubmit: (data) => {
			let col = data.collaboratorsInput;
			if (col > 50) {
				setResult(Math.round((0.9604 * col) / (0.0025 * (col - 1) + 0.9604)));
			} else {
				setResult(getTranslation(t, SampleNames.LESS_50));
			}
		},
	});

	const [result, setResult] = useState();
	/** navigation context **/
	const navigation = useContext(NavigationContext);
	const tittle = findNestedObj(
		routes,
		'tapTitle',
		navigation.currentTap?.father
	);
	const { t } = useCustomTranslation();
	const renderEquation = () => {
		return (
			<>
				<div className='w-full h-20 font-font-family-base text-sm p-2'>
					<div className=' flex justify-center'>
						<p>{getTranslation(t, SampleNames.SAMPLE_TEXT)}</p>
					</div>
				</div>
				<div style={{ justifyContent: 'center' }}>
					<center>
						<img width='20%' src={ecuacion} alt='Ecuación' />{' '}
					</center>
				</div>
			</>
		);
	};
	const renderInfo = () => {
		return (
			<>
				<div className='w-full h-20 font-font-family-base text-sm p-2'>
					<p>{getTranslation(t, SampleNames.WHERE)}</p> <br />
					<p style={{ textIndent: '40px' }}>
						<b>N </b>
						{getTranslation(t, SampleNames.SAMPLE_N)}
					</p>
					<p style={{ textIndent: '40px' }}>
						<b>n </b>
						{getTranslation(t, SampleNames.SAMPLE_n)}
					</p>
					<p style={{ textIndent: '40px' }}>
						{getTranslation(t, SampleNames.SAMPLE_NUMBERS)}
					</p>
				</div>
			</>
		);
	};
	const renderCalc = () => {
		const isFormFieldInvalid = (name) =>
			!!(formik.touched[name] && formik.errors[name]);
		const getFormErrorMessage = (name) => {
			return isFormFieldInvalid(name) ? (
				<small className='p-error'>{formik.errors[name]}</small>
			) : (
				<small className='p-error'>&nbsp;</small>
			);
		};
		const footerCard1 = () => {
			return (
				<p style={{ fontFamily: 'serif', fontSize: '12px' }}>
					{getTranslation(t, SampleNames.OMISION)}
				</p>
			);
		};
		const footerCard2 = () => {
			return (
				<p style={{ fontFamily: 'serif', fontSize: '12px' }}>
					{getTranslation(t, SampleNames.INFO_DATA)}
				</p>
			);
		};
		return (
			<>
				<br />
				<div className='card p-fluid flex-wrap gap-3 p-3 justify-center'>
					<Divider />
					<p>{getTranslation(t, SampleNames.SAMPLE_INSTRUCTIONS)}</p>
					<br />
					<div className='flex card p-fluid flex-wrap gap-3 p-3 justify-center'>
						<br />
						<Card
							footer={footerCard1}
							style={{ minWidth: '40%', maxWidth: '40%' }}>
							<form onSubmit={formik.handleSubmit}>
								<label>
									{' '}
									{getTranslation(
										t,
										SampleNames.TOTAL_COLLABORATORS
									)} &nbsp;{' '}
								</label>
								{/* <InputNumber
									className='p-inputtext-sm'
									inputId='collaboratorsInput'
									name='collaboratorsInput'
									value={countColl}
									//type='number'
									onValueChange={(e) => {
										formik.setFieldValue('collaboratorsInput', e.value);
									}}
									style={{ maxWidth: '40%', maxHeight: '80%', margin: 'auto' }}
								/> */}
								<InputText
									className='p-inputtext-sm max-h-[80%] m-auto max-w-[40%]'
									type='number'
									id='collaboratorsInput'
									name='collaboratorsInput'
									value={formik.values.collaboratorsInput}
									onChange={(e) => {
										formik.setFieldValue('collaboratorsInput', e.target.value);
									}}
								/>

								<span style={{ align: 'right', margin: 'auto' }}>
									<Button
										style={{
											maxWidth: '20%',
											maxHeight: '70%',
											marginRight: '0',
											float: 'right',
										}}
										label='Calcular'
										type='submit'
									/>
									<br />
								</span>
								<br />
								{getFormErrorMessage('collaboratorsInput')}
							</form>
						</Card>
						<div className='font-font-family-base text-sm flex justify-center'>
							<Card
								style={{
									backgroundColor: '#def4f7',
									textAlign: 'center',
									width: '100vh',
								}}
								footer={footerCard2}>
								<p style={{ margin: 'auto' }}>
									{getTranslation(t, SampleNames.SAMPLE_COMPANY)}
								</p>
								<br />
								<br />
								<p style={{ fontSize: '20px' }}>
									<b>{result}</b>
								</p>{' '}
								<br />
							</Card>
						</div>
					</div>
				</div>
				<p>{getTranslation(t, SampleNames.SELECTION_TEXT)}</p>
				<p>{getTranslation(t, SampleNames.SAMPLE_DIST)}</p>
			</>
		);
	};

	const mainHeader = () => {
		return (
			<>
				<div className='w-full h-20 font-font-family-base text-sm p-2'>
					<div className=' flex justify-center'>
						<p className='font-semibold flex uppercase'>
							{getTranslation(t, tittle.title)}
						</p>
					</div>
					<div className=' flex justify-center'>
						<p>{getTranslation(t, SampleNames.SAMPLE_TITLE)}</p>
					</div>
				</div>
				<hr />
			</>
		);
	};
	return (
		<>
			<section>
				<WorkArea
					grid={
						<div>
							{mainHeader()}
							{renderEquation()}
							{renderInfo()}
							{renderCalc()}
						</div>
					}
				/>
			</section>
		</>
	);
};
