import React from 'react';

const Guide4 = () => {
  return (
<div style={{width:"100%"}}>
<iframe
        src={window.EVALUA_GUIAIV_URL}
        title="Guía IV Política de Prevención"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
</div>
  );
};

 

export default Guide4;
