import './index.css';

import {
	QuestionaryCollaboratorsNames,
	SurveyNames,
	ToolBarNames,
	GenericsNames,
} from '../../translations';
import { TabPanel, TabView } from 'primereact/tabview';
import { ToExcel, ToPdf, EntityToExport } from '../../utils/exportService';
import {
	faFileExcel,
	faFilePdf,
	faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { Fieldset } from 'primereact/fieldset';
import { Column } from 'primereact/column';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FullLoading } from '../../Components/FullLoading';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { getResultTable } from '../../utils/htmlResultSurveyTable';
import { LoadingContext } from '../../context/LoadingContext';
import { useParams } from 'react-router-dom';
import useSurveyCollaboratorResult from '../../hooks/surveys/useSurveyCollaboratorResult';
import {IsNullOrEmpty } from '../../utils/utilsService';
import { StoreContext } from '../../business/Provider';
import { ERROR_SHOW } from '../../business/constants';
import { Toast } from '../../Components/toast';
import { HeaderSurvey } from './Components/HeaderSurvey';
import { getResultObjectHtml } from './utils';
import { ResultSurvey } from './Components/ResutlSurvey';


const CollaboratorQuestionaryComponent = () => {
	const { guideName, idQuestionary, idSurveyCollaborator } = useParams();
	const { t } = useCustomTranslation();
	const {} = useSurveyCollaboratorResult(idQuestionary, idSurveyCollaborator);
	const loadingObj = useContext(LoadingContext);
	const { resultState, dispatchResult } = useContext(StoreContext);
	const [survResult, setSurvResult] = useState(null);
	const [guideNum, setGuideNum] = useState(null);

	useEffect(() => {
		if (
			idQuestionary &&
			idSurveyCollaborator &&
			resultState.results[idQuestionary + '-' + idSurveyCollaborator]
		) {
			setSurvResult(
				resultState.results[idQuestionary + '-' + idSurveyCollaborator]
			);
		}
		if (!IsNullOrEmpty(guideName)) {
			setGuideNum(guideName.replace('guide-', ''));
		}
	}, [resultState.results, guideName, idQuestionary, idSurveyCollaborator]);

	useEffect(() => {
		if (resultState.errorShow === false && resultState.error) {
			dispatchResult({ type: ERROR_SHOW, payload: resultState.error });
		}
	}, [resultState?.error]);

	const exportToExcel = async () => {
		//	console.log('exportToExcel ', survResult);
		if (
			survResult?.questionary !== undefined &&
			survResult?.questionary !== null &&
			survResult?.questionary.length > 0
		) {
			try {
				let type;
				switch (guideNum) {
					case "1":
						type = EntityToExport.RESULT_BY_COLLABORATOR_G1;
						break;
					case "5":
						type = EntityToExport.RESULT_BY_COLLABORATOR_G5;
						break;
					case "6":
					case "7":
						type = EntityToExport.COLLABORATOR_SURVEYS_RESULT_LV;
						break;
					default:
						type = EntityToExport.RESULT_BY_COLLABORATOR;
						break;
				}
				loadingObj.setLoading(true);
				await ToExcel(
					{
						surveyId: idQuestionary,
						surveyCollaboratorId: idSurveyCollaborator,
						guideId: guideNum,
					},
					type
				);
				loadingObj.setLoading(false);
			} catch (error) {
				loadingObj.setLoading(false);
				console.log(error);
			}
		} else {
			Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
		}
	};

	const exportToPdf = async () => {
		if (
			survResult?.questionary !== undefined &&
			survResult?.questionary !== null &&
			survResult?.questionary.length > 0
		) {
			try {
				let type;
				switch (guideNum) {
					case "1":
						type = EntityToExport.RESULT_BY_COLLABORATOR_G1;
						break;
					case "5":
						type = EntityToExport.RESULT_BY_COLLABORATOR_G5;
						break;
					case "6":
					case "7":
						type = EntityToExport.COLLABORATOR_SURVEYS_RESULT_LV;
						break;
					default:
						type = EntityToExport.RESULT_BY_COLLABORATOR;
						break;
				}
				loadingObj.setLoading(true);
				await ToPdf(
					{
						surveyId: idQuestionary,
						surveyCollaboratorId: idSurveyCollaborator,
						guideId: guideNum,
					},
					type
				);
				loadingObj.setLoading(false);
			} catch (error) {
				loadingObj.setLoading(false);
				console.log(error);
			}
		} else {
			Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
		}
	};

	const headerTemplate = (rowData) => {
		return (
			<h5 style={{ fontWeight: 'bold' }}>{rowData.SequenceWithSection}</h5>
		);
	};
	const renderDivTableComponent = (e) => {
		return <div dangerouslySetInnerHTML={{ __html: e['QuestionHtml'] }} />;
	};

	const renderTableGuideOne = () => {
		return (
			<div className='flex absolute top-0 right-0 left-0 bottom-0 overflow-auto  '>
				<EvaluaTable
					value={survResult?.questionary}
					style={{ width: '100%', height: '100%' }}
					emptyMessage={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_EMPTY
					)}
					currentPageReportTemplate={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE
					)}
					rowGroupHeaderTemplate={headerTemplate}
					groupRowsBy='SequenceWithSection'
					rowGroupMode='subheader'
					globalFilterFields={[
						'SequenceWithSection',
						'QuestionHtml',
						'Always',
						'AlwaysIsh',
						'Sometimes',
						'NeverIsh',
						'Never',
						'Yes',
						'No',
					]}
					resizableColumns
					scrollable>
					<Column
						field='QuestionHtml'
						header={getTranslation(
							t,
							QuestionaryCollaboratorsNames.QUESTION_NAME
						)}
						body={(e) => renderDivTableComponent(e)}
						style={{ flexGrow: 1, width: '50%' }}
					/>
					<Column
						field='Yes'
						header={getTranslation(t, QuestionaryCollaboratorsNames.YES)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
					<Column
						field='No'
						header={getTranslation(t, QuestionaryCollaboratorsNames.NO)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
				</EvaluaTable>
			</div>
		);
	};

	const renderTableGuideTwoOrMore = () => {
		return (
			<div className='flex absolute top-0 right-0 left-0 bottom-0 overflow-auto  '>
				<EvaluaTable
					value={survResult?.questionary}
					style={{ width: '100%', height: '100%' }}
					emptyMessage={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_EMPTY
					)}
					currentPageReportTemplate={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE
					)}
					rowGroupHeaderTemplate={headerTemplate}
					groupRowsBy='SequenceWithSection'
					rowGroupMode='subheader'
					globalFilterFields={[
						'SequenceWithSection',
						'QuestionHtml',
						'Always',
						'AlwaysIsh',
						'Sometimes',
						'NeverIsh',
						'Never',
						'Yes',
						'No',
					]}
					resizableColumns
					scrollable>
					<Column
						field='QuestionHtml'
						header={getTranslation(
							t,
							QuestionaryCollaboratorsNames.QUESTION_NAME
						)}
						body={(e) => renderDivTableComponent(e)}
						style={{ flexGrow: 1, width: '50%' }}
					/>
					<Column
						field='Always'
						header={getTranslation(t, QuestionaryCollaboratorsNames.ALWAYS)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
					<Column
						field='AlwaysIsh'
						header={getTranslation(
							t,
							QuestionaryCollaboratorsNames.ALMOST_ALWAYS
						)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
					<Column
						field='Sometimes'
						header={getTranslation(t, QuestionaryCollaboratorsNames.SOMETIMES)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
					<Column
						field='NeverIsh'
						header={getTranslation(
							t,
							QuestionaryCollaboratorsNames.ALMOST_NEVER
						)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
					<Column
						field='Never'
						header={getTranslation(t, QuestionaryCollaboratorsNames.NEVER)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
				</EvaluaTable>
			</div>
		);
	};
	const renderTableGuideFiveToSeven = () => {
		return (
			<div className='flex absolute top-0 right-0 left-0 bottom-0 overflow-auto  '>
				<EvaluaTable
					value={survResult?.questionary}
					style={{ width: '100%', height: '100%' }}
					emptyMessage={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_EMPTY
					)}
					currentPageReportTemplate={getTranslation(
						t,
						QuestionaryCollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE
					)}
					rowGroupHeaderTemplate={headerTemplate}
					groupRowsBy='SequenceWithSection'
					rowGroupMode='subheader'
					globalFilterFields={[
						'QuestionHtml',
						'AnswerText',
					]}
					resizableColumns
					scrollable>
					<Column
						field='QuestionHtml'
						header={getTranslation(
							t,
							QuestionaryCollaboratorsNames.QUESTION_NAME
						)}
						body={(e) => renderDivTableComponent(e)}
						style={{ flexGrow: 1, width: '50%' }}
					/>
					<Column
						field='AnswerText'
						header={getTranslation(t, SurveyNames.RESPONSE)}
						style={{ flexGrow: 1, width: '10%' }}
					/>
				</EvaluaTable>
			</div>
		);
	};
	const renderHeaderSurvey = () => {
		return (
			<HeaderSurvey
				exportToExcel={exportToExcel}
				exportToPdf={exportToPdf}
				survResult={survResult}
			/>
		);
	};

	const renderResult = () => {
		return <ResultSurvey survResult={survResult} />;
	};

	const renderResultTable = (output) => {
		return (
			<div
				className=' overflow-y-auto absolute top-0 bottom-0 w-full p-1'
				dangerouslySetInnerHTML={{
					__html: getResultObjectHtml(output, getTranslation, t, survResult),
				}}></div>	
		);
	};

	return (
		<div className=' relative w-full h-full flex flex-col p-1 '>
			{survResult && (
				<>
					<div className='flex w-full h-52 border '>{renderHeaderSurvey()}</div>
					{guideNum > 1  ? (<>
						{guideNum === "5" || guideNum=== "6" || guideNum === "7" ? (
						<TabView className='flex-grow flex flex-col'>
						<TabPanel
								contentClassName='h-full relative'
								header={getTranslation(t, SurveyNames.QUESTIONARY_TITLE)}>
								{renderTableGuideFiveToSeven()}
							</TabPanel>
						</TabView>) : (<TabView className='flex-grow flex flex-col'>
							<TabPanel
								className=''
								header={getTranslation(t, SurveyNames.RESULT)}>
								{renderResult()}
							</TabPanel>
							<TabPanel
								contentClassName='h-full relative'
								header={getTranslation(t, SurveyNames.SUMMARY)}>
								{renderResultTable('html')}
							</TabPanel>
							<TabPanel
								contentClassName='h-full relative'
								header={getTranslation(t, SurveyNames.QUESTIONARY_TITLE)}>
								{renderTableGuideTwoOrMore()}
							</TabPanel>
						</TabView>)}
						
						</>
					) : (
						<div className='flex flex-grow w-full flex-col '>
							<div className='flex w-full h-14 border shadow-2xl flex-row content-center items-center'>
								<h1 className='font-semibold ml-2'>
									{getTranslation(t, SurveyNames.RECOMMENDATION)}
								</h1>
								<h1 className='ml-1'>
									{survResult?.general?.ScoreDescription ||
										getTranslation(t, SurveyNames.NOT_RECOMMENDATION)}
								</h1>
							</div>
							<div className='flex w-full flex-grow'>
								<div className='flex-grow flex flex-col relative '>
									{renderTableGuideOne()}
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{resultState.loading && (
				<div className='flex w-full h-full relative'>
					<FullLoading show={resultState.loading} />
				</div>
			)}
		</div>
	);
};
CollaboratorQuestionaryComponent.propTypes = {
	guideName: PropTypes.string.isRequired,
	idQuestionary: PropTypes.string.isRequired,
	idSurveyCollaborator: PropTypes.string.isRequired,
};

export default CollaboratorQuestionaryComponent;
