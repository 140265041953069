import React, { Fragment, useContext, useEffect, useState } from "react";
import {getTranslation, useCustomTranslation,} from "./../../hooks/useTranslations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight,} from "@fortawesome/free-solid-svg-icons";
import { NavigationContext } from "../../context/navigationContext";
import { ButtonDropDown } from "../Buttons/ButtonDropdown";
export const SubSidebarItem = ({ keyData, item, close }) => {
  const [showChildrens, setShowChildrens] = useState(false);
  const { t } = useCustomTranslation();
  const navigation = useContext(NavigationContext);
  const setIcon = showChildrens ? faChevronDown : faChevronRight;
  const defaultButtonStyle =
    "btn btn-link btn-sm hover:!border-none hover:!cursor-default !text-white";
  const SelectContentStyle = `w-full text-white`;
  return (
    <>
      <li>
        <ButtonDropDown
          tooltip={item?.children !== undefined ? getTranslation(t, item.title) : ""}
          key={keyData}
          onClick={() => {
            item?.children !== undefined
              ? setShowChildrens(!showChildrens)
              : navigation.selectTap(item.url, "", item.title);
            close();
          }}
          className={` h-10 w-full ${
            item?.children !== undefined
              ? defaultButtonStyle
              : " !border-none hover:!bg-menu-hover-bg-default"
          } `}
        >
          <div
            className={`flex h-full  content-center items-center ${
              item?.children !== undefined
                ? "w-5/6 font-bold"
                : SelectContentStyle
            }`}
          >
            <p className="truncate font-h3-font-family text-code-font-size ">
              {getTranslation(t, item.title)}
            </p>
          </div>
        </ButtonDropDown>
      </li>
      <li className="flex w-full flex-col pl-2">
        {item?.children?.map((itemChildren, keyChildren) => {
          return itemChildren.title ? (
            <Fragment key={keyChildren}>
              <ButtonDropDown
                onClick={() => {
                  navigation.selectTap(
                    itemChildren.url,
                    item.tapTitle,
                    itemChildren.title
                  );
                  close();
                }}
                key={keyChildren}
                className={`h-8 !p-0 !border-none hover:!bg-menu-hover-bg-default !bg-menu-bg-default !text-white `}
              >
                <div
                  className={`h-full w-full my-auto flex items-center content-center align-middle`}
                >
                  <p className="font-h3-font-family text-code-font-size font-normal pl-4">
                    {getTranslation(t, itemChildren.title)}
                  </p>
                </div>
              </ButtonDropDown>
            </Fragment>
          ) : null;
        })}
      </li>
    </>
  );
};
