import { IsNullOrEmpty } from "./utilsService";
const borderTitle = 'border:none; background:#FFFFFF;'

const bodyClass = 'mso-style-parent:style0;	border:.5pt solid #D9D9D9;-webkit-print-color-adjust: exact !important;';


const getHeadersSection = (backgroundColor, columns) => {
    const headerClass = `padding-top: 5px;padding-bottom: 5px;padding-left: 5px;padding-right: 5px;mso-style-parent:style0;font-weight:700;border:.5pt solid #D9D9D9;	background:${backgroundColor};	mso-pattern:black none;  -webkit-print-color-adjust: exact !important;`

    //const titleClass=`mso-style-parent:style0;font-size:14.0pt;font-weight:700;text-align:center;${backgroundColor}`
    let header = '';//`<th width='10px' style=${titleClass}> </th>`; // Add empty Columns
    columns.forEach(function (column) {
        header = header + `<th style='${headerClass}'>${column.title}</th>`
    });
    //header = header + `<th width='10px'style=${titleClass}> </th>`; // Add empty Columns
    return `<tr>${header}</tr>`;
}
const borderColor = 'black'
const getCell = (row, column, output) => {
    var rowSpanAttribute = column.rowSpanProperty ? `rowSpan=${row[column.rowSpanProperty]}` : '';
    var cellValue = IsNullOrEmpty(row[column.dataKey]) ? '' : row[column.dataKey];
    cellValue = column.toFix ? `${cellValue.toFixed(column.toFix)}` : cellValue;
    cellValue = output === 'xls' && column.toFix ? "'" + cellValue : cellValue;
    cellValue = output === 'xls' && cellValue instanceof String ? cellValue.replace('</br>', '\\n') : cellValue;
    var textAlign = column.textAlign ? `text-align:${column.textAlign};` : '';
    var color = column.textColor ? `color:${row[column.textColor]};` : '';
    var background = column.colorProperty ? `background:${row[column.colorProperty]};` : '';
    return `<td ${rowSpanAttribute} style='border: 1 solid ${borderColor};padding-top: 5px;padding-bottom: 5px;padding-left: 5px;padding-right: 5px;vertical-align: middle; ${textAlign} ${color} ${background}'>${cellValue}</td>\n`
}

const getTdsFromObject = (objModel, resultObj) => {
    let cells = '';
    let objectProperties = Object.keys(objModel);
    resultObj.columns.forEach(function (column) {
        let prop = objectProperties.filter(c => c === column.dataKey);
        if (prop.length > 0) {
            cells = cells + getCell(objModel, column, resultObj.output)
        }
    });
    return cells;
}
const getTableBody_original = (resultObj) => {
    let content = '';
    let dimensionsDictionaryArray = [];
    let domainsDictionaryArray = [];
    let categoryDictionaryArray = [];

    resultObj.rows.forEach(function (category) {
        let categoryDictionary = { key: '', array: [] };
        //First ForEach For Category
        //let rowSpanForCategory = 0;
        category.domains.forEach(function (domain) {
            //Populate dimensions for table
            let dimensionDictionary = { key: '', domainKey: '', array: [] };
            let domainDictionary = { key: '', categoryKey: '', array: [] };
            domain.dimensions.forEach(function (dimension) {
                //Step 1 populate dimensions
                dimensionDictionary.key = dimension.dimensionId
                dimensionDictionary.domainKey = domain.domainId
                dimensionDictionary.array.push(getTdsFromObject(dimension, resultObj));
            });
            dimensionsDictionaryArray.push(dimensionDictionary)
            //Add Span counter for domain             
            //let rowSpanForDomain = domain.dimensions.length;
            //Step 2 pupulate domain             
            domainDictionary.key = domain.domainId;
            domainDictionary.categoryKey = category.categoryId;
            domainDictionary.array.push(getTdsFromObject(domain, resultObj));
            domainsDictionaryArray.push(domainDictionary);
            //add counter for the span for category based all the dimensions
            //rowSpanForCategory = rowSpanForCategory + rowSpanForDomain;
        });
        //step 3 populate category.
        // content = content + `<tr style='${borderTitle}'>`+
        categoryDictionary.key = category.categoryId
        categoryDictionary.array.push(getTdsFromObject(category, resultObj));
        categoryDictionaryArray.push(categoryDictionary);
    });

    //console.log("Dimensions", dimensionsDictionaryArray)
    //console.log("Domains", domainsDictionaryArray)
    //console.log("Categories", categoryDictionaryArray)
    content = '';

    categoryDictionaryArray.forEach(function (categoryDictionary) {
        let domainsDictionaries = domainsDictionaryArray.filter((domain) => domain.categoryKey === categoryDictionary.key)
        let firstCategoryRow = true;
        domainsDictionaries.forEach(function (domainDictionary) {
            let dimensionsDictionaries = dimensionsDictionaryArray.filter((dimension) => dimension.domainKey === domainDictionary.key)
            domainDictionary.array.forEach(function (domain) {
                let firstDomainRow = true;
                dimensionsDictionaries.forEach(function (dimensionDictionary) {
                    dimensionDictionary.array.forEach(function (dimension) {
                        if (firstCategoryRow && firstDomainRow) {
                            content = content + '<tr>' + categoryDictionary.array[0] + domain + dimension + '</tr>\n'
                        } else {
                            if (firstDomainRow) {
                                content = content + '<tr>' + domain + dimension + '</tr>\n'
                            } else {
                                content = content + '<tr>' + dimension + '</tr>\n'
                            }
                        }
                        firstDomainRow = false;
                        firstCategoryRow = false;
                    });
                });

            });

        });
    });
    return content;
}

const getTableBody = (resultObj) => {
    let content = ''
    let lastCategory = ''
    let lastDomain = ''

    resultObj.rows.forEach((item) => {
        let row = '<tr>'
        if (lastCategory !== item.Category) {
            row += `<td rowspan='${item.CategoryRowSpan}' style="padding: 5px;border: 1px solid ${borderColor};background-color:${item.CategoryScoreColor};color:${item.CategoryTextColor}">${item.Category}</td>`
            row += `<td rowspan='${item.CategoryRowSpan}' style="padding: 5px;border: 1px solid ${borderColor};background-color:${item.CategoryScoreColor};color:${item.CategoryTextColor};text-align: center;">${item.CategoryScoreDescription}</td>`
            lastCategory = item.Category
        }
        if (lastDomain !== item.Domain) {
            row += `<td rowspan='${item.DomainRowSpan}' style="padding: 5px;border: 1px solid ${borderColor};background-color:${item.DomainScoreColor};color:${item.DomainTextColor}">${item.Domain}</td>`
            row += `<td rowspan='${item.DomainRowSpan}' style="padding: 5px;border: 1px solid ${borderColor};background-color:${item.DomainScoreColor};color:${item.DomainTextColor};text-align: center;">${item.DomainScoreDescription}</td>`
            lastDomain = item.Domain
        }
        row += `<td style="padding: 5px;border: 1px solid ${borderColor};">${item.Dimension}</td><td style="padding: 5px;border: 1px solid ${borderColor};text-align: center;">${item?.Total !== undefined ? item?.Total.toFixed(4): ""}</td><td style="padding: 5px;border: 1px solid ${borderColor};">${item.Questions}</td><td style="padding: 5px;border: 1px solid ${borderColor};">${item.Items}</td>`
        row += `<td style="padding: 5px;border: 1px solid ${borderColor};background-color:${item.FactorColor};color:${item.FactorTextColor};text-align: center;">${item.Factor !== undefined ? item.Factor.toFixed(4):""}</td>`

        row += '</tr>'

        content += row;
    })
    return content;
}

export const getResultTable = (resultObj) => {
    let headerSection = getHeadersSection(resultObj.headerBackgroundColor, resultObj.columns);
    let content = getTableBody(resultObj)
    return `<table style="width:100% ${borderTitle}" border=0 cellpadding=0 cellspacing=0 >${headerSection}${content}</table>`;
}