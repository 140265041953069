import './styles.css';

import { btn, btnDisable } from '../../assets/customStyles';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

// comments about props
// onClick: function
// text: string (text to display)
// icon: html element (icon to display) ex: <FontAwesomeIcon icon={faPlus} />
// type: string (primary, secondary, success, info, warning, danger)
// disabled: boolean
export const CustomIconButton = ({
	children,
	onClick,
	icon = null,
	className,
	toolTip,
	disabled = false,
	text = null,
}) => {
	// switch the type of the button

	return (
		<Button
			title={toolTip}
			disabled={disabled}
			onClick={onClick}
			className={`!pointer-events-auto !border-0 ${btnDisable} !bg-[#75b6fc] !text-white !rounded-none flex justify-center  ${className}`}
			tooltipOptions={{
				position: 'bottom',
				className: '!text-small-font-size',
			}}>
			{icon ? <FontAwesomeIcon icon={icon} title={toolTip} /> : children}
			{text === null ? null : <span className='!ml-1'>{text}</span>}
		</Button>
	);
};

// CustomIconButton.propTypes = {
//     onClick: PropTypes.func,
//     icon: PropTypes.element,
//     disabled: PropTypes.bool,
//     className: PropTypes.string,
//     toolTip: PropTypes.string
// }
