import React, { useState } from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import { SurveysQuestionsNames } from './../../../translations/index';

export const ContentHeaderComponent = ({ collaboratorData }) => {
	const pClass = 'pl-1 font-bold  truncate';
	const { t } = useCustomTranslation();

	const [day] = useState(new Date().getDate());
	const [month] = useState(new Date().getMonth() + 1);
	const [year] = useState(new Date().getFullYear());
	const surNameNull = collaboratorData?.surName ?? ' ';

	const renderWebHeader = () => {
		return (
			<div className=' w-full h-[80px] flex-row  hidden  md:flex lg:flex xl:flex 2xl:flex text-sm '>
				<div className='flex h-full w-1/3 flex-col p-2'>
					<div className='flex flex-row'>
						<div className='flex h-full w-1/4'>
							<p>{getTranslation(t, SurveysQuestionsNames.SurveyPersonal)} :</p>
						</div>
						<div className='flex h-full w-3/4'>
							<p className={pClass}>
								{collaboratorData?.name +
									' ' +
									collaboratorData?.lastName +
									' ' +
									surNameNull}
							</p>
						</div>
					</div>
					<div className='flex flex-row'>
						<div className='flex h-full w-1/4'>
							<p>
								{getTranslation(t, SurveysQuestionsNames.SurveyDepartment)} :
							</p>
						</div>
						<div
							className='flex h-full w-3/4 '
							title={collaboratorData?.departmentName}>
							<p className={pClass}>{collaboratorData?.departmentName}</p>
						</div>
					</div>
					<div className='flex flex-row'>
						<div className='flex h-full w-1/4'>
							<p>{getTranslation(t, SurveysQuestionsNames.SurveyPosition)} :</p>
						</div>
						<div className='flex h-full w-3/4 '>
							<p className={pClass}>{collaboratorData?.positionName}</p>
						</div>
					</div>
				</div>
				<div className='flex h-full w-1/3 justify-center'>
					<p className='uppercase font-bold'>{collaboratorData?.surveyName}</p>
				</div>
				<div className='flex h-full w-1/3 justify-end'>
					{/* <p className="font-bold">
            {day}/{month < 10 ? '0' + month : month}/{year}
          </p> */}
				</div>
			</div>
		);
	};

	const renderMobileHeader = () => {
		return (
			<div className=' w-full h-[80px] flex-col bg-white flex  md:hidden lg:hidden xl:hidden 2xl:hidden text-xs shadow-2xl'>
				<div className='flex flex-row h-1/3 w-full'>
					<div className='flex h-full w-1/2 justify-center content-center items-center'>
						<p className='uppercase font-bold '>
							{collaboratorData?.surveyName}
						</p>
					</div>
					<div className='flex h-full w-1/2 justify-center content-center items-center'>
						<p className='font-bold '>
							{day}/{month < 10 ? '0' + month : month}/{year}
						</p>
					</div>
				</div>
				<div className='flex w-full h-2/3 flex-row'>
					<div className='flex h-full w-1/3 flex-col justify-center content-center items-center'>
						<div className='flex w-full h-1/2'>
							<p className='pl-1'>
								{getTranslation(t, SurveysQuestionsNames.SurveyPersonal)} :
							</p>
						</div>
						<div className='flex w-full h-1/2'>
							<p className={pClass}>
								{collaboratorData?.name +
									' ' +
									collaboratorData?.lastName +
									' ' +
									surNameNull}
							</p>
						</div>
					</div>
					<div className='flex h-full w-1/3 flex-col justify-center content-center items-center'>
						<div className='flex w-full h-1/2'>
							<p className='pl-1'>
								{getTranslation(t, SurveysQuestionsNames.SurveyDepartment)} :
							</p>
						</div>
						<div className='flex w-full h-1/2'>
							<p className={pClass}>{collaboratorData?.departmentName}</p>
						</div>
					</div>
					<div className='flex h-full w-1/3 flex-col justify-center content-center items-center'>
						<div className='flex w-full h-1/2'>
							<p className='pl-1'>
								{getTranslation(t, SurveysQuestionsNames.SurveyPosition)} :
							</p>
						</div>
						<div className='flex w-full h-1/2'>
							<p className={pClass}>{collaboratorData?.positionName}</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{renderWebHeader()}
			{renderMobileHeader()}
			{}
		</>
	);
};
