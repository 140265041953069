import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/img/LogotipoLanding.png'

// error page
export const ErrorPage = () => {
    // get state from url using hooks
    const [details, setDetails] = useState(false);
    const { state } = useLocation();
    // usecallback to show details
    
    const onClick = () => setDetails(!details);

    return (
    <div className='bg-white flex flex-col justify-center items-center w-screen h-screen'>
        <img width="128" className='w-72' src={logo} alt="logo" />
        <article className='text-center pt-4 '>
            <p className='text-xl p-error'>Ocurrió un error inesperado por favor intente más tarde</p>
        </article>
        <button className='text-primary' onClick={() => onClick()}>
            ver mas
        </button>
        {details && (
            <div className='text-center'>
                <p className='text-sm '>codigo: {state.code}</p>
                <p className='text-sm p-error'>{state.error}</p>
            </div>
        )}
    </div>
    );
};