import React, { useRef, useEffect, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';

import { Checkbox } from 'primereact/checkbox';

import { Dropdown } from 'primereact/dropdown';
import { GridDialogTextField } from '../../TextField';
import { isCousin } from '../utils';
import { RadioButtonQuestion } from './RadioButtonQuestion';
export const SurveyItem = ({
	item,
	index,
	sectionId,
	indexSection,
	validateForm,
	sendData,
	setValueRadio,
	setValueCheck,
	isSelect,
	questionaryId,
	collaboratorData,
	textProgressBar,
	setProgressQuestions, 
	progressQuestions
}) => {
	const html =
		item.questionNumber !== ''
			? `${item.questionNumber}.- ${item.questionHtml}`
			: item.questionHtml;

	const bg =
		validateForm === true && sendData === true && item.questionValue === ''
			? '  font-semibold '
			: '';

	const selection_itemRef = useRef(null);

	useEffect(() => {
		if (isSelect === item.questionId) {
			selection_itemRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	}, [isSelect]);

	const [valueText, setValueText] = useState(null);

	useEffect(() => {
		if (valueText == null) {
			if (item.questionId == 142 && collaboratorData.positionName) {
				item.questionValue = collaboratorData.positionName;
				setValueText(collaboratorData.positionName);
				setProgressQuestions(progressQuestions + 1);
				
			}
			if (item.questionId == 143 && collaboratorData.departmentName) {
				item.questionValue = collaboratorData.departmentName;
				setValueText(collaboratorData.departmentName);
				setProgressQuestions(progressQuestions + 1);

			}
		}
	}, [collaboratorData]);

	const [questionValue, setQuestionValue] = useState(null);

	const handleInputTextChange = (value) => {
		item.questionValue = value;
		setValueText(value);
		textProgressBar(item.questionId, value);
	};

	const handleDropDownChange = (value) => {
		item.questionValue = value;
		setQuestionValue(value);
		setProgressQuestions(progressQuestions + 1);

	};

	const renderRanges = () => {
		return 'ranges';
	};

	const renderSelect = (itemSelect) => {
		return (
			<Dropdown
				value={questionValue}
				optionValue='htmlText'
				onChange={(e) => handleDropDownChange(e.value)}
				options={itemSelect.responses}
				optionLabel='htmlText'
				placeholder='Selecciona una opcion'
				className={'flex w-1/2'}
			/>
		);
	};

	const renderGridInput = () => {
		return (
			<GridDialogTextField
				className={'flex w-full justify-center'}
				classNameInput={'flex w-1/2'}
				onChange={(e) => handleInputTextChange(e.target.value)}
				maxLength={250}
				value={valueText == null ? '' : valueText}
			/>
		);
	};

	const isDaySelected = (response, day) => {
		const validationDaySelect = response.questionValue.includes(day);
		return validationDaySelect;
	};
	const renderCheckButton = (
		text,
		indexRadio,
		itemRadio,
		response,
		sectionIdRadio,
		indexSectionRadio,
		bgRadio,
		w = 'w-1/5'
	) => {
		const renderValidation =
			item.questionTypeId === 7 ? (
				<label
					htmlFor={response.optionValueId}
					className={'font-bold ' + bgRadio}>
					{text}
				</label>
			) : null;
		return (
			<div
				key={indexRadio}
				className={
					'flex h-full flex-col content-center items-center justify-center text-center px-0.5 ' +
					w
				}>
				<div className='flex flex-col  md:flex-col lg:flex-col  xl:flex-col  2xl:flex-col  content-center items-center justify-center '>
					{questionaryId > 1 ? renderValidation : null}
					<label
						htmlFor={response.optionValueId}
						className={
							'flex text-xxs mr-1 font-semibold s:text-xxs  md:hidden lg:hidden xl:hidden 2xl:hidden ' +
							bgRadio
						}>
						{text}
					</label>
					<Checkbox
						className={'bg-red-500'}
						style={{ backGroundColor: 'red' }}
						id={response.optionValueId}
						value={response.optionValueId}
						name={item.questionNumber}
						onChange={() =>
							setValueCheck(response, sectionIdRadio, indexSectionRadio)
						}
						checked={isDaySelected(item, response.htmlText)}
					/>
				</div>
			</div>
		);
	};
	const renderRadioButton = (
		text,
		indexRadio,
		itemRadio,
		response,
		sectionIdRadio,
		indexSectionRadio,
		bgRadio,
		w = 'w-1/5'
	) => {
		return (
			<RadioButtonQuestion
				text={text}
				itemRadio={itemRadio}
				indexRadio={indexRadio}
				response={response}
				sectionIdRadio={sectionIdRadio}
				indexSectionRadio={indexSectionRadio}
				bgRadio={bgRadio}
				w={w}
				item={item}
				questionaryId={questionaryId}
				setValueRadio={setValueRadio}
				collaboratorData={collaboratorData}
			/>
		);
	};

	const renderTypeElement = (item, sectionId, indexSection, bg) => {
		switch (item.questionTypeId) {
			case 3:
				return renderRanges();
			case 5:
				return renderSelect(item);
			case 6:
				return renderGridInput(item);
			case 7:
				return item.responses.map((response, indexResponse) => {
					return renderCheckButton(
						response.htmlText,
						indexResponse,
						item,
						response,
						sectionId,
						indexSection,
						bg,
						'w-1/3'
					);
				});
			default:
				return item.responses.map((response, indexResponse) => {
					return renderRadioButton(
						response.htmlText,
						indexResponse,
						item,
						response,
						sectionId,
						indexSection,
						bg,
						'w-1/3'
					);
				});
		}
	};

	const value = index + 1;
	const initialBg = isCousin(value) ? ' ' : ' bg-gray-100 ';
	const validationFormStyle =
		validateForm === true && sendData === true && item.questionValue === ''
			? ' !text-red  !border-2 !border-b-red '
			: ' ';
	return (
		<div
			key={index}
			className={
				'flex flex-col  md:flex-row  lg:flex-row  xl:flex-row 2xl:flex-row border  w-full content-center justify-center items-center border-b-2   md:py-2 lg:py-2 xl:py-2 2xl:py-2    ' +
				validationFormStyle +
				initialBg
			}
			ref={selection_itemRef}>
			<div
				className={
					'flex w-full text-xs  md:w-[55%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%]  flex-col justify-start md:justify-center  lg:justify-center  xl:justify-center  2xl:justify-center  content-center text-xxs  md:text-sm lg:text-sm xl:text-base 2xl:text-base  ' +
					'font-bold'
				}
				dangerouslySetInnerHTML={{
					__html: html,
				}}></div>
			<div
				className={
					'flex w-full md:w-[45%] lg:w-[45%] xl:w-[45%] 2xl:w-[45%] h-full  flex-row  rounded-lg justify-center'
				}>
				{renderTypeElement(item, sectionId, indexSection, bg)}
			</div>
		</div>
	);
};
