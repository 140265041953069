import React from 'react';
import { SettingsPanelNames, ToolBarNames } from '../../translations';
import {
	faChevronDown,
	faEye,
	faFileAlt,
	faPowerOff,
	faUserAlt,
	faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {
	getTranslation,
	useCustomTranslation,
} from './../../hooks/useTranslations';
import { useSelector } from 'react-redux';
import { ButtonDropDown } from '../Buttons/ButtonDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/pro-thin-svg-icons';
import { uris } from './../../Auth/b2cService/b2cServiceConfig';

export const ProfileCard = ({
	showPopover,
	setShowPopover,
	refCloseProfileCard,
	setShowDialog,
	closeFunction,
}) => {
	const { t } = useCustomTranslation();

	const user = useSelector(({ auth }) => auth.user);

	const closeFunctionDialog = () => {
		return closeFunction(true);
	};

	const userProfileRedirect = () => {
		window.open(uris.platformAccountUri, '_blank').focus();
		return null;
	};

	const userTermsRedirect = () => {
		window.open(uris.terms, '_blank').focus();
		return null;
	};
	const userPrivacyRedirect = () => {
		window.open(uris.privacy, '_blank').focus();
		return null;
	};
	const showInfoModal = () => {
		return setShowDialog(true);
	};

	const settingsMenu = [
		{
			id: 1,
			text: SettingsPanelNames.MY_PROFILE,
			icon: faUserAlt,
			action: userProfileRedirect,
		},
		{
			id: 3,
			text: ToolBarNames.TERMS,
			icon: faFileAlt,
			action: userTermsRedirect,
		},
		{
			id: 4,
			text: ToolBarNames.POLICY,
			icon: faEye,
			action: userPrivacyRedirect,
		},
	];

	return (
		<div ref={refCloseProfileCard} className=' flex flex-col w-full relative '>
			<div
				onClick={() => setShowPopover(!showPopover)}
				className='flex w-full h-full flex-row text-secondary-contrast-color-text  rounded-md hover:cursor-pointer'>
				<div className='flex h-full w-3/4 flex-col '>
					<div className='flex items-start content-center justify-center text-center w-full h-1/2 text-code-font-size'>
						<p className='text-center'>{user?.data?.displayName}</p>
					</div>
					<div className='flex justify-center items-start content-center font-light w-full h-1/2 text-small-font-size truncate'>
						<p className=''>{user?.data?.email}</p>
					</div>
				</div>
				<div className='flex h-full flex-row w-1/4 content-center items-center justify-center'>
					{
						<FontAwesomeIcon
							className='!text-black fa-2xl fa-2xs pr-1'
							icon={faCircleUser}
						/>
					}
					{
						<FontAwesomeIcon
							className='!text-black'
							size='xs'
							icon={faChevronDown}
						/>
					}
				</div>
			</div>
			{showPopover ? (
				<ul className='flex w-56 flex-col pt-1 absolute top-[4rem] divide-y right-1 rounded-md bg-dropdown-bg-default drop-shadow-2xl  '>
					{settingsMenu.map((item, index) => {
						return (
							<li key={index}>
								{' '}
								<ButtonDropDown
									key={index}
									className='h-12 w-full p-2 !font-h3-font-family'
									Icon={item.icon}
									title={getTranslation(t, item.text)}
									onClick={item.action}
								/>{' '}
							</li>
						);
					})}
					<li>
						<ButtonDropDown
							key={'off'}
							className={' h-12 border w-full border-t-8 border-gray-500'}
							Icon={faCircleInfo}
							title={getTranslation(t, ToolBarNames.VERSION)}
							onClick={showInfoModal}
						/>
					</li>
					<li>
						<ButtonDropDown
							key={'off'}
							className={
								'!text-red h-12 border w-full border-t-8 border-gray-500'
							}
							Icon={faPowerOff}
							title={getTranslation(t, ToolBarNames.LOGOUT)}
							onClick={closeFunctionDialog}
						/>
					</li>
				</ul>
			) : null}
		</div>
	);
};
