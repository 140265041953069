import {
	DepartmentsNames,
	GenericsNames,
	ZoneNames,
} from '../../../../translations';
import {
	EntityToExport,
	ToExcel,
	ToPdf,
} from '../../../../utils/exportService';
import React, { useContext, useState } from 'react';
import {
	faPencilAlt,
	faPlusCircle,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
	getTranslation,
	useCustomTranslation,
} from './../../../../hooks/useTranslations';

import { Column } from 'primereact/column';
import { CustomIconButton } from '../../../../Components/Buttons/CustomIconButton';
import DepartmentObject from '../../../../utils/Constanst/FormikConstanst/Department/DeparmentObject';
import DepartmentsSchema from '../../../../utils/Constanst/FormikConstanst/Department/DepartmentSchema';
import { EvaluaTable } from '../../../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import { StoreContext } from '../../../../business/Provider';
import { TableButton } from '../../../../Components/Buttons/TableButtons';
import ToolBarThreeColumns from '../../../../Components/Table/ToolBarThreeColumns';
import { useFormik } from 'formik';
import { Toast } from '../../../../Components/toast';
import { AddButtonComponentIcon } from '../../../../Components/AddButtonComponentIcon';

export const DepartmentGrid = ({
	onEdit,
	onDelete,
	loading,
	rows,
	handleOnAdd,
	selectionMode,
	selection,
	onSelectionChange,
	hasRelationWithPersonia,
}) => {
	const { t } = useCustomTranslation();
	const [sort, setSort] = useState([{ field: 'scode', order: 1 }]);
	const { dispatchLoading } = useContext(StoreContext);
	const [filters, setFilters] = useState({
		global: { value: '', matchMode: FilterMatchMode.CONTAINS },
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const formik = useFormik({
		initialValues: { ...DepartmentObject },
		validationSchema: DepartmentsSchema,
		//onSubmit: addOrUpdateDepartments
	});

	const handleExcelExport = async () => {
		const printObj = { ...filters, sort: sort[0] };
		//exportToExcel(printObj)
		rows.length > 0
			? await ToExcel(
					printObj,
					EntityToExport.CATALOG_DEPARTMENT,
					dispatchLoading
			  )
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	};
	const handleToPDF = async () => {
		const printObj = { ...filters, sort: sort[0] };
		rows.length > 0
			? await ToPdf(
					printObj,
					EntityToExport.CATALOG_DEPARTMENT,
					dispatchLoading
			  )
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<>
				<TableButton
					onClick={() => onEdit(rowData)}
					icon={faPencilAlt}
					toolTip={getTranslation(t, GenericsNames.EDIT)}
				/>
				<TableButton
					onClick={() => onDelete(rowData)}
					icon={faTrashAlt}
					toolTip={
						hasRelationWithPersonia
							? getTranslation(t, DepartmentsNames.PERSONIA_DELETE)
							: getTranslation(t, GenericsNames.DELETE)
					}
					disabled={hasRelationWithPersonia}
				/>
			</>
		);
	};

	const renderHeader = () => (
		<ToolBarThreeColumns
			setValue={setGlobalFilterValue}
			setFilters={setFilters}
			rightChildren={
				<AddButtonComponentIcon
					className='!m-1'
					toolTip={
						hasRelationWithPersonia
							? getTranslation(t, DepartmentsNames.PERSONIA_ADD)
							: getTranslation(t, GenericsNames.ADD)
					}
					onClick={() => {
						handleOnAdd();
					}}
					//icon={faPlusCircle}
					disabled={hasRelationWithPersonia}
				/>
			}
			value={globalFilterValue}
			onChange={onGlobalFilterChange}
			formik={formik}
			placeholder={getTranslation(t, GenericsNames.SEARCH)}
			onExcelClick={() => {
				handleExcelExport();
			}}
			onPDFClick={() => {
				handleToPDF();
			}}
			onPrintClick={() => {
				//const printObj={cols:exportColumns , rows:rows, fileName:getTranslation(t, DepartmentsNames.DIALOG_TITLE), title:getTranslation(t, DepartmentsNames.DIALOG_TITLE),subTitle:''};
				//print(printObj, EntityToExport.CATALOG_DEPARTMENT)
				//const printObj = { exportableColumns: exportColumns, rows: rows, title: getTranslation(t, DepartmentsNames.DIALOG_TITLE), subTitle: '' };
				//printGrid(printObj);
			}}></ToolBarThreeColumns>
	);
	return (
		<div className='datatable-doc-demo'>
			<div className='card'>
				<EvaluaTable
					setSort={setSort}
					sort={sort}
					value={rows}
					sortField='code'
					header={renderHeader()}
					filters={filters}
					doubleClick={onEdit}
					//filterDisplay='row' // input search
					responsiveLayout='scroll'
					globalFilterFields={['name', 'scode']}
					emptyMessage={getTranslation(t, DepartmentsNames.EMPTY_DEPARTMENT)}
					currentPageReportTemplate={getTranslation(
						t,
						DepartmentsNames.GRID_PAGE_REPORT_TEMPLATE
					)}
					// height={height}
					selectionMode={selectionMode}
					selection={selection}
					onSelectionChange={onSelectionChange}
					columnResizeMode='fit'
					loading={loading}>
					{selectionMode && (
						<Column
							selectionMode='multiple'
							//headerStyle={{ width: "5px" }}
							style={{ flexGrow: 1, flexBasis: '100px' }}></Column>
					)}
					<Column
						field='scode'
						header={getTranslation(t, GenericsNames.CODE)}
						sortable
						style={{ flexGrow: 1, flexBasis: '100px' }}
					/>
					<Column
						field='name'
						header={getTranslation(t, GenericsNames.NAME)}
						sortable
						style={{ flexGrow: 1, flexBasis: '100px' }}
					/>

					<Column
						header={getTranslation(t, ZoneNames.GRID_ACTION)}
						headerStyle={{ width: '4rem', textAlign: 'center' }}
						bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
						body={actionBodyTemplate}
						style={{ flexGrow: 1, flexBasis: '100px' }}
					/>
				</EvaluaTable>
			</div>
		</div>
	);
};
