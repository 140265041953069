import React, { useContext, useEffect, useState } from 'react';
import { FullLoading } from '../../Components/FullLoading';
import { WorkArea } from '../../Components/WorkArea';
import useScoreRange from '../../hooks/scoreRanges/useScoreRanges';
import useReload from '../../hooks/useReload';
import { useCustomTranslation } from '../../hooks/useTranslations';
import ScoreRangesGrid from './components/ScoreRangesGrid';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../business/Provider';
import { ERROR_SHOW } from '../../business/constants';
import { Toast } from '../../Components/toast';

export const ScoreRangesComponent = () => {
	/** Reload **/
	//console.log('Guide Id',guideId)
	const context = useContext(StoreContext);
	let { guideId } = useParams();
	const { scoreRangesState, dispatchScoreRanges } = useContext(StoreContext);
	const { getScoreRange } = useScoreRange(guideId);
	useReload([getScoreRange]);
	const { t } = useCustomTranslation();
	const [scoreRange, setScoreRange] = useState([]);
	useEffect(() => {
		if (
			scoreRangesState.scoreRanges[guideId] &&
			scoreRangesState.scoreRanges[guideId]?.length > 0
		) {
			setScoreRange(scoreRangesState.scoreRanges[guideId]);
		}
	}, [scoreRangesState.scoreRanges, guideId]);
	useEffect(() => {
		if (scoreRange.error && scoreRange.errorShow === false) {
			dispatchScoreRanges({ type: ERROR_SHOW });
			Toast('warning', 'Error', scoreRange.error);
		}
	}, [scoreRange.error]);

	return (
		<div className='relative flex-grow w-full'>
			<WorkArea
				grid={
					<ScoreRangesGrid
						t={t}
						rows={scoreRange}
						loading={context.scoreRangesState.loading}
						guideId={guideId}
					/>
				}
			/>
			<FullLoading show={context.scoreRangesState.loading} />
		</div>
	);
};

ScoreRangesComponent.propTypes = {
	guideId: PropTypes.string,
};
