import React from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import { SurveyNames, ToolBarNames } from '../../../translations';
import { surveyCodeMask } from '../../../utils/utilsService';
import { CustomIconButton } from '../../../Components/Buttons/CustomIconButton';
import { BsFiletypePdf, BsFiletypeXls } from 'react-icons/bs';

export const HeaderSurvey = ({ survResult, exportToExcel, exportToPdf }) => {
	const { t } = useCustomTranslation();
	const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
	const renderOption = (title, children) => {
		return (
			<div className='flex w-1/4 h-1/3 flex-col '>
				<div className='flex w-full h-1/2 content-end justify-start items-end '>
					<h1 className='font-semibold content-end ml-1'>{title}</h1>
				</div>
				<div className='flex w-full h-1/2' title={children}>
					<h1 className='truncate w-full h-full text-xs ml-1'>{children}</h1>
				</div>
			</div>
		);
	};
	const renderActionsButtons = () => {
		return (
			<div className='flex w-1/4 h-1/3 flex-row-reverse pr-2 pt-2 pb-2'>
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.EXCEL)}
					onClick={exportToExcel}
					className={'!mr-2'}
					//icon={faFileExcel}
				>
					<BsFiletypeXls className='text-xl ' />
				</CustomIconButton>
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.PDF)}
					onClick={exportToPdf}
					className={'!mr-2'}
					//	icon={faFilePdf}
				>
					<BsFiletypePdf className='text-xl ' />
				</CustomIconButton>
			</div>
		);
	};
	return (
		survResult && (
			<div className='flex h-full w-full flex-col divide-y '>
				<div className='flex w-full h-[20%] flex-col '>
					<div className='flex w-full h-1/2 justify-center items-center content-center'>
						<h1 className='font-bold text-sm '>{survResult?.general.Title}</h1>
					</div>
					<div className='flex w-full h-1/2 justify-center items-center content-center'>
						<h1 className='font-bold text-sm'>{companyInfo.name}</h1>
					</div>
				</div>
				<div className='flex w-full h-[90%] flex-wrap'>
					{renderOption(
						getTranslation(t, SurveyNames.NUMBER_SURVEY),
						surveyCodeMask(survResult?.general.SurveyCode)
					)}
					{renderOption(
						getTranslation(t, SurveyNames.QUESTIONARY_TITLE),
						survResult?.general.SCCode
					)}
					{renderOption(
						getTranslation(t, SurveyNames.APPLICATION_DATE),
						survResult?.general.ApplicationDate
					)}
					{renderOption(
						getTranslation(t, SurveyNames.COLLABORATOR),
						survResult?.general.CollaboratorName
					)}
					{renderOption(
						getTranslation(t, SurveyNames.EMAIL),
						survResult?.general.CollaboratorEmail
					)}
					{renderOption(
						getTranslation(t, SurveyNames.WORK_CENTER),
						survResult?.general.WorkCenterText || '----------------------'
					)}
					{renderOption(
						getTranslation(t, SurveyNames.DEPARTMENT),
						survResult?.general.DepartmentText
					)}
					{renderOption(
						getTranslation(t, SurveyNames.ZONE),
						survResult?.general.AreaText || '----------------------'
					)}
					{renderOption(
						getTranslation(t, SurveyNames.POSITION),
						survResult?.general.PositionText || '----------------------'
					)}
					{renderOption('', '')}
					{renderOption('', '')}
					{renderActionsButtons()}
				</div>
			</div>
		)
	);
};
