import React from 'react'
import { Dialog } from "primereact/dialog"
import { BaseButton } from "../../../Components/Buttons/BaseButton"
import { getTranslation, useCustomTranslation } from '../../../hooks/useTranslations'
import { GenericsNames, QueryNames } from '../../../translations'
export const ErrorDialog = ({ visible, setVisible, errorMessage }) => {
    const {t} = useCustomTranslation()
    return (
        <Dialog visible={visible} header={getTranslation(t, QueryNames.QUERY_RELATIONS_ERROR_HEADER)} onHide={() => setVisible(false)}>
            <p>{getTranslation(t, QueryNames.QUERY_RELATIONS_ERROR_MESSAGE)} </p>
            <p>{errorMessage}</p>
            <div className='flex gap-2'>
                <BaseButton className='w-full !mt-2' label={getTranslation(t, GenericsNames.ACCEPT)} onClick={() => setVisible(false)} />
            </div>
        </Dialog>
    )
}