import { GET_WORK_CENTER, GET_WORK_CENTER_ERROR, GET_WORK_CENTER_SUCCESS } from '../../business/constants';
import { useContext, useEffect, useState } from 'react'

import { IsNullOrEmpty } from './../../utils/index';
import { StoreContext } from '../../business/Provider';
import { sortBy } from 'lodash'
import useApi from '../api';
import { getTranslation, useCustomTranslation } from '../useTranslations';
import { WorkCenterNames } from '../../translations';
import { Toast } from '../../Components/toast';
import { LoadingContext } from '../../context/LoadingContext';

const useWorkCenters = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/WorkCenter`
  const [workCenters, setWorkCenters] = useState(undefined)
  const [workCentersOptions, setWorkCentersOptions] = useState(undefined)
  const [loadingWorkCenters, setLoadingWorkCenters] = useState(true)
  const {setLoading} = useContext(LoadingContext);
  const { genericApiCall } = useApi();
  const context = useContext(StoreContext);
  const {t} = useCustomTranslation()
  const getWorkCenterOptions = (workCenterResponse) => {
    const activeworkCenterResponse = workCenterResponse.filter((object) => {
      return IsNullOrEmpty(object.deleteBy)
    })
    return sortBy(activeworkCenterResponse, [
      function (o) {
        return o.name
      }
    ]).map((item) => ({ label: item.name, value: item.id }))

  }
  const getWorkCenterList = async () => {
    try {
      setLoadingWorkCenters(true)
      const response = await getWorkCenters()
      //console.log('data WorkCenter->', response.data)
      setWorkCenters(response.data.data.workCenters)
      setWorkCentersOptions(getWorkCenterOptions(response.data.data.workCenters))
      setLoadingWorkCenters(false)
    } catch (error) {
    
      Toast('warning',error.message, "", error)    
      setWorkCenters([])
      setLoadingWorkCenters(false)
    }
  }

  const postWorkCenter = async (WorkCenter) => {
    try{
      const result = await genericApiCall(baseUrl, 'POST', WorkCenter)
      if (result.status === 200) {
        Toast('success', getTranslation(t, WorkCenterNames.SUCCESS_ADD))
        const newWorkCenter = result.data.data.workCenter
        if(newWorkCenter.isActive===1){
          newWorkCenter.isActive=true;
        }
        return newWorkCenter
      } else {
        let message = {
          body: '',
          header: getTranslation(t, WorkCenterNames.ERROR_ADD)
        }
        if(result.message) message.body = result.message
      context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR, payload: message })
      return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR})
    }     
  }

  const getWorkCenters = async () => {
    try{
      context.dispatchWorkCenter({ type: GET_WORK_CENTER })
      const result = await genericApiCall(baseUrl, 'get')
      if (result.status === 200) {
        context.dispatchWorkCenter({ type: GET_WORK_CENTER_SUCCESS, payload: result.data.data.workCenters })
      } else {
        let message = {
          body: '',
          header: 'Error'
        }
        if (result.message) message.body = result.message

        context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR, payload: message })
      }
      return result
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR})
    }     
  }
  const putWorkCenter = async (WorkCenter) => {
    try{
      const result = await genericApiCall(baseUrl, 'PUT', WorkCenter)
      if (result.status === 200) {
        Toast('success', getTranslation(t, WorkCenterNames.SUCCESS_UPDATE))
        return result.data.data.workCenter
      } else {
        let message = {
          body: '',
          header: getTranslation(t, WorkCenterNames.ERROR_UPDATE)
        }
        if (result.message) message.body = result.message
        context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR})
    }     
  }

  const deleteWorkCenter = async (WorkCenter) => {
    try{
      const result = await genericApiCall(baseUrl, 'DELETE', WorkCenter)
      if (result.status === 200) {
        Toast('success', getTranslation(t, WorkCenterNames.SUCCESS_DELETE))
        return result.data.data
      } else {
        let message = {
          body: '',
          header: getTranslation(t, WorkCenterNames.ERROR_DELETE)
        }
        if (result.message) message.body = result.message
        context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR, payload: message })
        return undefined
      }
    } catch (error) {
      Toast('warning',error.message, "", error)
      context.dispatchWorkCenter({ type: GET_WORK_CENTER_ERROR})
    } 
  }

  const getNextCode = async () => {
    try{
      setLoading(true)
      const result = await genericApiCall(`${baseUrl}/next-code`, 'get')
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      Toast('warning',error.message, "", error)
    }     
  }

  useEffect(() => {
    let abortController = new AbortController();  
    if(!context.workCenterState?.workCenter && context.workCenterState.loading === false && context.workCenterState.error === null) {
      getWorkCenters()
    }
    return() => {
      abortController.abort()
    }
  }, [context.workCenterState])

  return {
    setWorkCenters,
    workCenters,
    loadingWorkCenters,
    postWorkCenter,
    putWorkCenter,
    deleteWorkCenter,
    getNextCode,
    getWorkCenters,
    getWorkCenterList,
    workCentersOptions
  }
}
export default useWorkCenters
