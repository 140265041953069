import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import moment from 'moment';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import { ResultsNames } from '../../../translations';
import { SelectComponent } from '../../../Components/Select';
import { convertDate } from '../../../utils/utils';
import { ChartBarComponent } from '../../../Components/Charts';

export const ContentComponent = ({
	selectSurvey,
	surveyIdState,
	surveys,
	setSurveyIdState,
	resetForm,
	getSurveys,
	guideId,
}) => {
	const bg = `${selectSurvey.detail?.general.ScoreColor || 'transparent'}`;
	const { t } = useCustomTranslation();
	const guideIdToString = guideId.toString();

	const renderHeaderContent = () => {
		const valueStartDate = convertDate(selectSurvey?.base?.startDate);
		const valueEndDate = convertDate(selectSurvey?.base?.endDate);
		return (
			<div className=' flex w-full p-2  border-border h-16'>
				<div className='flex w-full h-full flex-row '>
					<div className='flex h-full w-[10%] '>
						<SelectComponent
							className={'w-full h-full flex'}
							ValuesOptions={surveys}
							optionLabel='scode'
							optionValue={'id'}
							valueSelected={surveyIdState}
							name='surveyId'
							handleChange={(e) => {
								setSurveyIdState(e.value);
								resetForm();
								getSurveys({}, e.value);
							}}
						/>
					</div>

					<div className='flex h-full w-[35%]  items-center text-center  p-2'>
						<p>{selectSurvey.base?.name}</p>
					</div>
					<div className='flex h-full w-[55%] text-center  justify-start  p-2'>
						<p>
							{getTranslation(t, ResultsNames.SINCE)}:
							<span className='px-2 font-light'>
								{selectSurvey.base?.startDate
									? moment(valueStartDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
									: '--/--/----'}
							</span>
							{getTranslation(t, ResultsNames.TO)}:
							<span className='px-2 font-light'>
								{selectSurvey.base?.endDate
									? moment(valueEndDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
									: '--/--/----'}
							</span>
						</p>
					</div>
				</div>
			</div>
		);
	};

	const renderContentBody = () => {
		return (
			<>
				<div className='text-center'>
					<p>{getTranslation(t, ResultsNames.GENERAL_RESULT)}</p>
				</div>
				<div className='flex w-full justify-center '>
					<div
						style={{
							background: bg,
						}}
						className={`w-1/3 h-12  flex text-center items-center justify-center border border-border text-black`}>
						{selectSurvey.detail?.general?.GeneralScoreText}
					</div>
				</div>
				<Fieldset
					legend={getTranslation(t, ResultsNames.RECOMMENDATION)}
					className=' border border-border p-1 relative flex-grow'>
					<div className='absolute top-0 bottom-0 overflow-y-auto !font-font-family-base m-4'>
						<p>{selectSurvey.detail?.general?.ScoreDescription}</p>
					</div>
				</Fieldset>
			</>
		);
	};

	function generateData(dataChart) {
		if (dataChart !== undefined) {
			let labels = dataChart.map((data) => data.LabelText);
			let data = dataChart.map((data) => data.TotalCount);
			let name = dataChart[0].ChartName;
			return {
				dataLabels: labels,
				dataChart: data,
				name,
			};
		}
	}

	const renderChart = (chartData, width, id, label) => {
		return (
			<section className='flex-shrink-0 w-full xl:!w-1/2 2xl:!w-1/3 p-2'>
				<div className={' flex h-full  shadow-2xl p-2 bg-white w-full'}>
					<ChartBarComponent
						dataChart={chartData.dataChart}
						labels={chartData.dataLabels}
						name={chartData.name}
						id={id}
						label={label}
					/>
				</div>
			</section>
		);
	};
	const renderChart2 = (chartData, width, id, label) => {
		return (
			<section className='flex-shrink-0 w-full xl:!w-1/2 2xl:!w-1/2 p-2'>
				<div className={' flex h-full  shadow-2xl p-2 bg-white w-full'}>
					<ChartBarComponent
						dataChart={chartData.dataChart}
						labels={chartData.dataLabels}
						name={chartData.name}
						id={id}
						label={label}
					/>
				</div>
			</section>
		);
	};

	const renderChartContent = () => {
		const chartCHAG = generateData(selectSurvey?.detail?.chag);
		const chartCHD4 = generateData(selectSurvey?.detail?.chd4);
		const chartCHD5 = generateData(selectSurvey?.detail?.chd5);
		const chartCHAS = generateData(selectSurvey?.detail?.chas);
		const width = '!w-1/3 lg:w-full xl:!w-1/2 2xl:!w-1/3';
		return selectSurvey.detail !== null ? (
			<div className='flex w-full h-2/3  justify-center content-center items-center relative'>
				<div className='flex w-full h-full'>
					{guideIdToString === '6' ? (
						<div className='flex  flex-col lg:!flex-row :xl:!flex-row 2xl:!flex-row  inset-0 overflow-x-scroll absolute'>
							{renderChart(chartCHAG, width, 'CHAG', 'Encuestados')}
							{renderChart(chartCHD4, width, 'CHD4', 'Encuestados')}
							{renderChart(chartCHD5, width, 'CHD5', 'Encuestados')}
						</div>
					) : (
						<div className='flex  flex-col lg:!flex-row :xl:!flex-row 2xl:!flex-row  inset-0 overflow-x-scroll absolute'>
							{renderChart2(chartCHAG, 'w-1/2', 'CHAG', 'Encuestados')}
							{renderChart2(chartCHAS, 'w-1/2', 'CHAS', 'Encuestados')}
						</div>
					)}
				</div>
			</div>
		) : (
			<div className='flex w-full h-2/3  justify-center content-center items-center relative' />
		);
	};

	return (
		<div className='w-full h-full p-2 gap-2 flex flex-col'>
			{renderHeaderContent()}
			{surveyIdState ? (
				<div className='p-2 border border-border flex-grow flex flex-col gap-2'>
					{guideIdToString !== '6' && guideIdToString !== '7'
						? renderContentBody()
						: renderChartContent()}
					<Fieldset
						legend={getTranslation(t, ResultsNames.CONCLUSION)}
						className='border p-1 border-border relative flex-grow '>
						<div className='m-4 absolute top-0 bottom-0 overflow-y-auto !font-font-family-base'>
							<p> {selectSurvey.detail?.general?.Conclusion}</p>
						</div>
					</Fieldset>
				</div>
			) : (
				<div className='p-4 border border-border flex-grow flex flex-col gap-4 justify-center font-h1-font-family text-center text-h1-font-size '>
					<span className='opacity-3'>
						{getTranslation(t, ResultsNames.SELECT_SURVEY)}
					</span>
				</div>
			)}
		</div>
	);
};
