import {
	GET_SURVEY_COLLABORATOR,
	GET_SURVEY_COLLABORATOR_ERROR,
	GET_SURVEY_COLLABORATOR_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
	PUT_SHARE,
	PUT_SHARE_ERROR,
	PUT_SHARE_SUCCESS,
} from '../../business/constants';
import { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../business/Provider';
import { Toast } from '../../Components/toast';
import useApi from '../api';
import { getTranslation, useCustomTranslation } from '../useTranslations';
import { SurveysQuestionsNames } from '../../translations';

const useQuestionnaireGuide = (guideId, surveyDataID) => {
	const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/guide/${guideId}`;
	const [collaborators, setCollaborators] = useState(undefined);

	const { genericApiCall } = useApi();
	const { t } = useCustomTranslation();
	const {
		dispatchSurveyCollaborator,
		dispatchLoading,
		surveyCollaboratorState,
		dispatchPutShare,
	} = useContext(StoreContext);

	const getCollaborators = async (surveyId) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR });
			const result = await genericApiCall(
				`${baseUrl}/survey/${surveyId === undefined ? surveyDataID : surveyId}`,
				'get'
			);
			if (result.status === 200) {
				const payload = {
					data: result.data.data,
					guideId: guideId,
					surveyId: surveyId,
				};
				dispatchSurveyCollaborator({
					type: GET_SURVEY_COLLABORATOR_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = '';
				if (result.data) message = result.data.message;
				else message = result.message;
				dispatchSurveyCollaborator({
					type: GET_SURVEY_COLLABORATOR_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
			dispatchSurveyCollaborator({ type: GET_SURVEY_COLLABORATOR_ERROR });
		}
	};
	const putCancelStatus = async (
		surveyId,
		collaboratorIds,
		surveyCollaboratorId
	) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(
				`${baseUrl}/survey/${surveyId}/collaborator/${surveyCollaboratorId}`,
				'put',
				collaboratorIds
			);

			if (result.status === 200) {
				dispatchLoading({ type: LOADING_OFF });
				return await getCollaborators(surveyId);
			}

			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postSendEmail = async (surveyId, collaboratorSurvey) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(
				`${baseUrl}/survey/${surveyId}/sendEmail`,
				'post',
				collaboratorSurvey
			);
			if (result.status === 200) {
				Toast(
					'success',
					getTranslation(t, SurveysQuestionsNames.surveyMessageSuccess)
				);
				return await getCollaborators(surveyId);
			} else {
				Toast(
					'warning',
					getTranslation(t, SurveysQuestionsNames.surveyMessageError)
				);
			}
			dispatchLoading({ type: LOADING_OFF });
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const putShare = async (
		surveyId,
		collaboratorSurvey,
		surveyCollaboratorKey,
		collaboratorId
	) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			dispatchPutShare({ type: PUT_SHARE });
			const result = await genericApiCall(
				`${baseUrl}/survey/${surveyId}/collaborator/${collaboratorSurvey}/collaboratorKey/${surveyCollaboratorKey}/share/${collaboratorId}`,
				'put'
			);

			if (result.status === 200) {
				dispatchPutShare({
					type: PUT_SHARE_SUCCESS,
					payload: result.data.data.data,
				});
				dispatchLoading({ type: LOADING_OFF });
				return await getCollaborators(surveyId);
			} else {
				let message = '';
				if (result.data) message = result.data.message;
				else message = result.message;
				dispatchPutShare({
					type: PUT_SHARE_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}

			dispatchLoading({ type: LOADING_OFF });
			return result;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const deleteSurveyCollaborator = async (surveyId, collaboratorId) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(
				`${baseUrl}/survey/${surveyId}/collaborator/${collaboratorId}`,
				'delete'
			);
			if (result.status === 200) {
				Toast(
					'success',
					'Se ha eliminado correctamente el colaborador.',
					'',
					result
				);
				return await getCollaborators(surveyId);
			}
			dispatchLoading({ type: LOADING_OFF });
			return result;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });
			Toast('warning', error.message, '', error);
		}
	};

	const getCollaboratorsAnswers = async (surveyId, collaboratorId) => {
		try {
			return await genericApiCall(
				`${baseUrl}/surveyCollaboratorAnswers/${collaboratorId}`,
				'get'
			);
		} catch (error) {
			Toast('warning', error.message, '', error);
		}
	};

	// useEffect(() => {
	// 	if (
	// 		surveyCollaboratorState.error === null &&
	// 		surveyCollaboratorState.loading === false &&
	// 		surveyCollaboratorState.surveyCollaborator === undefined
	// 	) {
	// 		getCollaborators(surveyIdData);
	// 	}
	// }, [surveyCollaboratorState]);

	return {
		setCollaborators: setCollaborators,
		collaborators: collaborators,
		getCollaborators: getCollaborators,
		putCancelStatus: putCancelStatus,
		postSendEmail: postSendEmail,
		deleteSurveyCollaborator: deleteSurveyCollaborator,
		getCollaboratorsAnswers: getCollaboratorsAnswers,
		putShare: putShare,
	};
};
export default useQuestionnaireGuide;
