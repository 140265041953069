import { CheckBoxComponent } from '../../../../Components/CheckBox';
import { Input } from '../../../../Components/Input/Input';
import React from 'react';
import { SearchInput } from '../../../../Components/SearchInput';
import { SelectComponent } from '../../../../Components/Select';
import { WorkCenterNames } from '../../../../translations';
import { areaCodeMask } from '../../../../utils/utilsService';
import { firstOrDefault } from '../../../../Auth/arrayService';
import { getTranslation } from '../../../../hooks/useTranslations';
import { AddressInformationComponent } from '../../../../Components/AddressInformationComponent';

const renderInput = (
	id,
	name,
	placeholder,
	onChange,
	value,
	onBlur,
	error,
	errorText,
	type,
	important,
	rows,
	cols
) => {
	return (
		<div className='flex w-1/2 p-2'>
			<Input
				id={id}
				name={name}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				onBlur={onBlur}
				error={error}
				errorText={errorText}
				type={type}
				important={important}
				rows={rows}
				cols={cols}
			/>
		</div>
	);
};

export const renderWorkCenterInputs = (t, formik, evaluators) => {
	return (
		<>
			<div className='flex w-full flex-row'>
				{renderInput(
					'scode',
					'scode',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_KEY),
					formik.handleChange,
					formik.values.scode || '',
					(event) => {
						formik.handleBlur(event);
						const paddingZero = areaCodeMask(event.target.value);
						formik.setFieldValue('scode', paddingZero);
					},
					formik.touched.scode && Boolean(formik.errors.scode),
					formik.touched.scode && getTranslation(t, formik.errors.scode),
					'number',
					true
				)}
				{renderInput(
					'name',
					'name',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_NAME),
					formik.handleChange,
					formik.values.name || '',
					(event) => {
						formik.handleBlur(event);
						formik.setFieldValue('name', event.target.value.trim());
					},
					formik.touched.name && Boolean(formik.errors.name),
					formik.touched.name && getTranslation(t, formik.errors.name),
					'text',
					true
				)}
			</div>
			<div className='flex w-full flex-row'>
				<SelectComponent
					className={'flex flex-col p-2 w-1/2'}
					valueSelected={formik.values.evaluatorId}
					labelText={getTranslation(t, WorkCenterNames.GRID_COLUMN_EVALUATOR)}
					ValuesOptions={evaluators}
					handleChange={(e) => {
						formik.setFieldValue('evaluatorId', e.target.value);
					}}
					placeholderText={'...'}
					name={'evaluatorId'}
					optionLabel={'label'}
					error={Boolean(
						formik.touched.evaluatorId && formik.errors.evaluatorId
					)}
					errorText={
						formik.touched.evaluatorId &&
						getTranslation(t, formik.errors.evaluatorId)
					}
				/>
				{renderInput(
					'activities',
					'activities',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_ACTIVITIES),
					(e) => {
						const value = e.target.value;
						if (value.startsWith(' ')) {
							formik.setFieldValue('activities', value.trim());
						} else {
							formik.setFieldValue('activities', value);
						}
					},
					formik.values.activities || '',
					(event) => {
						formik.handleBlur(event);
						formik.setFieldValue('activities', event.target.value.trim());
					},
					formik.touched.activities && Boolean(formik.errors.activities),
					formik.touched.activities &&
						getTranslation(t, formik.errors.activities),
					'text',
					false,
					2,
					4
				)}
			</div>
			<div className='flex w-full flex-row'>
				{renderInput(
					'objective',
					'objective',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_OBJECTIVE),
					(e) => {
						// delete with space if is in the start
						const value = e.target.value;
						if (value.startsWith(' ')) {
							formik.setFieldValue('objective', e.target.value.trim());
						} else {
							formik.setFieldValue('objective', e.target.value);
						}
					},
					formik.values.objective || '',
					(event) => {
						formik.handleBlur(event);
						formik.setFieldValue('objective', event.target.value.trim());
					},
					formik.touched.objective && Boolean(formik.errors.objective),
					formik.touched.objective &&
						getTranslation(t, formik.errors.objective),
					'textArea',
					false,
					2,
					4
				)}

				{renderInput(
					'principalActivity',
					'principalActivity',
					getTranslation(t, WorkCenterNames.GRID_COLUMN_PRINCIPAL_ACTIVITY),
					(event) => {
						const value = event.target.value;
						if (value.startsWith(' ')) {
							formik.setFieldValue(
								'principalActivity',
								event.target.value.trim()
							);
						} else {
							formik.setFieldValue('principalActivity', event.target.value);
						}
					},
					formik.values.principalActivity || '',
					formik.handleBlur,
					formik.touched.principalActivity &&
						Boolean(formik.errors.principalActivity),
					formik.touched.principalActivity &&
						getTranslation(t, formik.errors.principalActivity),
					'textArea',
					false,
					2,
					4
				)}
			</div>
			<div className='flex flex-row w-full '>
				<div className='flex w-1/2 pl-2'>
					<CheckBoxComponent
						className='flex w-1/2 p-2 content-center items-center'
						value={formik.values.isActive}
						text={getTranslation(t, WorkCenterNames.GRID_COLUMN_ACTIVE)}
						handleChange={(e) => {
							console.log(e);
							formik.setFieldValue('isActive', e.checked);
						}}
						name={'isActive'}
					/>
				</div>
			</div>
		</>
	);
};
