
import { useContext, useEffect } from 'react'
import { GET_ENTITY, GET_ENTITY_SUCCESS, GET_QUERY, GET_QUERY_DATA_SUCCESS, GET_QUERY_ERROR, GET_QUERY_PROPERTIES, GET_QUERY_PROPERTIES_ERROR, GET_QUERY_PROPERTIES_SUCCESS, GET_QUERY_SUCCESS } from '../../business/constants';
import { StoreContext } from '../../business/Provider';
import { QueryNames } from '../../translations';
import useApi from '../api'
import { getTranslation, useCustomTranslation } from '../useTranslations';
import { Toast } from '../../Components/toast'

const useQueries = () => {
    const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/queries`
    const { genericApiCall } = useApi();
    const {t} = useCustomTranslation()
    const {queryState, dispatchQuery} = useContext(StoreContext)
    const getEntity = async () => {
        const headers = {
            'Cache-Control': 'no-cache',
          }
            dispatchQuery({ type: GET_QUERY })
            try{
                const response = await genericApiCall(baseUrl+'/queries-entities', 'GET',{},{},headers)
               
                    const payload = {
                        entity: response.data.data.entitiesTree,
                        entityList: response.data.data.entities
                    }
                    dispatchQuery({ type: GET_ENTITY_SUCCESS, payload: payload })
            } catch (err) {
                let message = {
                    body: '',
                    header: getTranslation(t, QueryNames.ENTITY_GET_ERROR)
                  }
                  if(err.message) message.body = err.message
                dispatchQuery({ type: GET_QUERY_ERROR, payload: message })            
            }
           

    }
    const getQueries = async () => {
        try{
            const headers = {
                'Cache-Control': 'no-cache',
              }
            dispatchQuery({ type: GET_QUERY })
            const response = await genericApiCall(baseUrl+'/queries', 'GET',{},{},headers)
            if( response.status === 200) {
                dispatchQuery({ type: GET_QUERY_SUCCESS, payload: response.data.data })
            } else {
                let message = {
                    body: '',
                    header: getTranslation(t, QueryNames.QUERY_GET_ERROR)
                  }
                  if(response.message) message.body = response.message
                dispatchQuery({ type: GET_QUERY_ERROR, payload: message })
            }
        } catch (error) {
            let message = {
                body: '',
                header: getTranslation(t, QueryNames.QUERY_GET_ERROR)
            }
            if(error.message) message.body = error.message
            dispatchQuery({ type: GET_QUERY_ERROR, payload: message  })
        }          
    }
    const getQueryData = async (queryId, repeat = 0) => {
        try{
            dispatchQuery({ type: GET_QUERY })
            // if response status is different than 200, repit the request 3 times 
            let response = await genericApiCall(baseUrl+'/queries/'+queryId+'/data', 'GET')
                const payload = {
                    data: response.data.data,
                    queryId: queryId
                }
                dispatchQuery({ type: GET_QUERY_DATA_SUCCESS, payload: payload })
                return response.data.data            
        } catch (error) {
            if(repeat !== 2) {
              return  setTimeout(() => {
                    return getQueryData(queryId, repeat+1)
                }, 1000)
            } else {
                let message = {
                    body: '',
                    header: getTranslation(t, QueryNames.QUERY_GET_ERROR)
                }
                if(error.message) message.body = error.message
                dispatchQuery({ type: GET_QUERY_ERROR, payload: message  })
            }
            return false
        }
    }
    const getQueryProperties = async (queryId) => {
        try {
            dispatchQuery({ type: GET_QUERY_PROPERTIES })
            const response = await genericApiCall(baseUrl+'/queries/'+queryId+'/properties', 'GET')
            // add propertyKey field to each property
            const data = response.data.data.map(property => {
                property.propertyKey = queryState.entityList.find(item=> item.propertyId === property.propertyId).propertyKey
                return property
            }
            )
            const payload = {
                queryId: queryId,
                properties: data
            }
            dispatchQuery({ type: GET_QUERY_PROPERTIES_SUCCESS, payload: payload })
            return data
        } catch (error) {
            let message = {
                body: '',
                header: getTranslation(t, QueryNames.QUERY_GET_ERROR)
              }
              if(error.message) message.body = error.message
            dispatchQuery({ type: GET_QUERY_PROPERTIES_ERROR, payload: message })
        }          
        
    }

    const addQuery = async (query, guideId) => {
        try{
            const response = await genericApiCall(baseUrl+`/queries/${guideId}`, 'POST', query)
                return response.data.data.data
        } catch (error) {
            let message = {
                body: '',
                header: getTranslation(t, QueryNames.QUERY_POST_ERROR)
            }
            if(error.message) message.body = error.message
            dispatchQuery({ type: GET_QUERY_ERROR, payload: message  })
        }          
    }
    const deleteQuery = async (queryId) => {
        try{
            await genericApiCall(baseUrl+`/queries/${queryId}`, 'DELETE')
            return true
        } catch (error) {
            let message = {
                body: '',
                header: getTranslation(t, QueryNames.QUERY_DELETE_ERROR)
            }
            if(error.message) message.body = error.message
            dispatchQuery({ type: GET_QUERY_ERROR, payload: message  })
            return false
        }          
    }
    const updateQuery = async (query, queryId) => {
        try{
            const response = await genericApiCall(baseUrl+`/queries/${queryId}`, 'PUT', query)
                return response.data.data.data
        } catch (error) {
            let message = {
                body: '',
                header: getTranslation(t, QueryNames.QUERY_UPDATE_ERROR)
            }
            if(error.message) message.body = error.message
            dispatchQuery({ type: GET_QUERY_ERROR, payload: message  })
            return undefined
        }          
    }
    useEffect(() => {
        if(queryState.entity.length === 0) {
            getEntity()
        }
        if(queryState.query.length === 0) {
            getQueries()
        }
    }, [])
    return { getEntity, getQueries, addQuery, deleteQuery, updateQuery, getQueryProperties, getQueryData }
}
export default useQueries