import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import React from 'react';

const FileUpload = ({
	accept,
	onChange,
	disabled = false,
	label = 'Cargar Foto',
	allowClear = false,
	clearLabel = 'Limpiar',
	onClear,
}) => {
	const hiddenFileInput = React.useRef(null);
	// Programatically click the hidden file input element
	// when the Button component is clicked
	const handleClick = (event) => {
		//Clear value for always
		hiddenFileInput.current.value = null;
		hiddenFileInput.current.click();
	};
	return (
		<div className='space-x-2'>
			<input
				style={{ display: 'none' }}
				ref={hiddenFileInput}
				accept={accept}
				id='contained-button-file'
				multiple
				type='file'
				onChange={onChange}
			/>
			<Button
				variant='contained'
				component='span'
				disabled={disabled}
				type='button'
				onClick={handleClick}>
				{label}
			</Button>
			{allowClear && (
				<Button
					variant='contained'
					component='span'
					disabled={disabled}
					type='button'
					onClick={onClear}>
					{clearLabel}
				</Button>
			)}
		</div>
	);
};

FileUpload.propTypes = {
	accept: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	allowClear: PropTypes.bool,
	onClear: PropTypes.func,
	clearLabel: PropTypes.string,
};

export default FileUpload;
