import React, { useContext, useEffect, useState } from 'react';
import { EntityToExport, ToExcel, ToPdf } from '../../utils/exportService';
import { GenericsNames, QuestionsNames } from '../../translations';
import { findNestedObj } from '../../utils/utils';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

import { Column } from 'primereact/column';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import { NavigationContext } from '../../context/navigationContext';
import ToolBar from '../../Components/Table/ToolBar';
import { WorkArea } from '../../Components/WorkArea';
import routes from '../../routes';
import { useParams } from 'react-router-dom';
import useQuestions from '../../hooks/questions/useQuestions';
import useReload from '../../hooks/useReload';
import { StoreContext } from '../../business/Provider';
import { ERROR_SHOW } from '../../business/constants';
import { Toast } from '../../Components/toast';
import { RxActivityLog } from 'react-icons/rx';
import {
	COLUMNS_QUESTIONS,
	COLUMNS_QUESTIONS_5,
	COLUMNS_QUESTIONS_6,
} from './const';

export const QuestionList = () => {
	const { questionaryId } = useParams();
	const { dispatchLoading, questionsState, dispatchQuestions } =
		useContext(StoreContext);
	const { t } = useCustomTranslation();

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [selectedRows, setSelectedRows] = useState(null);
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	const { getQuestions } = useQuestions(questionaryId);
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		if (
			questionsState.questions[questionaryId] &&
			questionsState.questions[questionaryId]?.length > 0
		) {
			let questions = questionsState.questions[questionaryId];
			//eliminar del arreglo todos los datos que tengan questionNumber = 0
			let newQuestions = questions.filter(
				(question) => question.questionNumber !== 0
			);

			setQuestions(newQuestions);
		} else if (questionsState.questions[questionaryId]) {
			setQuestions([]);
		}
	}, [questionsState.questions, questionaryId]);

	useEffect(() => {
		if (questionsState?.error && questionsState.errorShow === false) {
			Toast('warning', 'Error', questionsState?.error);
			dispatchQuestions({ type: ERROR_SHOW });
		}
	}, [questionsState?.error]);
	/** Reload **/
	useReload([getQuestions]);
	/** navigation context **/
	const navigation = useContext(NavigationContext);
	const tittle = findNestedObj(
		routes,
		'tapTitle',
		navigation.currentTap?.father
	);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		category: {
			constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
		},
		domainHtml: {
			constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
		},
		dimensionHtml: {
			constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
		},
		questionHtml: {
			constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
		},
	});

	function goToRanges() {
		navigation.selectTap(
			`/guide-${questionaryId}/score-ranges/guideId/${questionaryId}`
		);
	}

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	async function handleExcelExport() {
		const printObj = {
			questionaryId: questionaryId,
			...filters,
			sort: sort[0],
		};
		questions.length > 0
			? await ToExcel(printObj, EntityToExport.GUIDE_QUESTIONS, dispatchLoading)
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	}

	async function handleToPDF() {
		const printObj = { questionaryId: questionaryId, ...filters };
		questions.length > 0
			? await ToPdf(printObj, EntityToExport.GUIDE_QUESTIONS, dispatchLoading)
			: Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
	}

	const renderHeader = () => {
		const newQuestionaryToString = questionaryId.toString();
		return (
			<ToolBar
				value={globalFilterValue}
				setValue={setGlobalFilterValue}
				setFilters={setFilters}
				onChange={onGlobalFilterChange}
				placeholder={getTranslation(t, GenericsNames.SEARCH)}
				onExcelClick={() => {
					handleExcelExport();
				}}
				onPDFClick={() => {
					handleToPDF();
				}}>
				{newQuestionaryToString !== '1' &&
				newQuestionaryToString !== '5' &&
				newQuestionaryToString !== '6' &&
				newQuestionaryToString !== '7' ? (
					<CustomIconButton
						className='!m-1'
						toolTip={getTranslation(t, GenericsNames.VIEW_RANGE)}
						onClick={() => goToRanges()}>
						<RxActivityLog />
					</CustomIconButton>
				) : null}
			</ToolBar>
		);
	};
	const headerTemplate = (rowData) => {
		return (
			<label className='font-bold'>{rowData.sectionHtmlWithSequence}</label>
		);
	};
	// if questionaryId === 1 and is domainHtml and is dimensionHtml
	const isDomainDimension = (column) => {
		if (questionaryId == 1) {
			return column.field === 'domainHtml' || column.field === 'dimensionHtml';
		}
		return false;
	};
	function renderDataTable() {
		let currentList = COLUMNS_QUESTIONS;
		if (questionaryId == 5) {
			currentList = COLUMNS_QUESTIONS_5;
		} else if (questionaryId == 6 || questionaryId == 7) {
			currentList = COLUMNS_QUESTIONS_6;
		}
		return (
			<EvaluaTable
				value={questions}
				//sortField="category"
				loading={questionsState.loading}
				header={renderHeader()}
				selection={selectedRows}
				onSelectionChange={(e) => setSelectedRows(e.value)}
				filters={filters}
				//filterDisplay="row" // input search
				globalFilterFields={[
					'questionNumber',
					'category',
					'domainHtml',
					'dimensionHtml',
					'questionHtml',
				]}
				emptyMessage={getTranslation(t, QuestionsNames.EMPTY_QUESTION)}
				currentPageReportTemplate={getTranslation(
					t,
					QuestionsNames.GRID_PAGE_REPORT_TEMPLATE
				)}
				rowGroupHeaderTemplate={headerTemplate}
				groupRowsBy='sectionHtmlWithSequence'
				rowGroupMode='subheader'
				style={{ height: 'calc(100vh - 186px)', width: 'calc(100vw - 92px)' }}
				setSort={setSort}
				sort={sort}>
				{currentList.map((column) => (
					<Column
						hidden={isDomainDimension(column)}
						key={column.field}
						field={column.field}
						body={(e) => {
							return (
								<div
									dangerouslySetInnerHTML={{ __html: e[column.field] }}></div>
							);
						}}
						header={getTranslation(t, column.headerTranslation)}
						showFilterMenu={false}
						style={{
							flexGrow: 1,
							flexBasis: `${column.width}`,
							whiteSpace: 'normal',
						}}
					/>
				))}
			</EvaluaTable>
		);
	}

	const mainHeader = () => {
		return (
			<div className='w-full h-20 font-font-family-base text-sm p-2'>
				<div className=' flex justify-center'>
					<p className='font-semibold flex uppercase'>
						{getTranslation(t, tittle.title)} 
					</p>
				</div>
				<div className=' flex justify-center'>
					<p>
						{getTranslation(t, GenericsNames.QUESTION)} : {questions?.length}
					</p>
				</div>
			</div>
		);
	};

	return (
		<>
			<section className='relative flex-grow w-full flex-row'>
				<WorkArea
					grid={
						<div>
							{mainHeader()}
							{renderDataTable()}
						</div>
					}
				/>
			</section>
		</>
	);
};
