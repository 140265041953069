import { GenericsNames, QuestionsNames } from '../../translations';

export const COLUMNS_QUESTIONS = [
	{
		field: 'questionNumber',
		headerTranslation: QuestionsNames.ID,
		width: '5%',
		sort: true,
	},
	{
		field: 'category',
		headerTranslation: GenericsNames.CATEGORY,
		width: '20%',
		sort: true,
	},
	{
		field: 'domainHtml',
		headerTranslation: GenericsNames.DOMAIN,
		width: '20%',
		sort: false,
	},
	{
		field: 'dimensionHtml',
		headerTranslation: GenericsNames.DIMENSION,
		width: '20%',
		sort: false,
	},
	{
		field: 'questionHtml',
		headerTranslation: GenericsNames.QUESTION,
		width: '35%',
		sort: false,
	},
];

export const COLUMNS_QUESTIONS_5 = [
	{
		field: 'questionNumber',
		headerTranslation: QuestionsNames.ID,
		width: '5%',
		sort: true,
	},
	{
		field: 'questionHtml',
		headerTranslation: GenericsNames.QUESTION,
		width: '35%',
		sort: false,
	},
];

export const COLUMNS_QUESTIONS_6 = [
	{
		field: 'questionNumber',
		headerTranslation: QuestionsNames.ID,
		width: '5%',
		sort: true,
	},
	{
		field: 'category',
		headerTranslation: GenericsNames.CATEGORY,
		width: '20%',
		sort: true,
	},
	{
		field: 'questionHtml',
		headerTranslation: GenericsNames.QUESTION,
		width: '35%',
		sort: false,
	},
];
