export const CollaboratorsObject = {
  id: undefined,
  name: '',
  lastName: '',
  surName: "",
  isActive: true,
  email: '',
  gender: '',
  status: 'A',
  areaId: null,
  departmentId: '',
  positionId: null,
  workCenterId: null,
  licensed: 1,
  taxId: '',
}
