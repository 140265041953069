import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

import CompanyItem from '../../containers/Company/Components/companyItem';
import { CompanyRelatedContext } from '../../context/CompanyRelatedContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IsNullOrEmpty } from '../../utils';
import { StoreContext } from '../../business/Provider';
import { ToolBarNames } from '../../translations';
import { Tooltip } from 'primereact/tooltip';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import useCompanies from '../../hooks/Company/useCompany';
import useReload from '../../hooks/useReload';
import { useSelector } from 'react-redux';

export const CompanyInfo = ({
	refCloseCompanyInfo,
	showCompanyInfo,
	setShowCompanyInfo,
}) => {
	const { companyState } = useContext(StoreContext);
	const { t } = useCustomTranslation();
	const user = useSelector(({ auth }) => auth.user);
	// const [showCompanyInfo, setShowCompanyInfo] = useState(false);
	const { currentCompany } = useCompanies();
	const { relatedCompanies } = useContext(CompanyRelatedContext);
	const getRFCFromSession = () => {
		const sessionStateStorage = sessionStorage.getItem('sessionState');
		const sessionObj = JSON.parse(sessionStateStorage);
		document.title = sessionObj
			? (sessionObj?.rfc || '') + ' | ' + ' CONTPAQi® Evalua'
			: 'CONTPAQi® Evalua';
		return sessionObj?.rfc || '';
	};
	//useReload([getRelatedCompanies])
	const onGetRelatedCompanies = async () => {
		setShowCompanyInfo(!showCompanyInfo);
	};
	const renderCompanyRelatedItemsNavBar = () => {
		return (
			<div className='flex w-[30px] h-16 justify-around'>
				<div
					className='flex h-full w-5/12'
					title={getTranslation(t, ToolBarNames.COMPANY_RELATED)}>
					<Fragment key={'companyRelated'}>
						<div
							key={'companyRelated'}
							onClick={onGetRelatedCompanies}
							className={`tooltip-companyRelated flex-grow flex text-[#5e5e5e]  items-center content-center justify-center cursor-pointer hover:text-[#0a6ebd] hover:bg-[#5e5e5e1e]`}>
							<FontAwesomeIcon icon={faLink} />
						</div>
					</Fragment>
				</div>
			</div>
		);
	};
	useEffect(async () => {
		let token = window.localStorage.getItem('accessToken');
		function parseJwt(token) {
			let base64Url = token.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);

			return JSON.parse(jsonPayload);
		}

		const dataInfo = parseJwt(token);
		//	console.log(dataInfo);

		if (!IsNullOrEmpty(companyState?.companies)) {
			localStorage.setItem(
				'companyInfo',
				JSON.stringify(companyState?.companies)
			);
			window.idzCustomData = {
				cust_asesorcontpaqi: false,
				cust_certificaciones: 0,
				cust_cid: 0,
				cust_email: dataInfo.email,
				cust_excomercial: false,
				cust_excontable: false,
				cust_mesaservicio: false,
				cust_nivel: 'value',
				cust_tipo: 'value',
				extid: dataInfo.extension_CONTPAQiID,
				logged_in: true,
				page_type: 'singlepage',
				distribuidor_de_servicio: 'value',
				distribuidor_de_venta: 'value',
				cust_url: 'https://evalua.contpaqinube.com',
				cust_nombre: 'value',
				cust_idsistema: 9986,
				rfc: '',
				razon_social: '',
				telefono: 0,
				ciudad: '',
				estado: '',
				pais: '',
			};

			let idz = document.createElement('script');
			idz.type = 'text/javascript';
			idz.async = true;
			idz.src =
				document.location.protocol + '//halc.iadvize.com/iadvize.js?sid=5665';
			let s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(idz, s);

			document.title =
				(companyState?.companies.taxID || '') + ' | ' + ' CONTPAQi® Evalua';
		} else {
			document.title = 'CONTPAQi® Evalua';
		}
	}, [companyState?.companies]);

	return (
		<>
			<div className='flex flex-col h-full w-4  font-h2-alt-font-family text-secondary-contrast-color-text pt-3'>
				{currentCompany && relatedCompanies && relatedCompanies.length > 0 ? (
					renderCompanyRelatedItemsNavBar()
				) : (
					<></>
				)}
			</div>
			<div
				ref={refCloseCompanyInfo}
				className='flex flex-col h-full w-full  font-h2-alt-font-family text-secondary-contrast-color-text pt-3 ml-2'>
				<div className='flex w-full font-bold  text-[#01b0f3] text-small-font-size '>
					{/*<p className='leading-3'>{getRFCFromSession()}</p>*/}
					<p className='leading-3'>
						{companyState?.companies?.taxID
							? companyState?.companies.taxID
							: getRFCFromSession()}
					</p>
				</div>
				<div
					title={currentCompany?.name}
					className='flex w-full  text-sm truncate'>
					{currentCompany ? currentCompany.name : ''}
				</div>
				<div className='flex w-full  text-xs'>
					{currentCompany ? currentCompany.alias : ''}
				</div>
			</div>
			{showCompanyInfo ? (
				<ul className='flex min-w-max flex-col pt-1 absolute top-[4rem] divide-y rounded-md bg-dropdown-bg-default drop-shadow-2xl  '>
					{relatedCompanies &&
						relatedCompanies.map((item, index) => {
							return (
								<li key={index}>
									{/* <ButtonDropDown key={index} className='h-12 w-full p-2 !font-h3-font-family' title={item.text}  />  */}

									<CompanyItem item={item} className='mx-1'></CompanyItem>
								</li>
							);
						})}
				</ul>
			) : (
				<></>
			)}
		</>
	);
};
