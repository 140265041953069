import * as yup from 'yup';
import { CollaboratorsNames, GenericsNames } from '../../../../translations';
import { regexName } from '../../../utils';

export const CollaboratorsSchema = yup.object().shape({
	name: yup
		.string('')
		.required(CollaboratorsNames.VALIDATOR_NAME_REQUIRED)
		.max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
		.matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
	lastName: yup
		.string('')
		.required(CollaboratorsNames.VALIDATOR_LASTNAME_REQUIRED)
		.min(1, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
		.max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
		.matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS),
	surName: yup
		.string('')
		.max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
		.matches(regexName, GenericsNames.VALIDATOR_SPECIAL_CHARACTERS)
		.nullable(),
	email: yup
		.string('')
		.required(CollaboratorsNames.VALIDATOR_EMAIL_REQUIRED)
		.max(100, CollaboratorsNames.VALIDATOR_NAME_REQUIRED_SIZE)
		.matches(/^[a-zA-Z0-9._%+-]+(?:\.[a-zA-Z0-9._%+-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, CollaboratorsNames.VALIDATOR_EMAIL),
	departmentId: yup
		.string('')
		.required(CollaboratorsNames.VALIDATOR_DEPARTMENT_REQUIRED)
		.nullable(),
	positionId: yup.string('').nullable(),
	workCenterId: yup.string('').nullable(),
	gender: yup.string(''),
	areaId: yup.string('').nullable(),
	taxId: yup //taxID: DataTypes.STRING, //RFC
		.string()
		.required(CollaboratorsNames.VALIDATOR_TAX_ID_REQUIRED)
		.max(13, CollaboratorsNames.VALIDATOR_TAX_ID_SIZE)
		.matches(
			/^([A-ZÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/,
			'RFC inválido'
		),
});
