import { GET_COMPANY, GET_COMPANY_ERROR, GET_COMPANY_SUCCESS } from '../../business/constants'
import { useContext, useEffect, useState } from 'react'
import { IsNullOrEmpty } from '../../utils/utilsService'
import { StoreContext } from '../../business/Provider'
import { Toast } from '../../Components/toast';
import axios from 'axios'
import { getLocalAccessToken } from '../../Auth/auth/token'
import { sortBy } from 'lodash'
import useApi from '../api';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Auth/auth/store/userSlice';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const useCompanies = () => {
  const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/companies`
  const { genericApiCall } = useApi();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [companies, setCompanies] = useState(undefined)
  const [currentCompany, setCurrentCompany] = useState(undefined)
  const [companiesOptions, setCompaniesOptions] = useState(undefined)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const { dispatchCompany } = useContext(StoreContext)

  // const getCollaboratorOptions = (companyResponse) => {
  //   const activecompany = companyResponse.filter((object) => {
  //     return IsNullOrEmpty(object.deleteBy)
  //   })

  //   return sortBy(activecompany, [
  //     function (item) { return item.name; },
  //     function (item) { return item.lastName; },
  //     function (item) { return item.surName; },
  //   ]).map((item) => ({ label: `${item.name} ${item.lastName} ${item.surName}`, value: item.id }))
  // }

  const postCompanies = async (Companies) => {
    const accessToken = getLocalAccessToken()
    return axios.post(baseUrl, Companies, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
  }

  // const getCompanies = async () => {
  //   const accessToken = getLocalAccessToken()
  //   return axios.get(baseUrl, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   })
  // }
  const putCompanies = async (Companies) => {
    const accessToken = getLocalAccessToken()
    return axios.put(baseUrl, Companies, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
  }

  const deleteCompanies = async (Companies) => {
    const accessToken = getLocalAccessToken()
    return axios.delete(baseUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: Companies
    })
  }
  const getCurrentCompany = async () => {
    try {

      if (dispatchCompany === undefined) {
        return
      }
      setLoadingCompanies(true)
      dispatchCompany({ type: GET_COMPANY })

      // header noCache
      const headers = {
        'Cache-Control': 'no-cache',
      }
      const response = await genericApiCall(baseUrl + '/CurrentCompany', 'GET', {}, {}, headers)
      const { company } = response.data.data
      dispatchCompany({ type: GET_COMPANY_SUCCESS, payload: company })
      setCurrentCompany(company)
      setLoadingCompanies(false)

    } catch (error) {
      dispatchCompany({ type: GET_COMPANY_ERROR, payload: error })
      Toast('warning', error.message, "", error)
      setCurrentCompany(null)
      setLoadingCompanies(false)
      // redirect to error page
      navigate('/error',{state: { 
        error: error?.data?.error || error?.message,
        code: error.status
      }} )
      
    }

  }

  const getRelatedCompanies = async (attemp = 1) => {



    setLoadingCompanies(true)
    const headers = {
      'Cache-Control': 'no-cache',
    }
    return genericApiCall(baseUrl + '/CurrentCompany/RelatedCompanies', 'GET', {}, {}, headers).then(response => {
      setLoadingCompanies(false)
      return response.data.data;
    })
      .catch(error => {
        if (attemp < 3) {
          return getRelatedCompanies(attemp + 1)
        } else {
          setLoadingCompanies(false)
          Toast('warning', error.message, "", error)
        }
      })



  }
  const { instance } = useMsal()
  const signOff = async () => {
    console.log('signOff')
    const baseUrlData = `${window.REACT_APP_EVALUA_API_URI}/session`
    return genericApiCall(baseUrlData, 'DELETE', {})
      .then(response => {
        consooe.log('signOff response', response)
        if (response.status === 200) {
          return dispatch(logoutUser(instance))
        } else {
          Toast('warning', error.message, "", error)
        }
      })
      .catch(error => {
        console.log('error Companies-> catch', error)
        return dispatch(logoutUser(instance))
      });
  }
  const getAvailableCompaniesForRelation = async () => {
    try {

      setLoadingCompanies(true)
      const headers = {
        'Cache-Control': 'no-cache',
      }
      const response = await genericApiCall(baseUrl + '/CurrentCompany/RelatedCompanies/AvailableCompanies', 'GET', {}, {}, headers)

      setLoadingCompanies(false)
      return response.data.data;
    } catch (error) {
      setLoadingCompanies(false)
      Toast('warning', error.message, "", error)
      return { companiesRelated: [] };
    }
  }

  const postAssociateCompanies = async (Companies) => {
    const accessToken = getLocalAccessToken()
    return axios.post(baseUrl + '/CurrentCompany/RelatedCompanies', Companies, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
  }
  const deleteAssociateCompanies = async (Companies) => {
    const response = await genericApiCall(baseUrl + '/CurrentCompany/RelatedCompanies', 'DELETE', Companies)
    return response.data.data;
  }

  useEffect(() => {
    // if (!companies) {
    //   getCompaniesList()

    // }
    // if path is different to /error
    if (currentCompany === undefined && window.location.pathname !== '/error') {
      getCurrentCompany()
    }
  }, [currentCompany])

  return {
    setCompanies,
    companies,
    currentCompany,
    loadingCompanies,
    postCompanies,
    putCompanies,
    deleteCompanies,
    companiesOptions,
    getRelatedCompanies,
    getCurrentCompany,
    getAvailableCompaniesForRelation,
    postAssociateCompanies,
    deleteAssociateCompanies
  }
}
export default useCompanies
