import {
	CollaboratorsNames,
	GenericsNames,
	SurveyNames,
} from '../../translations';
import React, { useContext, useEffect, useState } from 'react';
import {
	firstIndex,
	removeDuplicateBySingleKey,
} from '../../utils/arrayService';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import CollaboratorSelectionDialog from './collaboratorSelectionDialog';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { Toast } from '../../Components/toast';
import { LoadingContext } from '../../context/LoadingContext';
import SurveyWizard from './survayWizard';
import { WizardDialog } from './WizardDialog';
import { searchObj } from '../../utils/Constanst/survey/constants';
import useCollaborators from '../../hooks/Collaborator/useCollaborators';

import { useFormik } from 'formik';
import usePosition from '../../hooks/position/usePosition';
import useWorkCenters from '../../hooks/WorkCenters/useWorkCenters';
import useZone from '../../hooks/zone/useZone';
import { StoreContext } from '../../business/Provider';
import useDepartments from '../../hooks/Departaments/useDepartaments';
import { downloadFile, excelToJsonPortal } from '../../utils/utils';
import { EntityToExport, ToExcel } from '../../utils/exportService';

export const SurveyDialog = ({
	title,
	open,
	onClose,
	formik,
	activeStep,
	setActiveStep,
	selectedCollaborators,
	setSelectedCollaborators,
	collaboratorsSearh,
	setColloboratorSearch,
}) => {
	const { t } = useCustomTranslation();
	const [openAddCollaborator, setOpenCollaborator] = useState(false);
	const [isImportCollaborator, setIsImportCollaborators] = useState(false);
	const { setLoading } = useContext(LoadingContext);
	const {
		areaState,
		positionState,
		departmentState,
		workCenterState,
		collaboratorState,
		companyState,
		dispatchLoading,
	} = useContext(StoreContext);

	const { getZones } = useZone();
	const { getDepartment } = useDepartments();
	const { getPositions } = usePosition();
	const { getWorkCenters } = useWorkCenters();
	const { getCollaborators, getCollaboratorsByTaxIds } = useCollaborators();

	useEffect(()=>{
		if(isImportCollaborator && collaboratorState?.filteredCollaborators?.length > 0) 
		{
			let collaborators = [
				...formik.values.collaborators,
				...collaboratorState.filteredCollaborators,
			];
			let collaboratorsWithOutDupes = removeDuplicateBySingleKey(
				'collaboratorId',
				collaborators
			);
			formik.setFieldValue('collaborators', collaboratorsWithOutDupes);
			setIsImportCollaborators(false);
			setLoading(false);
		}
	},[isImportCollaborator,collaboratorState])

	useEffect(() => {
		if (open === true) {
			getZones();
			getDepartment();
			getPositions();
			getWorkCenters();
			getCollaborators();
		}
	}, [open]);
	const statusOptions = [
		{ value: 'A', label: getTranslation(t, CollaboratorsNames.HIGH) },
		{ value: 'R', label: getTranslation(t, CollaboratorsNames.REENTRY) },
	];
	const steps = [
		{
			label: getTranslation(t, SurveyNames.STEP_1),
			title: getTranslation(t, SurveyNames.IDENTIFICATION_FREQUENCY),
			validationFields: ['number', 'name', 'startDate', 'endDate'],
		},
		{
			label: getTranslation(t, SurveyNames.STEP_2),
			title: getTranslation(t, SurveyNames.COLLABORATOR_LIST),
		},
	];

	const addCollaborators = async () => {
		if (selectedCollaborators && selectedCollaborators.length > 0) {
			//Concatenate Arrays
			let collaborators = [
				...formik.values.collaborators,
				...selectedCollaborators,
			];
			let collaboratorsWithOutDupes = removeDuplicateBySingleKey(
				'collaboratorId',
				collaborators
			);
			formik.setFieldValue('collaborators', collaboratorsWithOutDupes);
		}
		toggleAddCollaboratorDialog(false);
	};

	const downloadExcelColab = async () => {
		await downloadFile(99);
    }

    const importExcelColab = (e) => {
		const fileData = e.target.files[0];
		const reader = new FileReader();
		// check if is a correct file extension
		if (
			fileData.type ===
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
			fileData.type === 'application/vnd.ms-excel'
		) {
			reader.readAsDataURL(fileData);
			reader.onload = async () => {
				try{
					setLoading(true);
					const collaborators = excelToJsonPortal(reader.result.split(',')[1]);
					const taxIds = collaborators.Colaboradores.map(obj => obj.RFC);
					const errors = await getCollaboratorsByTaxIds(taxIds);
					const toExport = errors[0].map((error)=>{
						return { 
							rfc: error,
							errors: {
								invalid: [], 
								obligatory: [], 
								notExist: [getTranslation(t, GenericsNames.IMPORT_COLLAB_ERROR_MESSAGE)]
							}
						}
					})
					const printObj = {
						name : getTranslation(t, GenericsNames.IMPORT_COLLAB_NAME),
						bitacoraImport: errors[1],
						bitacoraNotImport: errors[0].length,
						data: toExport,
						rfc: companyState.companies.taxID || "",
						alias: companyState.companies.alias || "",
						company: companyState.companies.name || "",
					}
					setIsImportCollaborators(true);
					return ToExcel(printObj, EntityToExport.BITACORA, dispatchLoading);
				}catch{
					setLoading(false);
					Toast('warning', 'Es necesario seleccionar un archivo valido', '');
					return false;
				}
			};
			
		}
    };

	const formikSearch = useFormik({
		initialValues: { ...searchObj },
		//validationSchema: searchSchema,
	});
	const toggleAddCollaboratorDialog = (flag) => {
		setOpenCollaborator(flag);
	};
	const onRemoveCollaborator = (row) => {
		let collaborators = formik.values.collaborators;
		const toDeleteCollaborators =
			formik.values.toDeleteCollaborators == undefined
				? []
				: formik.values.toDeleteCollaborators;
		const index = firstIndex(
			'collaboratorId',
			row.collaboratorId,
			collaborators
		);
		if (index > -1) {
			toDeleteCollaborators.push(collaborators.splice(index, 1)[0]); // 2nd parameter means remove one item only
			formik.setFieldValue('toDeleteCollaborators', toDeleteCollaborators);
		}
		formik.setFieldValue('collaborators', collaborators);
		formikSearch.resetForm();
	};
	const handleNext = async (e) => {
		e.preventDefault();
		//Get All Errors
		const validationErrors = await formik.validateForm();

		if (Object.keys(validationErrors).length > 0) {
			//Filter the error that belongs to the step.
			if (steps[activeStep].validationFields) {
				//Execute Touch for validation fields.
				let validationFields = steps[activeStep].validationFields;
				validationFields.forEach((element) =>
					formik.setFieldTouched(element, true)
				);
				//formik.setTouched(setNestedObjectValues(validationErrors, true));
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	return (
		<>
			<CollaboratorSelectionDialog
				open={openAddCollaborator}
				onAdCollaborators={addCollaborators}
				onClose={() => toggleAddCollaboratorDialog(false)}
				rows={collaboratorsSearh}
				setRows={setColloboratorSearch}
				formik={formikSearch}
				selectedCollaborators={selectedCollaborators ?? []}
				setSelectedCollaborators={(e) => setSelectedCollaborators(e.value)}
				zones={areaState.areasOptions}
				departments={departmentState.departmentsOptions}
				positions={positionState.positionOptions}
				workCenters={workCenterState.workCenterOptions}
				statuses={statusOptions}
				collaborators={collaboratorState.collaboratorsOptions}
				collaboratorsListAssign={
					formik.values.collaborators
				}></CollaboratorSelectionDialog>
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold'>
						{title.toUpperCase()}
					</p>
				}
				visible={open}
				modal
				style={{ width: '70vw' }}
				onHide={() => onClose()}>
				<WizardDialog
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					steps={steps}
					formik={formik}
					onNext={handleNext}
					onBack={handleBack}
					onClose={() => onClose()}
					handleBack={handleBack}
					handleNext={handleNext}
					open={open}
					title={title}>
					<SurveyWizard
						downloadExcelColab={downloadExcelColab}
						importExcelColab={importExcelColab}
						edit={title === getTranslation(t, SurveyNames.EDIT_SURVEY)}
						formik={formik}
						activeStep={activeStep}
						onClickAddCollaborators={() => toggleAddCollaboratorDialog(true)}
						onRemoveCollaborator={onRemoveCollaborator}
					/>
				</WizardDialog>
			</Dialog>
		</>
	);
};

SurveyDialog.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	formik: PropTypes.object.isRequired,
	activeStep: PropTypes.number,
	setActiveStep: PropTypes.func,
	selectedCollaborators: PropTypes.array,
	setSelectedCollaborators: PropTypes.func,
	collaboratorsSearh: PropTypes.array,
	setColloboratorSearch: PropTypes.func,
};
