import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const FinallyComponent = () => {
	const renderHeader = () => {
		return <div className='flex w-full h-16 bg-secondary rounded-t-lg'></div>;
	};
	return (
		<div className='flex h-3/5 w-3/5 bg-white rounded-md shadow-2xl flex-col'>
			{renderHeader()}
			<div className='flex w-full h-full  rounded-b-lg flex-col'>
				<div className='flex w-full h-2/4 flex-col justify-center p-1 items-center content-center'>
					<div className='flex w-36 h-36 bg-red-600 items-center content-center rounded-full justify-center  bg-[#248C00]'>
						<FontAwesomeIcon
							className='!text-white w-2/3 h-2/3'
							size='2xl'
							icon={faCheck}
							title={'check'}
						/>
					</div>
					<h1>{'Gracias por contestar la evaluación.'}</h1>
				</div>
				<div className='flex w-full h-1/4  p-4'>
					<div className='flex w-full h-full flex-col !bg-[#cae6fc]  rounded-md '>
						<div className='flex w-full h-1/2 content-center items-center justify-center'>
							<h6 className=''>{'Registro realizado exitosamente.'}</h6>
						</div>
						<div className=' w-full h-1/2 content-center items-center justify-center'>
							<p className=' text-sm text-center'>
								{
									' Muchas gracias por su colaboración. Sus respuestas han sido enviadas y su participación en la evaluación se ha registrado satisfactoriamente.'
								}
							</p>
						</div>
					</div>
				</div>
				<div className='flex w-full h-1/4 justify-center items-end content-center  rounded-b-lg'>
					<p className=' text-sm text-center mb-4'>
						{
							'Este mensaje es para confirmar que ha completado el cuestionario. Desde este momento puede cerrar la ventana.'
						}
					</p>
				</div>
			</div>
		</div>
	);
};
