import React from 'react';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { faSearch, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolBarNames } from '../../translations';
import { CustomIconButton } from '../Buttons/CustomIconButton';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { FilterMatchMode } from 'primereact/api';
import { BsFiletypeXls, BsFiletypePdf } from 'react-icons/bs';
import { TbTableExport } from "react-icons/tb";

export const ToolBarThreeColumns = ({
	placeholder,
	onChange,
	value,
	leftChildren,
	rightChildren,
	onExcelClick,
	onPDFClick,
	onExcelImportFormatClick,
	_onPrintClick,
	_size,
	setValue,
	setFilters,
}) => {
	const { t } = useCustomTranslation();

	const renderEmptyValueIcon = () => {
		return value !== '' ? (
			<FontAwesomeIcon
				className='pi mr-4 hover:bg-slate-200'
				onClick={() => {
					setValue('');
					setFilters({
						global: { value: '', matchMode: FilterMatchMode.CONTAINS },
					});
				}}
				icon={faXmark}
			/>
		) : null;
	};
	return (
		<div className='w-full flex border border-border bg-white'>
			{leftChildren ? (
				<div className={`flex justify-start `}>{leftChildren}</div>
			) : null}
			<div className={`bg-white flex flex-grow border-x border-border`}>
				<span className='p-input-icon-left w-full h-full !rounded-none'>
					<FontAwesomeIcon className='pi' icon={faSearch} />
					<InputText
						className='w-full h-full !rounded-none !border-0'
						value={value}
						onChange={onChange}
						placeholder={placeholder}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								onChange(e);
							}
						}}
					/>
				</span>
				{setValue !== undefined ? (
					<div className='flex w-1/12 justify-end content-end items-center'>
						{renderEmptyValueIcon()}
					</div>
				) : null}
			</div>
			<div className={`bg-white flex justify-end  p-1`}>
				{rightChildren}
				{onExcelImportFormatClick? 
					<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.EXCELTOIMPORT)}
					onClick={onExcelImportFormatClick}
					className='!m-1'>
					<TbTableExport className='text-xl' />
					</CustomIconButton>
					:
					<></>
				}
				{
					<CustomIconButton
						toolTip={getTranslation(t, ToolBarNames.EXCEL)}
						onClick={onExcelClick}
						className='!m-1'>
						<BsFiletypeXls className='text-xl' />
					</CustomIconButton>
				}
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.PDF)}
					onClick={onPDFClick}
					className='!m-1'>
					<BsFiletypePdf className='text-xl' />
				</CustomIconButton>
			</div>
		</div>
	);
};

export default ToolBarThreeColumns;

ToolBarThreeColumns.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	onExcelClick: PropTypes.func,
	onPDFClick: PropTypes.func,
	onPrintClick: PropTypes.func,
	rightChildren: PropTypes.node,
	leftChildren: PropTypes.node,
	size: PropTypes.shape({
		left: PropTypes.string,
		middle: PropTypes.string,
		right: PropTypes.string,
	}),
};

ToolBarThreeColumns.defaultProps = {
	onExcelClick: () => {},
	onPDFClick: () => {},
	onPrintClick: () => {},
	rightChildren: null,
	leftChildren: <div></div>,
	size: {
		middle: `col-span-4  md:col-span-4 lg:col-span-6 2xl:col-span-6`,
		left: `col-span-3  md:col-span-3 lg:col-span-2 2xl:col-span-2`,
		right: `col-span-3  md:col-span-3 lg:col-span-2 2xl:col-span-2`,
	},
};
