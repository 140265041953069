import { Column } from 'primereact/column';
import React from 'react';
import { EvaluaTable } from '../../../Components/Table/EvaluaTable';
import {
	CollaboratorsNames,
	GenericsNames,
	QuestionaryCollaboratorsNames,
	SurveyCollaboratorsNames,
	SurveyNames,
} from '../../../translations';
import { TableButton } from '../../../Components/Buttons/TableButtons';
import {
	faEnvelope,
	faEye,
	faShare,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
export const TableComponent = ({
	dataTable,
	renderHeader,
	loadingTableState,
	filters,
	getTranslation,
	t,
	guideId,
	toSend,
	goToCollaboratorQuestionary,
	sort,
	setSort,
	handlerSendEmail,
	handlerCopy,
	setRowDataValue,
	setToSend,
	setShowAlert,
}) => {
	const BAJA = 'B';

	const translation = (translate) => {
		const translateResult = getTranslation(t, translate);
		return translateResult;
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<>
				{rowData.answerDate !== null ? (
					<TableButton
						onClick={() => {
							goToCollaboratorQuestionary(rowData);
						}}
						icon={faEye}
						toolTip={translation(GenericsNames.VIEW)}
					/>
				) : null}
				{rowData.answerDate === null ? (
					<TableButton
						onClick={() => {
							handlerSendEmail(rowData);
						}}
						icon={faEnvelope}
						toolTip={
							rowData.status === BAJA
								? translation(SurveyCollaboratorsNames.FIRED)
								: translation(GenericsNames.SEND_EMAIL)
						}
						disabled={rowData.status === BAJA}
					/>
				) : null}
				{rowData.answerDate === null ? (
					<TableButton
						onClick={() => handlerCopy(rowData)}
						icon={faShare}
						toolTip={
							rowData.status === BAJA
								? translation(SurveyCollaboratorsNames.FIRED)
								: translation(GenericsNames.SHARE)
						}
						disabled={rowData.status === BAJA}
					/>
				) : null}
				{rowData.cancelDate === null ? (
					<TableButton
						onClick={() => {
							setRowDataValue(rowData);
							setShowAlert(true);
						}}
						icon={faTrashAlt}
						toolTip={translation(GenericsNames.DELETE)}
					/>
				) : null}
			</>
		);
	};
	function statusTemplate(e) {
		const cancelStatus =
			e.cancelDate === null
				? translation(QuestionaryCollaboratorsNames.COMPLETED)
				: translation(QuestionaryCollaboratorsNames.CANCEL);
		const answerStatus =
			e.answerDate === null
				? translation(QuestionaryCollaboratorsNames.RESPONDED)
				: cancelStatus;
		const status =
			e.sentDate === null
				? translation(QuestionaryCollaboratorsNames.SEND)
				: answerStatus;
		return <div>{status}</div>;
	}

	return (
		<EvaluaTable
			value={dataTable}
			sortField='name'
			header={renderHeader}
			loading={loadingTableState}
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={[
				'surveyId',
				'name',
				'departmentName',
				'workcenterName',
				'email',
				'status',
				'GeneralResult',
			]}
			emptyMessage={translation(CollaboratorsNames.GRID_EMPTY)}
			currentPageReportTemplate={translation(
				CollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE
			)}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: 'calc(100vh - 15.3rem)', width: 'calc(100vw - 92px)' }}
			selection={toSend}
			selectionMode='checkbox'
			onSelectionChange={(e) => setToSend(e.value)}
			isDataSelectable={(rowData) => rowData.data.answerDate === null}
			setSort={setSort}
			sort={sort}>
			<Column selectionMode='multiple' style={{ minWidth: '10px' }}></Column>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='SurveyCollaboratorCode'
				header={getTranslation(t, SurveyCollaboratorsNames.QUESTIONNAIRE_ID)}
				sortable
			/>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '200px' }}
				body={(e) => {
					return `${e.name} ${e.lastName} ${
						e.surName === null ? '' : e.surName
					}`;
				}}
				field='name'
				header={translation(SurveyCollaboratorsNames.COLLABORATOR_NAME)}
				sortable
			/>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='departmentName'
				header={translation(SurveyCollaboratorsNames.DEPARTMENT)}
				sortable
			/>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='workcenterName'
				header={translation(SurveyCollaboratorsNames.WORK_CENTER)}
				sortable
			/>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='email'
				header={translation(SurveyCollaboratorsNames.EMAIL)}
				sortable
			/>
			<Column
				headerClassName='hover:!text-success !border-border !border-r'
				style={{ flexGrow: 1, flexBasis: '50px' }}
				field='status'
				body={(e) => statusTemplate(e)}
				header={translation(SurveyCollaboratorsNames.STATUS)}
				sortable
			/>
			{guideId === '1' ||
			guideId === '5' ||
			guideId === '6' ||
			guideId === '7' ? null : (
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '50px' }}
					field='GeneralResult'
					header={translation(SurveyCollaboratorsNames.GENERALRESULT)}
					sortable
				/>
			)}
			{guideId === '1' && ( //esta columna solo debe mostrarse para la gía 1
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '50px' }}
					field='response'
					header={translation(SurveyNames.RESPONSE)}
					align='center'
					sortable
				/>
			)}

			<Column
				style={{ flexGrow: 1, flexBasis: '50px' }}
				header={translation(SurveyNames.GRID_ACTION)} //Este es el que funciona
				headerStyle={{ width: '4rem', textAlign: 'center' }}
				bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
				body={actionBodyTemplate}
			/>
		</EvaluaTable>
	);
};

TableComponent.propTypes = {
	dataTable: PropTypes.array.isRequired,
	renderHeader: PropTypes.element.isRequired,
	loadingTableState: PropTypes.bool.isRequired,
	filters: PropTypes.object.isRequired,
	getTranslation: PropTypes.func.isRequired,
	t: PropTypes.any.isRequired,
	guideId: PropTypes.number.isRequired,
	toSend: PropTypes.array.isRequired,
	goToCollaboratorQuestionary: PropTypes.func.isRequired,
	sort: PropTypes.object.isRequired,
	setSort: PropTypes.func.isRequired,
	handlerSendEmail: PropTypes.func.isRequired,
	handlerCopy: PropTypes.func.isRequired,
	setRowDataValue: PropTypes.func.isRequired,
	setToSend: PropTypes.func.isRequired,
	setShowAlert: PropTypes.func.isRequired,
};
