import './TabViewCompany.css';

//imports
import React, { useContext, useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';

//components
import CompanyRelatedList from './Components/companyRelatedList';
import CompanyForm from './Components/companyForm';
import { ConfirmDialogBeforeSave } from '../../Components/Dialogs/Confirm';
import { CreateDialog } from '../../Components/Dialogs/CreateDialog';
import IntegrationCompany from './Components/IntegrationCompany';
import PageForm from '../../Components/Dialogs/PageForm';
import { Toast } from '../../Components/toast';

//hooks
import {
	CompanyNames,
	GenericsNames,
	NavigationNames,
	ToolBarNames,
} from '../../translations';

import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

import useReload from '../../hooks/useReload';
import useCompanies from '../../hooks/Company/useCompany';

//context
import { CompanyRelatedContext } from '../../context/CompanyRelatedContext';
import { LoadingContext } from '../../context/LoadingContext';
import { NavigationContext } from '../../context/navigationContext';

//utils
import CompanyObject from '../../utils/Constanst/FormikConstanst/Company/CompanyObject';
import CompanySchema from '../../utils/Constanst/FormikConstanst/Company/CompanySchema';
import CompanyRelatedObject from '../../utils/Constanst/FormikConstanst/CompanyRelated/CompanyRelated';
import { IsNullOrEmpty } from '../../utils';
import { SUCCESS } from '../../utils/Constanst/ResponseStatus';
const Company = (props) => {
	//context implementation
	const navigation = useContext(NavigationContext);
	const loadingObj = useContext(LoadingContext);
	const { relatedCompanies, reload, updateRelatedCompanies } = useContext(
		CompanyRelatedContext
	);

	//hooks implementation
	const { t } = useCustomTranslation();

	const {
		currentCompany,
		postAssociateCompanies,
		putCompanies,
		getCurrentCompany,
		getAvailableCompaniesForRelation,
		getRelatedCompanies,
		deleteAssociateCompanies,
	} = useCompanies();

	//state implementation

	const [zipCodeFound, setZipCodeFound] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [open, setOpen] = useState(false);
	const [companiesFilter, setRelatedCompaniesFilter] = useState([]);
	const [disabledOnSave, setDisabledOnSave] = useState(false);
	const [companiesAll, setRelatedCompaniesAll] = useState([]);
	const [associatedCompaniesFilter, setAssociatedCompaniesFilter] =
		useState(undefined);
	const [associatedCompaniesAll, setAssociatedCompaniesAll] =
		useState(undefined);
	const [company, setCompany] = useState(null);
	const [confirmRemoveRelated, setConfirmRemoveRelated] = useState(false);
	const [addRelatedCompany, setAddRelatedCompany] = useState(undefined);

	const setInternalRelatedCompanies = async () => {
		setAssociatedCompaniesFilter(relatedCompanies);
		setAssociatedCompaniesAll(relatedCompanies);
	};

	useEffect(async () => {
		loadingObj.setLoading(true);
		setCompany(currentCompany);
		if (currentCompany) {
			setZipCodeFound(true);
			formik.setValues(currentCompany);
			loadingObj.setLoading(false);
		}
	}, [currentCompany]);

	useEffect(async () => {
		try {
			if (!associatedCompaniesFilter || reload) {
				setInternalRelatedCompanies();
			}
		} catch (error) {
			loadingObj.setLoading(false);
		}
	}, [associatedCompaniesFilter, relatedCompanies, reload]);

	/** Reload **/
	useReload([getRelatedCompanies, getCurrentCompany]);

	const getCompanyFormData = (values) => {
		const formData = new FormData();
		formData.append('id', values.id);
		formData.append('name', values.name);
		formData.append('alias', values.alias);
		formData.append('zipCode', values.zipCode);
		formData.append('state', values.state);
		formData.append('municipality', values.municipality);
		formData.append('street', values.street);
		formData.append('exteriorNumber', values.exteriorNumber);
		formData.append('interiorNumber', values.interiorNumber);
		formData.append('suburb', values.suburb);
		formData.append('logoFile', values.logoFile);
		formData.append('taxID', values.taxID);
		formData.append('rfc', values.taxID);
		formData.append('clearLogo', values.clearLogo);
		return formData;
	};

	const addOrUpdateCompany = async (values) => {
		try {
			if (Object.entries(formik.errors).length === 0) {
				loadingObj.setLoading(true);
				const response = await putCompanies(getCompanyFormData(values));
				if (response !== undefined) {
					loadingObj.setLoading(false);
					if (response.data.status === SUCCESS) {
						await getCurrentCompany();
						Toast('success', getTranslation(t, CompanyNames.SUCCESS_ADD));
					} else {
						Toast(
							'warning',
							getTranslation(t, CompanyNames.ERROR_ADD),
							response.data.data.message
						);
						return undefined;
					}
				}
			}
		} catch (err) {
			console.log(err);
			loadingObj.setLoading(false);
		}
	};

	const formik = useFormik({
		initialValues: { ...CompanyObject },
		validationSchema: CompanySchema,
	});

	/* Checking if the formik values are equal to the company values. If they are not equal, then it sets
	the valid data to true. If they are equal, then it sets the valid data to false. */
	useEffect(() => {
		if (company !== undefined && company !== null) {
			if (JSON.stringify(company) !== JSON.stringify(formik.values)) {
				navigation.setValidData(true);
			} else {
				navigation.setValidData(false);
			}
		}
	}, [formik.values, company]);

	//#region Popup
	const showCompaniesRelated = async () => {
		setOpen(!open);
		loadingObj.setLoading(true);
		let companyAvailableForRelations = await getAvailableCompaniesForRelation();

		const results = companyAvailableForRelations.companiesRelated.filter(
			(ir) =>
				!associatedCompaniesAll.some(
					(ia) =>
						ia.externalInstanceID.toLowerCase() === ir.instanceId.toLowerCase()
				)
		);

		setRelatedCompaniesAll(results);
		setRelatedCompaniesFilter(results);
		loadingObj.setLoading(false);
	};

	const formikCompanyRelated = useFormik({
		initialValues: { ...CompanyRelatedObject },
	});

	const onClose = () => {
		setOpen(!open);
	};

	const onAddCompanyRelated = async () => {
		let companyCopy = companiesFilter.filter((company) => {
			return company.selected;
		});
		try {
			let response = await postAssociateCompanies(companyCopy);
			let copy = relatedCompanies;
			for (const item of response.data.data) {
				copy.push(item);
			}
			await updateRelatedCompanies(copy);

			onClose();
			setDisabledOnSave(false);
		} catch (error) {
			console.log(error);
			Toast('error', getTranslation(t, CompanyNames.ERROR_ADD));
			onClose();
		}
	};

	const onRemoveCompany = async (company) => {
		try {
			loadingObj.setLoading(true);
			await deleteAssociateCompanies(company);
			// let companyCopy = associatedCompaniesFilter.filter((item) => {
			// 	return item.id !== company.id;
			// });
			let allCompaniesCopy = associatedCompaniesAll.filter((item) => {
				return item.id !== company.id;
			});

			await updateRelatedCompanies(allCompaniesCopy);
			loadingObj.setLoading(false);
			//await setReload(true);
		} catch (error) {
			loadingObj.setLoading(false);
			console.log(error);
			Toast('error', getTranslation(t, CompanyNames.ERROR_ADD));
		}
	};

	const beforeRemoveCompany = (company) => {
		setAddRelatedCompany(company);
		setConfirmRemoveRelated(true);
	};

	const closeDialog = () => {
		setAddRelatedCompany(undefined);
		setConfirmRemoveRelated(false);
	};

	const onSearchAssociation = async (searchValue) => {
		if (!IsNullOrEmpty(searchValue)) {
			let companyCopy = associatedCompaniesAll.filter((company) => {
				return (
					company.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					company.alias.toLowerCase().includes(searchValue.toLowerCase())
				);
			});
			setAssociatedCompaniesFilter(companyCopy);
		} else {
			setAssociatedCompaniesFilter(associatedCompaniesAll);
		}
	};

	const onSearchNewRelation = (searchValue) => {
		if (!IsNullOrEmpty(searchValue)) {
			let companyCopy = companiesAll.filter((company) => {
				return (
					company.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					company.alias.toLowerCase().includes(searchValue.toLowerCase())
				);
			});
			setRelatedCompaniesFilter(companyCopy);
		} else {
			setRelatedCompaniesFilter(companiesAll);
		}
	};

	const saveBeforeClose = async () => {
		try {
			loadingObj.setLoading(true);
			const response = await putCompanies(getCompanyFormData(formik.values));
			if (response !== undefined) {
				loadingObj.setLoading(false);
				if (response.data.status === SUCCESS) {
					await getCurrentCompany();
					Toast('success', getTranslation(t, CompanyNames.SUCCESS_ADD));
					navigation.removeTap(navigation.urlStage);
				} else {
					Toast(
						'warning',
						getTranslation(t, CompanyNames.ERROR_ADD),
						response.data.data.message
					);
					return undefined;
				}
			}
		} catch (err) {
			console.log(err);
			loadingObj.setLoading(false);
		}
	};

	const renderCompany = () => {
		return (
			<div className='flex w-full h-full flex-row '>
				<div className='flex  w-[60%] h-full '>
					<PageForm
						formik={formik}
						onClose={() => {
							console.log('On Close');
						}}
						title={'TODO'}
						textCancel={getTranslation(t, CompanyNames.CANCEL)}
						textSave={getTranslation(t, CompanyNames.UPDATE)}
						onSave={addOrUpdateCompany}
						hidden={true}
						validateOnSaveFooter={false}>
						<CompanyForm
							formik={formik}
							zipCodeFound={zipCodeFound}
							setZipCodeFound={setZipCodeFound}
							t={t}
							//	getStatesCities={getStatesCities}
						/>
					</PageForm>
				</div>
				<div className='flex  w-[40%] h-full'></div>
			</div>
		);
	};

	const renderIntegration = () => {
		return (
			<IntegrationCompany
				key='IntegrationCompanyRelation'
				iNeedSearch={false}
				associatedCompanies={associatedCompaniesFilter}
				setAssociatedCompanies={setAssociatedCompaniesFilter}
				onClickAdd={showCompaniesRelated}
				onClickRemove={beforeRemoveCompany}
				onSearchAssociation={onSearchAssociation}></IntegrationCompany>
		);
	};

	const renderConfirmRemoveRelatedCompaniesDialog = () => (
		<ConfirmDialog
			visible={confirmRemoveRelated}
			onHide={() => closeDialog()}
			message={getTranslation(t, CompanyNames.CONFIRM_REMOVE_RELATION)}
			header='Confirmación'
			accept={async () => onRemoveCompany(addRelatedCompany)}
			acceptLabel='Aceptar'
			rejectLabel='Cancelar'
			/* A function that is called when the user clicks on the cancel button. */
			reject={() => closeDialog()}
		/>
	);

	const renderAddCompany = () => {
		let companyCopy = companiesFilter.filter((company) => {
			return company.selected;
		});
		return (
			<CreateDialog
				disabledSave={companyCopy.length === 0}
				textSave={getTranslation(t, GenericsNames.SAVE)}
				textCancel={getTranslation(t, GenericsNames.CANCEL)}
				open={open}
				title={getTranslation(t, CompanyNames.SELECT_COMPANY)}
				onClose={onClose}
				formik={formikCompanyRelated}
				onSave={onAddCompanyRelated}
				disabledOnSave={disabledOnSave}
				setDisabledOnSave={setDisabledOnSave}
				formComponet={
					<div className='flex-grow h-full'>
						<div className='flex w-full h-full'>
							<div className='flex w-full  h-full relative'>
								<>
									<CompanyRelatedList
										key='companyRelatedWindow'
										modalMode={true}
										companies={companiesFilter}
										setCompanies={setRelatedCompaniesFilter}
										onSearchChange={onSearchNewRelation}
										iNeedMessage
									/>
								</>
							</div>
						</div>
					</div>
				}
			/>
		);
	};
	//#endregion

	const tabs = [
		{
			title: getTranslation(t, ToolBarNames.COMPANY_DATA),
			content: renderCompany(),
			icon: 'pi pi-building',
		},
		{
			title: getTranslation(t, NavigationNames.INTEGRATION),
			content: renderIntegration(),
			icon: 'pi pi-share-alt',
		},
	];

	return (
		<div className='flex-grow h-full'>
			{renderAddCompany()}
			{renderConfirmRemoveRelatedCompaniesDialog()}
			<ConfirmDialogBeforeSave
				message={'¿Desea Guardar los Cambios Antes de Salir?'}
				actionAccept={() => saveBeforeClose()}
			/>
			<div className='flex w-full h-full '>
				<TabView
					activeIndex={activeTab}
					onTabChange={(e) => setActiveTab(e.index)}
					className='flex !w-full !h-full flex-col'>
					{tabs.map((tab) => {
						return (
							<TabPanel
								key={tab.title}
								header={tab.title}
								leftIcon={tab.icon}
								className='flex w-full h-full relative'
								headerClassName='!flex align-items-center !h-full w-[300px]'>
								{tab.content}
							</TabPanel>
						);
					})}
				</TabView>
			</div>
		</div>
	);
};

export default Company;
