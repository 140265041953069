import React, { useEffect, useState } from 'react';
import {
	faFileExcel,
	faFilePdf,
	faPrint,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';

import { faSearch, faSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolBarNames } from '../../translations';
import { CustomIconButton } from '../Buttons/CustomIconButton';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { FilterMatchMode } from 'primereact/api';
import { BsFiletypePdf, BsFiletypeXls } from 'react-icons/bs';

export const ToolBar = ({
	placeholder,
	onChange,
	value,
	children,
	onExcelClick,
	onPDFClick,
	//onPrintClick,
	setValue,
	setFilters,
}) => {
	const { t } = useCustomTranslation();
	const renderEmptyValueIcon = () => {
		return value !== '' ? (
			<FontAwesomeIcon
				className='pi mr-4 hover:bg-slate-200'
				onClick={() => {
					setValue('');
					setFilters({
						global: { value: '', matchMode: FilterMatchMode.CONTAINS },
					});
				}}
				icon={faXmark}
			/>
		) : null;
	};
	return (
		<div className='w-full flex border border-border bg-white'>
			<div className='flex justify-start flex-grow'>
				<span className='p-input-icon-left w-full h-full !rounded-none border-r border-border'>
					<FontAwesomeIcon className='pi' icon={faSearch} />
					<InputText
						className='w-full h-full !rounded-none !border-0'
						value={value}
						onChange={onChange}
						placeholder={placeholder}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								onChange(e);
							}
						}}
					/>
				</span>
				{setValue !== undefined ? (
					<div className='flex w-1/12 justify-end content-end items-center'>
						{renderEmptyValueIcon()}
					</div>
				) : null}
			</div>
			<div className='flex justify-end h-full p-1'>
				{children}
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.EXCEL)}
					onClick={onExcelClick}
					//icon={faFileExcel}
					className='!m-1'>
					<BsFiletypeXls className='text-xl' />
				</CustomIconButton>
				<CustomIconButton
					toolTip={getTranslation(t, ToolBarNames.PDF)}
					onClick={onPDFClick}
					//	icon={faFilePdf}
					className='!m-1'>
					<BsFiletypePdf className='text-xl' />
				</CustomIconButton>
				{/* <CustomIconButton
          toolTip={getTranslation(t,ToolBarNames.PRINT)}
          onClick={onPrintClick}
          icon={faPrint}
          className='!m-1'
        /> */}
			</div>
		</div>
	);
};

export default ToolBar;

ToolBar.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	onExcelClick: PropTypes.func,
	onPDFClick: PropTypes.func,
	//onPrintClick: PropTypes.func,
	children: PropTypes.node,
};
