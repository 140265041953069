import React, { useEffect, useState } from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from './../hooks/useTranslations';

import { ErrorComponentValidation } from '../Components/ErrorComponent';
import { FinallyComponent } from '../Components/FinallyComponent/index';
import { SurveyComponent } from '../Components/SurveysComponent/index';
import { SurveyNames, SurveysQuestionsNames } from './../translations/index';
import { Toast } from '../Components/toast';
import { ToastContainer } from 'react-toastify';
import { ValidationComponent } from '../Components/ValidationComponent/index';
import axios from 'axios';
import logo from '../../src/assets/img/LogotipoLanding.png';
import { NewSurveyComponent } from './../Components/SurveysComponent/newSurveyComponent';
import moment from 'moment';

export const SurveysLayout = () => {
	const { t } = useCustomTranslation();
	const [step, setStep] = useState(1);

	const [dataCollaborator, setDataCollaborator] = useState(null);
	const [isSurveyValid, setIsSurveyValid] = useState(false);
	const [messageSurveyValidation, setMessageSurveyValidation] = useState('');
	const [departmentsList, setDepartmentsList] = useState([]);
	const [showNewComponent, setShowNewComponent] = useState(false);

	let URLactual = window.location;
	let newUrlActual = URLactual.toString();
	let newUrlActualArray = newUrlActual.split('/');

	const getTokenFromUrl = () => {
		return newUrlActualArray.pop();
	};

	useEffect(() => {
		try {
			const hostProd = `${window.REACT_APP_EVALUA_API_URI}`;
			const token = getTokenFromUrl();

			const baseUrl = hostProd + '/surveysResponse';
			if (token !== null) {
				//	console.log('token', token);
				return axios

					.get(baseUrl + '/surveys/' + token, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((res) => {
						//Configure general info
						const { departments } = res.data.data;
						const { surveysInfo } = res.data.data;
						const {
							startDate,
							endDate,
							answerDate,
							cancelDate,
							surveyCancelDate,
						} = surveysInfo[0];
						if (surveysInfo[0] !== null) {
							const today = new Date();
							if (today < startDate) {
								setMessageSurveyValidation(
									getTranslation(
										t,
										SurveysQuestionsNames.SurveyMessageDontStart
									)
								);
								setIsSurveyValid(true);
							}
							//validate end date
							else if (today > endDate) {
								setMessageSurveyValidation(
									getTranslation(t, SurveysQuestionsNames.SurveyMessageFinish)
								);
								setIsSurveyValid(true);
							}
							//validate answer date
							else if (answerDate !== null) {
								setMessageSurveyValidation(
									getTranslation(t, SurveysQuestionsNames.SurveyMessageAnswered)
								);
								setIsSurveyValid(true);
							} else if (cancelDate !== null) {
								setMessageSurveyValidation(
									getTranslation(t, SurveysQuestionsNames.SurveyMessageCancel) +
										moment(cancelDate).format('DD/MM/YYYY')
								);
								setIsSurveyValid(true);
							} else if (surveyCancelDate !== null) {
								setMessageSurveyValidation(
									getTranslation(
										t,
										SurveysQuestionsNames.SurveyMessageDeleted
									) + moment(surveyCancelDate).format('DD/MM/YYYY')
								);
								setIsSurveyValid(true);
							} else {
								setIsSurveyValid(false);
							}
							setDataCollaborator(
								surveysInfo.length > 0 ? surveysInfo[0] : null
							);
							setDepartmentsList(departments.length > 0 ? departments : []);
							Toast(
								'success',
								getTranslation(
									t,
									SurveysQuestionsNames.SurveyMessageLoadSuccessfully
								)
							);
						} else {
							setMessageSurveyValidation('No se encontró la encuesta');
							setIsSurveyValid(true);
						}
					})
					.catch((err) => {
						if (err.response.status === 404) {
							setMessageSurveyValidation(
								'Esta liga no es valida, intenta de nuevo'
							);
							Toast(
								'warning',
								'Esta liga no es valida, intenta de nuevo',
								'por favor consulta con el administrador'
							);
						} else {
							const date = err.response.data.endDate;
							const dateSplit = err?.response?.data?.endDate
								? date.split('-')
								: [];
							const dateEnd =
								dateSplit.length > 0
									? dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0]
									: '';
							setMessageSurveyValidation(
								err?.response?.data?.endDate
									? getTranslation(
											t,
											'Ésta liga ha expirado el día ' +
												dateEnd +
												', favor de comunicarse con Recursos Humanos para solicitar una nueva liga.'
									  )
									: err.response.data.message
							);

							Toast('warning', getTranslation(t, err.response.data.message));
						}
						setIsSurveyValid(true);
					});
			}
		} catch (error) {}
	}, []);

	useEffect(() => {
		if (dataCollaborator !== null) {
			if (dataCollaborator?.answerDate !== null) {
				setStep(3);
				Toast(
					'warning',
					getTranslation(t, SurveysQuestionsNames.SurveyAnswered),
					getTranslation(t, SurveysQuestionsNames.SurveyError)
				);
			}
		}
	}, [dataCollaborator]);

	const renderValidationComponent = () => {
		return isSurveyValid ? (
			<ErrorComponentValidation
				messageSurveyValidation={messageSurveyValidation}
			/>
		) : (
			<ValidationComponent
				setStep={setStep}
				collaboratorData={dataCollaborator}
				departaments={departmentsList}
				showNewComponent={showNewComponent}
				setShowNewComponent={setShowNewComponent}
			/>
		);
	};

	const renderSurveyComponent = () => {
		return (
			<NewSurveyComponent
				setStep={setStep}
				collaboratorData={dataCollaborator}
			/>
		);
	};

	const renderFinallyComponent = () => {
		return <FinallyComponent setStep={setStep} />;
	};

	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderValidationComponent();
			case 2:
				return renderSurveyComponent();
			default:
				return renderFinallyComponent();
		}
	};

	const renderContainer = () => {
		return (
			<div className='flex w-full h-full items-center content-center justify-center'>
				{renderSteps()}
			</div>
		);
	};

	return (
		<>
			<div className='flex h-screen w-full flex-col overflow-y-hidden bg-white landscape:hidden lg:landscape:flex xl:landscape:flex 2xl:landscape:flex '>
				<div className='flex flex-row  h-16 w-full bg-white shadow-2xl '>
					<div className='flex w-4/12 md:w-2/12 lg:w-2/12 xl:w-2/12 2xl:w-2/12  h-full items-center content-center justify-center '>
						<img
							width='100px'
							className='object-scale-down '
							src={logo}
							alt='Logo'
						/>
					</div>
					<div className='flex w-10/12 h-full items-center content-center font-bold'>
						<h1 className='uppercase ml-8 text-blue-500'>
							{dataCollaborator?.CompanyName !== null
								? dataCollaborator?.CompanyName
								: getTranslation(
										t,
										SurveysQuestionsNames.SurveyCompanyInformationNotFound
								  )}
						</h1>
					</div>
				</div>
				<div className='flex flex-grow overflow-y-hidden  w-full  '>
					<div className='w-full h-full flex items-center content-center justify-center'>
						{renderContainer()}
					</div>
					<ToastContainer
						icon={null}
						autoClose={4000}
						position={'bottom-right'}
					/>
				</div>
			</div>
			<div className='flex h-screen lg:hidden xl:hidden 2xl:hidden w-screen flex-col overflow-y-hidden bg-white portrait:hidden justify-center items-center content-center'>
				<div className='flex flex-row  h-16 w-full bg-white shadow-2xl '>
					<div className='flex w-4/12 md:w-2/12 lg:w-2/12 xl:w-2/12 2xl:w-2/12  h-full items-center content-center justify-center '>
						<img
							width='100px'
							className='object-scale-down '
							src={logo}
							alt='Logo'
						/>
					</div>
					<div className='flex w-10/12 h-full items-center content-center font-bold'>
						<h1 className='uppercase ml-8 text-blue-500'>
							{dataCollaborator?.CompanyName !== null
								? dataCollaborator?.CompanyName
								: getTranslation(
										t,
										SurveysQuestionsNames.SurveyCompanyInformationNotFound
								  )}
						</h1>
					</div>
				</div>
				<div className='flex flex-grow overflow-y-hidden  w-full  '>
					<div className='w-full h-full flex items-center content-center justify-center'>
						{
							<h1 className='font-bold text-2xl'>
								Porfavor gire el dispositivo
							</h1>
						}
					</div>
				</div>
			</div>
		</>
	);
};
