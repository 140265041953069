import {
	GET_SUBURB,
	GET_SUBURB_ERROR,
	GET_SUBURB_SUCCESS,
} from '../../business/constants';

import { IsNullOrEmpty } from '../../utils/utilsService';
import { StoreContext } from '../../business/Provider';
import { sortBy } from 'lodash';
import useApi from '../api';
import { useContext } from 'react';
import { Toast } from '../../Components/toast';

const useSuburbs = () => {
	const { genericApiCall } = useApi();
	const context = useContext(StoreContext);
	const baseUrl = `${window.REACT_APP_EVALUA_API_URI}/cp/states`;
	const getStateOptions = (states) => {
		const activeEvaluators = states.filter((object) => {
			return IsNullOrEmpty(object.deleteBy);
		});
		return sortBy(activeEvaluators, [
			function (o) {
				return o.name;
			},
		]).map((item) => ({ label: item.name, value: item.name, id: item.id }));
	};
	const getSuburbs = async (city, state) => {
		try {
			context.dispatchSuburbs({ type: GET_SUBURB });
			const response = await genericApiCall(
				`${baseUrl}/${state}/cities/${city}/suburbs`,
				'get'
			);
			if (response.status === 200) {
				console.log('response', response);
				context.dispatchSuburbs({
					type: GET_SUBURB_SUCCESS,
					payload: getStateOptions(response.data.data.suburbs),
				});
			} else {
				context.dispatchSuburbs({
					type: GET_SUBURB_ERROR,
					payload: response.data.message,
				});
			}
			return response;
		} catch (error) {
			Toast('warning', error.message, '', error);
			context.dispatchSuburbs({ type: GET_SUBURB_ERROR });
		}
	};
	return { getSuburbs };
};
export default useSuburbs;
