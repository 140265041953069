import React, { Suspense, lazy } from 'react'

import { Collaborator } from './../containers/Catalogs/collaborators/index';
import { CollaboratorQuestionary } from './routes/CollaboratorQuestionary';
import Company from '../containers/Company/index';
import { Departments } from '../containers/Catalogs/Departments';
import { Evaluators } from '../containers/Catalogs/evaluators';
import { Home } from './../containers/Home/index';
import { NotFound } from './../Components/NotFound/index';
import { Positions } from '../containers/Catalogs/positions';
import { Queries } from '../containers/Queries';
import { QuestionList } from '../containers/Questions';
import { SurveyCollaboratorComponent } from '../containers/SurveyCollaborator';
import { Results } from '../containers/Result';
import { Route } from "react-router-dom";
import { ScoreRangesComponent } from '../containers/scoreRanges';
import { Survey } from '../containers/Survey';
import { WorkCenters } from '../containers/Catalogs/workCenters';
import { Zones } from '../containers/Catalogs/Zones';
import { Import } from '../containers/import';
import { Migrator } from '../containers/Migrator';
import { SurveySample } from '../containers/SurveySample';
import { Logout } from '../Components/Logout';
import Guide4 from '../containers/Guide4';

// routing array
const routes = [
    {
        path: "/",
        name: "Home",
        component: <Home />

    },
    {
        path: '/:guideName/survey/:guideId',
        name: 'surveys',
        component: <Survey />
    },
    {
        path: '/:guideName/questions/:questionaryId',
        name: 'questions',
        component: <QuestionList />
    },
    {
        path: '/:guideName/results/:guideId',
        name: 'results',
        component: <Results />
    },
    {
        path: '/:guideName/surveysCollaborators/:guideId',
        name: 'surveysCollaborators',
        component: <SurveyCollaboratorComponent />
    },
    {
        path: '/guide-3/sample',
        name: 'sample',
        component: <SurveySample />
    },
    {
        path: '/catalogs/collaborators',
        name: 'collaborators',
        component: <Collaborator />,
    },
    {
        path: '/catalogs/positions',
        name: 'positions',
        component: <Positions />
    },
    {
        path: '/catalogs/zones',
        name: 'zone',
        component: <Zones />
    },
    {
        path: '/catalogs/workCenters',
        name: 'positions',
        component: <WorkCenters />
    },
    {
        path: '/catalogs/evaluators',
        name: 'zone',
        component: <Evaluators />
    },
    {
        path: '/catalogs/departments',
        name: 'zone',
        component: <Departments />
    },
    {
        path: 'menus/surveys/score-ranges/guideId/:guideId',
        name: 'scoreRanges',
        component: <ScoreRangesComponent />
    },
    {
        path: '/:guideName/collaboratorQuestionary/:idQuestionary/:idSurveyCollaborator',
        name: 'collaboratorQuestionary',
        component: <CollaboratorQuestionary />
    },
    {
        path: '/:guideName/score-ranges/guideId/:guideId',
        name: 'scoreRanges',
        component: <ScoreRangesComponent />
    },
    {
        path: '/queries',
        name: 'queries',
        component: <Queries />
    },
    {
        path: '/configuration/company',
        name: 'company',
        component: <Company />
    },
    {
        path: '/configuration/import',
        name: 'import',
        component: <Import />
    },
    {
        path: '/guide-4/document',
        name: 'guide-4',
        component: <Guide4 />
    },
    // {
    //     path: '/configuration/migrator',
    //     name: 'migrator',
    //     component: <Migrator />
    // },
    {
        path: '/evaluaLogout',
        name: 'logout',
        component: <Logout />
    }
    ,
    {
        path: '*',
        name: 'NotFound',
        component: <NotFound />,
    },
    {
        path: '/configuration/migrator',
        name: 'migrator',
    }

]

// map routing array to React Router
export default routes.map((route, index) => {
    return (
        <Route
            key={index}
            path={route.path}
            element={route.component}
        />
    )
})