import React from 'react';
import {
	getTranslation,
	useCustomTranslation,
} from '../../../hooks/useTranslations';
import { Fieldset } from 'primereact/fieldset';
import { ResultsNames, SurveyNames } from '../../../translations';
export const ResultSurvey = ({ survResult }) => {
	const { t } = useCustomTranslation();
	const bg = `${survResult?.general.ScoreColor || 'transparent'}`;
	return (
		<div className='p-4 flex-grow flex flex-col gap-4'>
			<div className='text-center'>
				<p>{getTranslation(t, SurveyNames.RESULT_VALUE)}</p>
			</div>

			<div className='flex w-full justify-center '>
				<div
					style={{
						background: bg,
					}}
					className={`w-1/3 h-12  flex text-center items-center justify-center border border-border text-black`}>
					{survResult?.general.GeneralScoreText}
				</div>
			</div>

			<Fieldset
				legend={getTranslation(t, ResultsNames.RECOMMENDATION)}
				className=' border border-border p-2 relative flex-grow '>
				<p>{survResult?.general.ScoreDescription}</p>
			</Fieldset>
		</div>
	);
};
