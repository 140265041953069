import { RadioButton } from 'primereact/radiobutton';
import React from 'react';
import PropTypes from 'prop-types';

export const RadioButtonQuestion = ({
	text,
	indexRadio,
	itemRadio,
	response,
	sectionIdRadio,
	indexSectionRadio,
	bgRadio,
	item,
	questionaryId,
	setValueRadio,
	w = 'w-1/5',
	collaboratorData
}) => {
	const checkedRadioBtn = (response, item) =>
		response.optionValueId === item.questionValue;

	if (
		item?.questionId === 138 &&
		item?.questionValue === '' &&
		collaboratorData.gender !== ' '
	) {
		item.questionValue = collaboratorData.gender === 'M' ? 630 : 631;
		setValueRadio(
			{
				target: { value: collaboratorData.gender === 'M' ? 630 : 631 }
			},
			item,
			sectionIdRadio,
			indexSectionRadio,
			collaboratorData.gender === 'M' ? 630 : 631
		);
	}
	const renderValidation =
		item.questionTypeId === 1 ||
		item.questionTypeId === 4 ||
		item.questionTypeId === 7 ? (
			<label
				htmlFor={response.optionValueId}
				className={'font-bold ' + bgRadio}>
				{text}
			</label>
		) : null;
	return (
		<div
			key={indexRadio}
			className={
				'flex h-full flex-col content-center items-center justify-center text-center px-0.5 ' +
				w
			}>
			<div className='flex flex-col  md:flex-col lg:flex-col  xl:flex-col  2xl:flex-col  content-center items-center justify-center '>
				{questionaryId > 1 ? renderValidation : null}
				<label
					htmlFor={response.optionValueId}
					className={
						'flex text-xxs mr-1 font-semibold s:text-xxs  md:hidden lg:hidden xl:hidden 2xl:hidden ' +
						bgRadio
					}>
					{text}
				</label>
				<RadioButton
					className={'bg-red-500'}
					style={{ backGroundColor: 'red' }}
					id={response.optionValueId}
					value={response.optionValueId}
					name={item.questionNumber}
					onChange={(e) =>
						setValueRadio(
							e,
							itemRadio,
							sectionIdRadio,
							indexSectionRadio,
							response.value
						)
					}
					checked={checkedRadioBtn(response, item)}
				/>
			</div>
		</div>
	);
};

RadioButtonQuestion.propTypes = {
	text: PropTypes.string,
	indexRadio: PropTypes.number,
	itemRadio: PropTypes.object,
	response: PropTypes.object,
	sectionIdRadio: PropTypes.number,
	indexSectionRadio: PropTypes.number,
	bgRadio: PropTypes.string,
	item: PropTypes.object,
	questionaryId: PropTypes.number,
	setValueRadio: PropTypes.func,
	w: PropTypes.string,
};
