import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import React from 'react';

export const SearchInput = ({
	onClickSearch,
	name,
	title,
	value,
	onChange,
	onBlur,
	error,
	errorText,
	important,
	ref,
	disabled,
	type,
	onKeyPress,
	onKeyDown,
	maxLength,
}) => {
	return (
		<div className='field w-full rounded-none !font-input-font-family'>
			<label className='text-small-font-size font-semibold flex' htmlFor={name}>
				{title}{' '}
				<div
					hidden={!important}
					className='h-2 w-2 rounded-full bg-red ml-2 my-auto'></div>{' '}
			</label>

			<div className='flex w-full flex-row p-p-input-icon-right '>
				<div className='flex w-10/12'>
					<InputText
						className={`w-full !rounded-none !text-font-size-base ${
							error ? 'p-invalid' : ''
						}`}
						id={name}
						value={value}
						onKeyPress={onKeyPress}
						//  onBlur={onBlur}
						onChange={onChange}
						ref={ref}
						disabled={disabled}
						type={type}
						aria-describedby={`${name}-${errorText}`}
						name={name}
						maxLength={maxLength}
						onKeyDown={onKeyDown}
					/>
				</div>
				<div className='flex w-2/12 p-1'>
					<Button
						type='button'
						className='  p-button-icon-only p-button-transition '
						onClick={onClickSearch}>
						<FontAwesomeIcon className='!text-black ' icon={faSearch} />
					</Button>
				</div>
			</div>

			{error && (
				<small id={`${name}-${errorText}`} className='p-error block'>
					{errorText}
				</small>
			)}
		</div>
	);
};
