import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

import { GenericsNames } from '../../translations';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

export const FullLoading = ({ show, textLoading, zIndex = 50 }) => {
	const { t } = useCustomTranslation();
	if (!textLoading) {
		textLoading = getTranslation(t, GenericsNames.LOADING) + '...';
	}
	//const zIndexClass=`z-[${zIndex}]`;
	//console.log(zIndexClass);
	const renderComponent = () => {
		return show ? (
			<div style={{ zIndex: zIndex, opacity: '0.5' }}>
				<div
					className={`absolute bg-white bg-opacity-25 flex w-full top-0 bottom-0 items-center justify-center `}>
					<div
						className={
							'flex w-full h-full flex-col items-center justify-center p-24'
						}>
						<p className='text-13 sm:text-20 mb-16'>{textLoading}</p>
						<ProgressSpinner
							mode='indeterminate'
							className='flex w-full h-8'></ProgressSpinner>
					</div>
				</div>
			</div>
		) : null;
	};
	return (
		<>
			{
				//show ?
				renderComponent()
				//  : null
			}
		</>
	);
};
