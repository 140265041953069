import React, { useRef } from 'react';
import { CompanyNames } from '../../../translations';
import FileUpload from '../../../Components/FileUpload';
import { Input } from '../../../Components/Input/Input';
import PropTypes from 'prop-types';

import { getTranslation } from '../../../hooks/useTranslations';
import { AddressInformationComponent } from '../../../Components/AddressInformationComponent';

const CompanyForm = ({ formik, zipCodeFound, setZipCodeFound, t }) => {
	const fileUploadRef = useRef(null);
	const onClearHandler = async () => {
		formik.setFieldValue('thumbnail', '');
		formik.setFieldValue('logoFile', null);
		formik.setFieldValue('clearLogo', true);
	};
	const onUploadHandler = async (event) => {
		const reader = new FileReader();

		//Read the contents of Image File.
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = function (file) {
			//Initiate the JavaScript Image object.
			const image = new Image();
			formik.setFieldTouched('logoFile', true);
			//Set the Base64 string return from FileReader as source.
			image.src = file.target.result;

			//Validate the File Height and Width.
			image.onload = function () {
				const height = this.height;
				const width = this.width;

				if (height > 500 || width > 500) {
					formik.setFieldValue('logoFile', 'InvalidSize');
					return false;
				}
				formik.setFieldValue('clearLogo', false);
				formik.setFieldValue('thumbnail', image.src);
				formik.setFieldValue('logoFile', event.target.files[0]);
				return true;
			};
		};
	};

	const renderInput = (
		id,
		name,
		placeholder,
		onChange,
		value,
		onBlur,
		error,
		errorText,
		type,
		important,
		rows,
		cols,
		disabled = false
	) => {
		return (
			<div className='flex w-1/2 p-2'>
				<Input
					id={id}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					onBlur={onBlur}
					error={error}
					errorText={errorText}
					type={type}
					important={important}
					rows={rows}
					cols={cols}
					disabled={disabled}
				/>
			</div>
		);
	};

	const renderName = () => {
		return renderInput(
			'name',
			'name',
			getTranslation(t, CompanyNames.NAME),
			formik.handleChange,
			formik.values.name,
			(event) => {
				formik.handleBlur(event);
				formik.setFieldValue('name', event.target.value.trim());
			},
			formik.touched.name && Boolean(formik.errors.name),
			formik.touched.name && getTranslation(t, formik.errors.name),
			'text',
			true,
			null,
			null,
			true
		);
	};

	const renderRFC = () => {
		return renderInput(
			'taxID',
			'taxID',
			getTranslation(t, CompanyNames.RFC),
			formik.handleChange,
			formik.values.taxID,
			(event) => {
				formik.handleBlur(event);
				if (event.target.value) {
					formik.setFieldValue(
						'taxID',
						event.target.value.toUpperCase().trim()
					);
				}
			},
			formik.touched.taxID && Boolean(formik.errors.taxID),
			formik.touched.taxID && getTranslation(t, formik.errors.taxID),
			'text',
			true
		);
	};

	const renderAlias = () => {
		return renderInput(
			'alias',
			'alias',
			getTranslation(t, CompanyNames.ALIAS),
			formik.handleChange,
			formik.values.alias,
			(event) => {
				formik.handleBlur(event);
				formik.setFieldValue('alias', event.target.value.trim());
			},
			formik.touched.alias && Boolean(formik.errors.alias),
			formik.touched.alias && getTranslation(t, formik.errors.alias),
			'text',
			true,
			null,
			null,
			true
		);
	};

	const renderLogo = () => {
		return (
			<div className='grid grid-cols-1 justify-items-center'>
				{formik.values.thumbnail && (
					<img
						className='object-cover h-48 w-96 rounded border-2 border-sky-400'
						src={formik.values.thumbnail}></img>
				)}
				<br />
				{formik.touched.logoFile && Boolean(formik.errors.logoFile) && (
					<small className='p-error block'>
						{getTranslation(t, formik.errors.logoFile)}
					</small>
				)}
				<FileUpload
					onChange={onUploadHandler}
					accept='image/*'
					label={getTranslation(t, CompanyNames.LOGO)}
					allowClear={true}
					clearLabel={getTranslation(t, CompanyNames.CLEAN)}
					onClear={onClearHandler}></FileUpload>
			</div>
		);
	};
	return (
		<div>
			<div className='flex flex-row'>
				{renderName()}
				{renderAlias()}
				{renderRFC()}
			</div>
			<div className='flex flex-col items-center justify-center content-center'>
				<div className='flex flex-row w-full'>
					{
						<AddressInformationComponent
							formik={formik}
							open={true}
							setZipCodeFound={setZipCodeFound}
							zipCodeFound={zipCodeFound}
							isEdit
						/>
					}
				</div>
				<div className='flex flex-row'>{renderLogo()}</div>
			</div>
		</div>
	);
};

CompanyForm.propTypes = {
	formik: PropTypes.object,
	zipCodeFound: PropTypes.bool,
	suburbs: PropTypes.array,
	onZipSearch: PropTypes.func,
	setZipCode: PropTypes.func,
	municipality: PropTypes.string,
	states: PropTypes.array,
	searchCity: PropTypes.func,
	t: PropTypes.func,
	getSuburbs: PropTypes.func,
};

export default CompanyForm;
