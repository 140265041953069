/* eslint-disable react/prop-types */
import React from 'react'
import { InputText } from 'primereact/inputtext';

export const GridDialogTextField = ({
  className,
  classNameInput,
  onBlur,
  error,
  helperText,
  onChange,
  value,
  label,
  name,
  id,
  children,
  maxLength,
  ...other
}) => {
  return (
    <div className={className}>
      <label htmlFor="" className="">{label}</label>
      <InputText
        className={classNameInput}
        autoComplete='off'
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        maxLength={maxLength}>
        {children}
      </InputText>

    </div >
  )
}