import 'primeicons/primeicons.css';
import './index.css';
import './assets/tailwind.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import {
	AuthenticatedTemplate,
	MsalProvider,
	useMsalAuthentication,
} from '@azure/msal-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import { loginRequest, msalConfig } from './Auth/b2cService/b2cServiceConfig';

import { Auth } from './Auth/auth';
import { Buffer } from 'buffer';
import { CompanyRelatedProvider } from './context/CompanyRelatedContext';
import History from './RouterFiles/@history';
import { InteractionType } from '@azure/msal-browser';
import { LoadingProvider } from './context/LoadingContext';
import { MainLayout } from './Layout/Main';
import { NavigationProvider } from './context/navigationContext';
import { Provider } from 'react-redux';
import { ReloadProvider } from './context/ReloadProvider';
import { StoreProvider } from './business/Provider';
import WrapperApp from './WrapperApp';
import axios from 'axios';
import routes from './routes/routes';
import store from './store/index';
import { ErrorPage } from './containers/errorPage';

function App({ pca }) {
	window.Buffer = Buffer;
	const { login, error } = useMsalAuthentication(
		InteractionType.Silent,
		loginRequest
	);
	const getQueryVariable = (variable) => {
		const query = window.location.search.substring(1);
		const vars = query.replace('#state', '&state').split('&');

		for (const vrItem of vars) {
			const pair = vrItem.split('=');
			if (pair[0] === variable) {
				return pair[1];
			}
		}
		return '';
	};

	if (localStorage.getItem('accessToken') === null) {
		localStorage.removeItem('currentTap');
	}

	useEffect(() => {
		const currentPath = window.location.pathname;
		if (currentPath === '/evaluaLogout') return;
		if (error) {
			login(InteractionType.Redirect, loginRequest);
			window.localStorage.clear();
		} else {
			// get state from master portal, if the state is not present, will do a redirection to master portal.
			const stateQueryvariable = getQueryVariable('state');
			var state = '';
			if (stateQueryvariable !== '') {
				//compare instance id from session storage with instance id from master portal
				const sessionStateStorage = JSON.parse(
					sessionStorage.getItem('sessionState')
				);
				state = JSON.parse(
					Buffer.from(stateQueryvariable, 'base64').toString()
				);
				state.userAgent = window.navigator.userAgent.toString();
				const sessionStateJson = state;
				console.log(sessionStateJson);
				if (sessionStateJson?.instanceId !== sessionStateStorage?.instanceId) {
					//remove session storage
					sessionStorage.removeItem('sessionState');
					window.localStorage.clear();
					console.log('reset');
				}
			}
			if (!sessionStorage.sessionState) {
				//get current path
				if (!stateQueryvariable || stateQueryvariable.length === 0) {
					window.location.replace(msalConfig.ctiPortalURI);
				} else {
					state = JSON.parse(
						Buffer.from(stateQueryvariable, 'base64').toString()
					);
					state.userAgent = window.navigator.userAgent.toString();

					const sessionStateJson = JSON.stringify(state);
					//clear sessionStorage sessionState
					sessionStorage.setItem('sessionState', sessionStateJson);

					// set as default header the sessionState
					axios.defaults.headers.common = {
						...axios.defaults.headers.common,
						sessionState: sessionStateJson,
					};
					console.log('Session State has been register', sessionStateJson);
				}
			} else {
				const sessionStateStorage = sessionStorage.getItem('sessionState');

				// set as default header the sessionState
				axios.defaults.headers.common = {
					...axios.defaults.headers.common,
					sessionState: sessionStateStorage,
				};
			}
		}
	}, [error, login]);

	let token = window.localStorage.getItem('accessToken');

	if (token !== null) {
		function parseJwt(token) {
			let base64Url = token.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);

			return JSON.parse(jsonPayload);
		}

		const dataInfo = parseJwt(token);
		window.inlineManualTracking = {};
		window.inlineManualOptions = { language: 'en' };
		let domaininline =
			window.location.hostname.split('.')[
				window.location.hostname.split('.').length - 2
			];

		const inlineID = {
			//testdevcontpaqinube: '5a3790c79e6c61283062922eb6ce28d7', //se cambia por solicitud de conocimiento
			testdevcontpaqinube: '07615fc6a5bd071b10a81644c4ea1688',
			testcontpaqinube: '07615fc6a5bd071b10a81644c4ea1688',
			contpaqinube: 'b0599a44b62745f2a559d0e8ab912a77',
		};
		!(function (e, n, a, t, i, r, s) {
			(e[i] =
				e[i] ||
				function () {
					(e[i].q = e[i].q || []).push(arguments);
				}),
				(e[i].l = 1 * new Date()),
				(e[i].snippet = {
					version: '2.0',
				}),
				(r = n.createElement(a)),
				(s = n.getElementsByTagName(a)[0]),
				(r.async = 1),
				(r.src = t),
				(r.id = t + '-' + i),
				s.parentNode.insertBefore(r, s);
		})(
			window,
			document,
			'script',
			`https://cdn.inlinemanual.com/inm/loader/loader.${inlineID[domaininline]}.js`,
			'InlineManual'
		);

		InlineManual('boot', {
			uid: dataInfo.email,
			email: dataInfo.email,
			username: dataInfo.email,
			name: dataInfo.given_name,
			product: 'evalua',
		});
	}

	return (
		<>
			<AuthenticatedTemplate>
				<Provider store={store}>
					<ReloadProvider>
						<Auth>
							<MsalProvider instance={pca}>
								<LoadingProvider>
									<BrowserRouter history={History}>
										<CompanyRelatedProvider>
											<WrapperApp>
												<NavigationProvider>
													<StoreProvider>
														<Routes>
															<Route exact path='/' element={<MainLayout />}>
																{routes}
															</Route>
															<Route path='/error' element={<ErrorPage />} />
														</Routes>
													</StoreProvider>
												</NavigationProvider>
											</WrapperApp>
										</CompanyRelatedProvider>
									</BrowserRouter>
								</LoadingProvider>
							</MsalProvider>
						</Auth>
					</ReloadProvider>
				</Provider>
			</AuthenticatedTemplate>
		</>
	);
}

export default App;
