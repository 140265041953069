const collaborator = [
	{
		title: 'Nombre',
		dataKey: 'name',
	},
	{
		title: 'Primer Apellido',
		dataKey: 'lastName',
	},
	{
		title: 'Segundo Apellido',
		dataKey: 'surName',
	},
	{
		title: 'Correo Electrónico',
		dataKey: 'email',
	},
	{
		title: 'RFC',
		dataKey: 'taxId',
	},
	{
		title: 'Género',
		dataKey: 'gender',
		drop: 1,
	},
	{
		title: 'Estatus',
		dataKey: 'status',
		drop: 2,
	},
	{
		title: 'Departamento',
		dataKey: 'departmentId',
		drop: 3,
	},
	{
		title: 'Centro de Trabajo',
		dataKey: 'workCenterId',
		drop: 4,
	},
	{
		title: 'Puesto',
		dataKey: 'positionId',
		drop: 5,
	},
	{
		title: 'Area',
		dataKey: 'areaId',
		drop: 6,
	},
	{
		title: 'Teletrabajador',
		dataKey: 'teleWorker',
		drop: 9,
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const area = [
	{
		title: 'Clave',
		dataKey: 'scode',
		width: '10%',
	},
	{
		title: 'Nombre',
		dataKey: 'name',
		width: '80%',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const position = [
	{
		title: 'Clave',
		dataKey: 'scode',
		width: '10%',
	},
	{
		title: 'Nombre',
		dataKey: 'name',
		width: '80%',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const department = [
	{
		title: 'Clave',
		dataKey: 'scode',
		width: '10%',
	},
	{
		title: 'Nombre',
		dataKey: 'name',
		width: '80%',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const evaluator = [
	{
		title: 'Clave',
		dataKey: 'scode',
		width: '10%',
	},
	{
		title: 'Nombre',
		dataKey: 'name',
		width: '45%',
	},

	{
		title: 'Cédula Profesional',
		dataKey: 'professionalId',
		width: '45%',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const workCenter = [
	{
		title: 'Nombre',
		dataKey: 'name',
	},
	{
		title: 'Clave',
		dataKey: 'scode',
	},
	{
		title: 'Evaluador',
		dataKey: 'evaluatorId',
		drop: 7,
	},
	{
		title: 'Condición',
		dataKey: 'isActive',
		drop: 8,
	},
	{
		title: 'Actividad principal',
		dataKey: 'principalActivity',
	},
	{
		title: 'Actividades',
		dataKey: 'activities',
	},
	{
		title: 'Objetivo',
		dataKey: 'objective',
	},
	{
		title: 'Código Postal',
		dataKey: 'zipCode',
	},
	{
		title: 'Estado',
		dataKey: 'state',
	},
	{
		title: 'Municipio',
		dataKey: 'municipality',
	},
	{
		title: 'Colonia',
		dataKey: 'suburb',
	},
	{
		title: 'Calle',
		dataKey: 'street',
	},
	{
		title: 'Número Exterior',
		dataKey: 'exteriorNumber',
	},
	{
		title: 'Número Interior',
		dataKey: 'interiorNumber',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

const survey = [
	{
		title: 'Correo',
		dataKey: 'email',
	},
	{
		title: 'RFC',
		dataKey: 'taxId',
	},
	{
		title: 'Nombre',
		dataKey: 'name',
	},
	{
		title: 'Apellido paterno',
		dataKey: 'lastName',
	},
	{
		title: 'Apellido materno',
		dataKey: 'surName',
	},
	{
		title: 'Centro de Trabajo',
		dataKey: 'workCenterId',
		drop: 4,
	},
	{
		title: 'Área',
		dataKey: 'areaId',
		drop: 6,
	},
	{
		title: 'Departamento',
		dataKey: 'departmentId',
		drop: 3,
	},
	{
		title: 'Puesto',
		dataKey: 'positionId',
		drop: 5,
	},
	{
		title: 'Evaluador',
		dataKey: 'evaluatorId',
		drop: 7,
	},
	{
		title: 'Periodo de Evaluación',
		dataKey: 'evaluationPeriod',
	},
	{
		title: 'Periodo',
		dataKey: 'period',
	},
	{
		title: 'Observaciones',
		dataKey: 'errors',
	},
];

export const columnsSw = (type) => {
	switch (type) {
		case 1:
			return collaborator;
		case 2:
			return area;
		case 3:
			return position;
		case 4:
			return workCenter;
		case 5:
			return evaluator;
		case 6:
			return department;
		case 7:
		case 8:
		case 9:
		case 10:
		case 11:
		case 12:
			return survey;
		default:
			break;
	}
};
